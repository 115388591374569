import React from "react";
import { useState } from "react";
import { Datepicker, localeAr, localeEn } from "@mobiscroll/react";
import "./datepicker.scss";
import language from "../../Json/locale.json";
import useStorage from "../../hooks/useStorage";

const DatePicker = ({ selectedDate, setSelectedDate, disabledDates }) => {
  const locale = useStorage();
  const date = new Date();
  return (
    <div className="h-full flex flex-col justify-center mb-[45px] md:mb-0">
      <div>
        <h5 className="mb-[20px] text-white text-center text-[17px] font-SansSerif">
          {language.select_day[locale]}
        </h5>
        <Datepicker
          controls={["calendar"]}
          themeVariant="dark"
          locale={locale === "en" ? localeEn : localeAr}
          display="inline"
          touchUi={true}
          theme="material"
          min={new Date(date.getFullYear(), date.getMonth(), date.getDate())}
          invalid={disabledDates}
          showOuterDays={false}
          value={selectedDate}
          onChange={(dt) => setSelectedDate(dt.value)}
        />
      </div>
    </div>
  );
};

export default DatePicker;

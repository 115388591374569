import moment from "moment";
import { Datepicker, localeAr, localeEn } from "@mobiscroll/react";
import "./datepicker.scss";
import language from "../../Json/locale.json";
import useStorage from "../../hooks/useStorage";

export default function Calendar({
  handleDateChange,
  selectedDate,
  minDate,
  maxDate,
  disabled_dates,
  disabled_weeks,
}) {
  const locale = useStorage();
  const curDate = new Date();

  let startDate = minDate;

  if (
    moment(moment(curDate).format("YYYY-MM-DD")).isAfter(
      moment(minDate).format("YYYY-MM-DD"),
      "day"
    ) ||
    !startDate
  ) {
    startDate = curDate;
  }

  return (
    <div className="h-full flex flex-col justify-center mb-[45px] md:mb-0">
      <div>
        <h5 className="mb-[20px] text-white text-center text-[17px] font-semibold">
          {language.select_day[locale]}
        </h5>
        <Datepicker
          controls={["calendar"]}
          display="inline"
          locale={locale === "en" ? localeEn : localeAr}
          touchUi={true}
          themeVariant="dark"
          theme="material"
          showOuterDays={false}
          defaultSelection={null}
          min={
            new Date(
              startDate.getFullYear(),
              startDate.getMonth(),
              startDate.getDate()
            )
          }
          max={
            new Date(
              maxDate.getFullYear(),
              maxDate.getMonth(),
              maxDate.getDate()
            )
          }
          invalid={[...disabled_dates, disabled_weeks]}
          value={selectedDate}
          onChange={handleDateChange}
        />
      </div>
    </div>
  );
}

import React, { useEffect, useRef, useState } from "react";
import Banner from "../components/Banner";
import Hotel from "../components/Dine/Hotel";
import hotel1 from "../assets/images/hotel1.png";
import hotel2 from "../assets/images/hotel2.png";
import animate from "../gsap/animate";
import { useSelector } from "react-redux";
import { fetchClient } from "../axios-config";
import { useAuth0 } from "@auth0/auth0-react";
import useFacebookPixel from "../hooks/useFacebookPixel";

const Dine = () => {
  const ref = useRef([]);
  const {
    loginWithRedirect,
    getAccessTokenSilently,
    isAuthenticated,
    isLoading,
  } = useAuth0();
  const [dineData, setDineData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { heading, description, image } = useSelector(
    (state) => state.data.dine
  );
  const { trackPageView } = useFacebookPixel();

  useEffect(() => {
    trackPageView();
  }, [trackPageView]);

  useEffect(() => {
    animate(ref);
    const getData = async () => {
      setLoading(true);
      const data = await fetchClient(
        getAccessTokenSilently,
        isAuthenticated,
        `dines/?&locale=${localStorage.getItem("lang") || "en"}&populate=*`,
        "",
        true
      );
      // window.removeEventListener("storage", getData);
      if (data) setDineData(data.data);
      setLoading(false);
    };
    getData();
    // window.addEventListener("storage", getData);

    // return () => {
    //   window.removeEventListener("storage", getData);
    // };
  }, []);

  return (
    <>
      <Banner
        url={process.env.REACT_APP_CMS + image.data.attributes.url}
        title={heading}
        desc={description}
      />
      {dineData.map((dine, idx) => (
        <Hotel
          ref={(el) => (ref.current[idx] = el)}
          data={{
            image: dine.attributes.image.data.attributes.url,
            title: dine.attributes.title,
            desc: dine.attributes.description,
            time: dine.attributes.timings,
            link: dine.attributes.menu_link,
          }}
        />
      ))}
    </>
  );
};

export default Dine;

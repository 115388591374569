import language from "../../Json/locale.json";
import useStorage from "../../hooks/useStorage";

export default function TimeSlot({
  selectedTimeSlot,
  timeSlots,
  changeTimeSlot,
}) {
  const locale = useStorage();
  return (
    <div className="h-full flex flex-col justify-between font-SansSerif mb-[45px] md:mb-0">
      <div></div>
      <div>
        <h5 className="mb-[20px] text-white text-center text-[17px]">
          {language.available_times[locale]}
        </h5>
        <div className="flex flex-col justify-center items-center w-full mb-[48px] gap-[12px]">
          {timeSlots.map((slot, idx) => (
            <div
              className={`${
                selectedTimeSlot === idx ? "bg-white" : "bg-primaryBg"
              } w-full flex justify-between p-[20px] rounded-[13px] cursor-pointer`}
              onClick={() => changeTimeSlot(idx)}
            >
              <div>
                {/* <h3 className="text-white text-[17px] font-semibold">
                  October 6
                </h3> */}
                <h3
                  className={`${
                    selectedTimeSlot === idx ? "text-[#111]" : "text-white"
                  } text-[17px]`}
                >
                  {slot.time_slot}
                </h3>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

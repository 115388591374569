import { Datepicker, localeAr, localeEn } from "@mobiscroll/react";
import tick from "../../assets/svg/tick.svg";
import { useState, useEffect } from "react";
import "../../components/Modal/datepicker.scss";
import { useNavigate } from "react-router-dom";
import close from "../../assets/svg/close.svg";
import RedX from "../../assets/svg/red_x.svg";
import Loader from "../Modal/Loader";
import language from "../../Json/locale.json";
import useStorage from "../../hooks/useStorage";

const Modal = ({
  isOpen,
  closeModal,
  data,
  setData,
  loading,
  joinMemberShip,
  validateCoupon,
  handleSubmitData,
  handlePay,
  profileData,
  selectedMemberShip,
  alertText,
  coupon,
  setCoupon,
  resetCoupon,
  setResetCoupon,
  step,
  setStep,
  amount,
  setAmount,
}) => {
  const date = new Date();
  const navigate = useNavigate();
  const locale = useStorage();
  const handleDataChange = (input) => {
    setData((prev) => {
      return {
        ...prev,
        [input.name]:
          input.name === "document"
            ? input.files[0]
            : input.name === "student_check"
            ? input.checked
            : input.value,
      };
    });
  };

  const handleClearCoupon = () => {
    setAmount(0);
    setCoupon("");
    setResetCoupon(false);
  };

  useEffect(() => {
    const modal_overlay = document.querySelector("#modal_overlay");
    const modal = document.querySelector("#modal");
    const modalCl = modal.classList;
    const overlayCl = modal_overlay.classList;
    if (isOpen) {
      overlayCl.remove("hidden");
      setTimeout(() => {
        modalCl.remove("opacity-0");
      }, 100);
    } else {
      modalCl.add("opacity-0");
      setTimeout(() => overlayCl.add("hidden"), 100);
    }
  }, [isOpen]);

  return (
    <>
      <div
        id="modal_overlay"
        className="flex justify-center items-center overflow-x-hidden fixed inset-0 z-50 backdrop-blur-sm hidden bg-black bg-opacity-30"
      >
        <div
          id="modal"
          className="relative w-full h-full md:min-w-[400px] md:min-h-[400px] md:w-auto md:h-auto md:my-6 mx-auto max-w-3xl opacity-0 transition-opacity duration-300"
        >
          <div className="bg-modalBg md:max-h-[90vh] overflow-y-scroll h-auto md:min-h-[400px] px-[24px] pb-[20px] md:px-[30px] md:py-[15px] border-0 md:rounded-lg shadow-lg relative flex flex-col w-full outline-none focus:outline-none">
            <div className="sticky top-0 pb-[10px] pt-[20px] md:relative md:pb-0 bg-modalBg md:max-h-[90vh]">
              <div className="flex items-center justify-between mb-[60px] md:mb-[36px] rounded-t">
                <h3 className="text-[20px] md:text-[32px] text-white">
                  {step
                    ? language.summary[locale]
                    : language.your_details[locale]}
                </h3>
                <button
                  className="relative opacity-7 h-[36px] w-[36px] block bg-[#FFFFFF4D] py-0 rounded-full"
                  onClick={closeModal}
                >
                  <img
                    src={close}
                    alt="close"
                    className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
                  />
                </button>
              </div>
              <div className="bg-[#111] w-full flex justify-between mb-[20px] rounded-[13px] p-[20px]">
                <div>
                  <h6 className="text-white text-[17px] font-[600]">
                    {selectedMemberShip?.label}
                  </h6>
                  <p className="text-white text-[17px] font-[400]">
                    {data?.student_check
                      ? language.student_price[locale]
                      : language.regular_price[locale]}
                  </p>
                </div>
                <h6 className="text-white text-[17px] font-[600]">
                  {" "}
                  {!!amount ? (
                    <div className="flex gap-2">
                      <s>
                        AED{" "}
                        {data?.student_check
                          ? selectedMemberShip?.plans[0].student_price
                          : selectedMemberShip?.plans[0].regular_price}
                      </s>
                      <p>AED {Number(amount).toFixed(2)}</p>
                    </div>
                  ) : (
                    <>
                      AED{" "}
                      {data?.student_check
                        ? selectedMemberShip?.plans[0].student_price
                        : selectedMemberShip?.plans[0].regular_price}
                    </>
                  )}{" "}
                  {data && selectedMemberShip && (
                    <p className="text-[15px] font-normal">
                      <i>
                        ({language.includes_VAT[locale]}{" "}
                        {(data?.student_check
                          ? selectedMemberShip?.plans[0].student_price * 0.05
                          : selectedMemberShip?.plans[0].regular_price * 0.05
                        ).toFixed(2)}{" "}
                        AED)
                      </i>
                    </p>
                  )}
                </h6>
              </div>
            </div>
            {!step && (
              <form onSubmit={handleSubmitData}>
                <div className="flex flex-col md:flex-row gap-[12px] mb-[12px]">
                  <input
                    type="text"
                    className="border-none outline-none text-white w-full bg-[#11111180] rounded-[30px] px-[28px] py-[16px] md:flex items-center"
                    placeholder={language.first_name[locale]}
                    name="name"
                    required
                    value={data?.name}
                    onChange={(e) => handleDataChange(e.target)}
                  />
                  <input
                    type="text"
                    className="border-none outline-none text-white w-full bg-[#11111180] rounded-[30px] px-[28px] py-[16px] md:flex items-center"
                    placeholder={language.last_name[locale]}
                    name="last_name"
                    required
                    value={data?.last_name}
                    onChange={(e) => handleDataChange(e.target)}
                  />
                </div>
                <div className="flex flex-col md:flex-row gap-[12px] mb-[12px]">
                  <input
                    type="text"
                    className="border-none outline-none text-white w-full bg-[#11111180] rounded-[30px] px-[28px] py-[16px] md:flex items-center"
                    placeholder={language.email[locale]}
                    name="email"
                    required
                    readOnly={profileData?.email}
                    value={data?.email}
                    onChange={(e) => handleDataChange(e.target)}
                  />
                  <input
                    type="tel"
                    className="border-none outline-none text-white w-full bg-[#11111180] rounded-[30px] px-[28px] py-[16px] md:flex items-center"
                    placeholder={language.phone[locale]}
                    name="mobile"
                    required
                    readOnly={profileData?.mobile}
                    value={data?.mobile}
                    onChange={(e) => handleDataChange(e.target)}
                  />
                </div>
                <div className="flex flex-col md:flex-row gap-[12px] mb-[12px]">
                  <select
                    className="border-none outline-none text-white w-full bg-[#11111180] rounded-[30px] min-h-[50px] py-[16px] px-[28px]  md:flex items-center"
                    name="document_type"
                    required
                    value={data?.document_type}
                    onChange={(e) => handleDataChange(e.target)}
                  >
                    {language.document_type[locale].map((doc_type) => (
                      <option value={doc_type.value}>{doc_type.name}</option>
                    ))}
                  </select>
                  <input
                    type="text"
                    className="border-none outline-none text-white w-full bg-[#11111180] rounded-[30px] px-[28px] py-[16px] md:flex items-center"
                    placeholder={language.document_id[locale]}
                    name="document_id"
                    required
                    value={data?.document_id}
                    onChange={(e) => handleDataChange(e.target)}
                  />
                </div>
                <div className="flex flex-col md:flex-row gap-[12px] mb-[12px] mobiDatePicker">
                  {/* <input
                    type="date"
                    className="border-none outline-none text-white w-full bg-[#11111180] rounded-[30px] px-[28px] py-[16px] md:flex items-center"
                    placeholder="Expiration Date"
                    name="expiry_date"
                    required
                    value={data.expiry_date}
                    onChange={(e) => handleDataChange(e.target)}
                  /> */}
                  <Datepicker
                    controls={["calendar"]}
                    themeVariant="dark"
                    locale={locale === "en" ? localeEn : localeAr}
                    touchUi={true}
                    theme="material"
                    inputComponent="input"
                    inputProps={{
                      placeholder: language.expiration_date[locale],
                    }}
                    min={
                      new Date(
                        date.getFullYear(),
                        date.getMonth(),
                        date.getDate()
                      )
                    }
                    value={data?.expiry_date}
                    name="expiry_date"
                    onChange={(date) =>
                      handleDataChange({
                        name: "expiry_date",
                        value: date.value,
                      })
                    }
                  />
                  {/* <input
                    type="file"
                    id="fileupload"
                    hidden
                    name="document"
                    required
                    onChange={(e) => handleDataChange(e.target)}
                  />
                  <label htmlFor="fileupload" className="w-full">
                    <div className="border-none outline-none text-white w-full bg-[#11111180] rounded-[30px] px-[28px] py-[16px] md:flex items-center">
                      <p className="text-white text-[16px]">Attach file</p>
                    </div>
                  </label> */}
                </div>
                <div className="flex flex-col md:flex-row gap-[12px] mb-[12px]">
                  <div className="hidden md:flex items-center">
                    <p className="text-white ml-[8px]"> </p>
                  </div>
                  <div className="my-[12px] flex items-center gap-[12px] w-full">
                    <input
                      type="checkbox"
                      name="student_check"
                      id="student_check"
                      checked={data?.student_check}
                      onChange={(e) => handleDataChange(e.target)}
                      className={`w-4 h-4 text-blue-600 rounded border-gray-300 border-[1px] bg-[#151515] ml-[20px] md:ml-0 ${
                        data?.student_check ? "" : "opacity-0"
                      }`}
                    />
                    <div
                      className={`w-4 h-4 text-blue-600 rounded border-gray-300 border-[1px] bg-[#151515] ${
                        locale === "en" ? "ml-[-28px]" : "mr-[-28px]"
                      } md-ml-[-8px] ${data?.student_check ? "hidden" : ""}`}
                    />
                    <label
                      htmlFor="student_check"
                      className="text-white ms-[10px] text-[16px]"
                    >
                      {language.i_am_student[locale]}
                    </label>
                  </div>
                </div>
                {data?.student_check && (
                  <>
                    <div className="flex flex-col md:flex-row gap-[12px] mb-[12px]">
                      <select
                        className="border-none outline-none text-white w-full bg-[#11111180] rounded-[30px]  min-h-[50px] px-[28px] py-[16px] md:flex items-center"
                        name="student_type"
                        required
                        value={data?.student_type}
                        onChange={(e) => handleDataChange(e.target)}
                      >
                        <option value="student_id">
                          {language.student_id[locale]}
                        </option>
                      </select>
                      <input
                        type="text"
                        className="border-none outline-none text-white w-full bg-[#11111180] rounded-[30px] px-[28px] py-[16px] md:flex items-center"
                        placeholder={language.document_id[locale]}
                        name="student_id"
                        required
                        value={data?.student_id}
                        onChange={(e) => handleDataChange(e.target)}
                      />
                    </div>
                    <div className="flex flex-col md:flex-row gap-[12px] mb-[12px] mobiDatePicker">
                      {/* <input
                        type="date"
                        className="border-none outline-none text-white w-full bg-[#11111180] rounded-[30px] px-[28px] py-[16px] md:flex items-center"
                        placeholder="Expiration Date"
                        name="student_expiry_date"
                        required
                        value={data.student_expiry_date}
                        onChange={(e) => handleDataChange(e.target)}
                      /> */}
                      <Datepicker
                        controls={["calendar"]}
                        themeVariant="dark"
                        locale={locale === "en" ? localeEn : localeAr}
                        touchUi={true}
                        theme="material"
                        inputComponent="input"
                        inputProps={{
                          placeholder: language.expiration_date[locale],
                        }}
                        min={
                          new Date(
                            date.getFullYear(),
                            date.getMonth(),
                            date.getDate()
                          )
                        }
                        value={data?.student_expiry_date}
                        name="student_expiry_date"
                        onChange={(date) =>
                          handleDataChange({
                            name: "student_expiry_date",
                            value: date.value,
                          })
                        }
                      />
                    </div>
                  </>
                  // <input
                  //       type="file"
                  //       id="fileupload"
                  //       hidden
                  //       name="student_id"
                  //       required={data.student_check}
                  //       onChange={(e) => handleDataChange(e.target)}
                  //     />
                  //     <label htmlFor="fileupload" className="w-full">
                  //       <div className="border-none outline-none text-white w-full bg-[#11111180] rounded-[30px] px-[28px] py-[16px] md:flex items-center">
                  //         <p className="text-white text-[16px]">
                  //           Upload Student Id
                  //         </p>
                  //       </div>
                  //     </label>
                  //   <div className="w-full bg-[#11111180] rounded-[30px] px-[28px] py-[16px] hidden md:flex items-center">
                  //     <input
                  //       type="file"
                  //       className="border-none outline-none bg-transparent text-white w-full"
                  //       name="student_id"
                  //       required={data.student_check}
                  //       onChange={(e) => handleDataChange(e.target)}
                  //     />
                  //   </div>
                )}
                <div className="w-full flex justify-center align-center mt-[30px] mb-[20px]">
                  <button
                    type="submit"
                    className="flex justify-center align-center text-white text-[16px] px-[20px] py-[6px] gradient-border relative w-fit m-auto"
                  >
                    {language.continue[locale]}
                  </button>
                </div>
              </form>
            )}
            {!!step && (
              <>
                <div className="relative flex w-[450px] items-center">
                  <input
                    type="text"
                    className="border-none outline-none text-white placeholder-white w-full bg-[#ffffff4d] rounded-[30px] px-[28px] py-[16px] md:flex items-center"
                    placeholder={language.coupon[locale]}
                    name="coupon"
                    autoComplete="off"
                    required
                    value={coupon}
                    onChange={(e) => {
                      setCoupon(e.target.value);
                      if (!e.target.value) {
                        setResetCoupon(false);
                      }
                    }}
                  />
                  <button
                    disabled={loading}
                    onClick={resetCoupon ? handleClearCoupon : validateCoupon}
                    className={`absolute ${
                      locale === "en"
                        ? "right-4"
                        : "right-[19rem] md:right-[22rem]"
                    } top-1/2 transform -translate-y-1/2 h-[36px] w-[36px] rounded-full text-white gradient-border`}
                  >
                    <img
                      src={resetCoupon ? close : tick}
                      alt={resetCoupon ? "close" : "tick"}
                      className={`absolute top-1/2 left-1/2 transform ${
                        !resetCoupon && "h-5"
                      } -translate-x-1/2 -translate-y-1/2`}
                    />
                  </button>
                </div>
              </>
            )}
            {loading ? (
              <>
                <div
                  role="status"
                  className="flex justify-center w-full h-full items-center mt-4"
                >
                  <svg
                    aria-hidden="true"
                    class="me-2 w-8 h-8 text-[#616161] animate-spin fill-white"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="currentColor"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentFill"
                    />
                  </svg>
                  <span class="sr-only">Loading...</span>
                </div>
                <div className="flex justify-center align-center text-white text-[16px] mt-4">
                  {joinMemberShip
                    ? language.redirect_payment[locale]
                    : language.upload_info[locale]}{" "}
                </div>
              </>
            ) : (
              <>
                {!!step && (
                  <div className="w-full flex justify-center align-center mt-[20px] mb-[20px]">
                    <div>
                      {alertText && (
                        <div className="flex gap-[1rem] mb-[1rem]">
                          <img src={RedX} alt="close" className="w-[30px]" />
                          <h1 className="text-white">{alertText}</h1>
                        </div>
                      )}
                      <div className="flex gap-2">
                        <button
                          onClick={() => setStep(0)}
                          className="flex justify-center align-center text-white text-[16px] px-[20px] py-[6px] relative rounded-[24px] border-[1.5px] border-[#FFFFFF33] w-fit m-auto"
                        >
                          {language.back[locale]}
                        </button>
                        <button
                          onClick={handlePay}
                          className="flex justify-center align-center text-white text-[16px] px-[20px] py-[6px] gradient-border relative w-fit m-auto"
                        >
                          {language.continue[locale]}
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Modal;

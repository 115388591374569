import React, { useEffect, useState } from "react";
import moment from "moment";
import close from "../../assets/svg/close.svg";
import tick from "../../assets/svg/tick.svg";
import language from "../../Json/locale.json";
import useStorage from "../../hooks/useStorage";
import ReactPixel from "react-facebook-pixel";

const Summary = ({
  addChild,
  addPerson,
  packageTickets,
  groupTickets,
  adult_price,
  child_price,
  selectedDate,
  selectedTimeSlot,
  timeSlots,
  packageName,
  packageSlug,
  shipping_req,
  total,
  enable_date_picker,
  enable_time_picker,
  shipping,
  setShipping,
  selectedSummerDate,
  selectedSummerGroup,
  summerCamp,
  isLoading,
  amount,
  setAmount,
  count,
  coupon,
  setCoupon,
  validateCoupon,
  resetCoupon,
  setResetCoupon,
}) => {
  const locale = useStorage();
  const totalValue = (
    summerCamp
      ? ((selectedSummerGroup
          ? Number(selectedSummerGroup.price)
          : selectedSummerDate.reduce((sum, value) => {
              return sum + Number(value.price);
            }, 0)) +
          total) *
        count
      : total
  ).toFixed(2);

  useEffect(() => {
    ReactPixel.track("AddToCart", {
      content_ids: [`p-${packageSlug}`],
      content_type: "product",
      value: totalValue,
      currency: "AED",
    });
  }, [totalValue]);

  const handleClearCoupon = () => {
    setAmount(0);
    setCoupon("");
    setResetCoupon(false);
  };

  return (
    <div className="flex flex-col justify-between mt-[84px] md:mt-0">
      <div className="bg-transparent md:min-w-[400px] w-full flex justify-between p-[10px] md:p-[20px] rounded-[13px] font-SansSerif">
        <div>
          <h3 className="text-white text-[17px] font-semibold">
            {packageName}
          </h3>
          {enable_time_picker && (
            <h3 className="text-white text-[17px]">
              {timeSlots[selectedTimeSlot].time_slot}
            </h3>
          )}
          <br />
          {enable_date_picker && (
            <h3 className="text-white text-[17px]">
              {moment(selectedDate).format("LL")}
            </h3>
          )}
          {/* {child_price && (
            <h3 className="text-white text-[17px]">
              {language.children[locale]} {addChild}
            </h3>
          )}
          {adult_price && (
            <h3 className="text-white text-[17px]">
              {language.adult[locale]} {addPerson}
            </h3>
          )} */}
          {packageTickets.map((ticket, index) => (
            <h3 key={index} className="text-white text-[17px]">
              {locale === "en" ? ticket.label : ticket.label_ar} {ticket.qty}
            </h3>
          ))}
          {summerCamp && (
            <>
              <h3 className="text-white text-[17px]">
                {selectedSummerGroup
                  ? selectedSummerGroup.group_name
                  : selectedSummerDate.map((date) => date.date).join(", ")}
              </h3>
            </>
          )}
          <br />
          {/* <h3 className="text-white text-[17px]">
            {language.regular_price[locale]}: AED {totalValue}
          </h3> */}
          <h3 className="text-white text-[17px]">
            {language.price[locale]}: AED{" "}
            {((amount ? amount : totalValue) * 0.95).toFixed(2)}
          </h3>
          <h3 className="text-white text-[17px]">
            {language.includes_VAT[locale]} (5%): AED{" "}
            {((amount ? amount : totalValue) * 0.05).toFixed(2)}
          </h3>
          <h3 className="text-white text-[17px]">
            {language.total[locale]}: AED{" "}
            {(amount ? amount : +totalValue).toFixed(2)}
          </h3>
          {/* <p className="text-white text-[17px] font-semibold">
            {!!amount ? (
              <div className="flex-row">
                <s>AED {totalValue}</s>
                <p>AED {amount.toFixed(2)}</p>
              </div>
            ) : (
              <>AED {totalValue}</>
            )}{" "}
            <i className="text-[15px] font-normal">
              ({language.includes_VAT[locale]}{" "}
              {!!amount ? amount * 0.05 : totalValue * 0.05} AED)
            </i>
          </p> */}
          {shipping_req && (
            <div className="my-[12px] flex items-center gap-[12px] w-full">
              <input
                type="checkbox"
                name="student_check"
                id="student_check"
                checked={shipping}
                onChange={(e) => setShipping(e.target.checked)}
                className={`w-4 h-4 text-blue-600 rounded border-gray-300 border-[1px] bg-[#151515] ml-[20px] md:ml-0 ${
                  shipping ? "" : "opacity-0"
                }`}
              />
              <div
                className={`w-4 h-4 text-blue-600 rounded border-gray-300 border-[1px] bg-[#151515] ${
                  locale === "en" ? "ml-[-28px]" : "mr-[-28px]"
                } md-ml-[-8px] ${shipping ? "hidden" : ""}`}
              />
              <label
                htmlFor="student_check"
                className="text-white ms-[10px] text-[16px]"
              >
                {language.shipping[locale]} (AED +30)
              </label>
            </div>
          )}
        </div>
      </div>
      <div className="relative flex items-center">
        <input
          type="text"
          className="border-none outline-none text-white placeholder-white w-full bg-[#ffffff4d] rounded-[30px] px-[28px] py-[16px] md:flex items-center"
          placeholder={language.coupon[locale]}
          name="coupon"
          autoComplete="off" 
          required
          value={coupon}
          onChange={(e) => {
            setCoupon(e.target.value);
            if (!e.target.value) {
              setResetCoupon(false);
            }
          }}
        />
        <button
          disabled={isLoading}
          onClick={resetCoupon ? handleClearCoupon : validateCoupon}
          className={`absolute ${
            locale === "en" ? "right-4" : "right-[19rem] md:right-[22rem]"
          } top-1/2 transform -translate-y-1/2 h-[36px] w-[36px] rounded-full text-white gradient-border`}
        >
          <img
            src={resetCoupon ? close : tick}
            alt={resetCoupon ? "close" : "tick"}
            className={`absolute top-1/2 left-1/2 transform ${
              !resetCoupon && "h-5"
            } -translate-x-1/2 -translate-y-1/2`}
          />
        </button>
        {/* <input
          type="text"
          className="border-none outline-none text-white placeholder-white w-full bg-[#ffffff4d] rounded-[30px] px-[28px] py-[16px] md:flex items-center"
          placeholder={language.coupon[locale]}
          name="coupon"
          required
          value={coupon}
          onChange={(e) => setCoupon(e.target.value)}
        />
        <button
          disabled={isLoading}
          onClick={validateCoupon}
          className={`absolute ${
            locale === "en"
              ? "right-20 md:right-24"
              : "right-[16rem] md:right-[19rem]"
          } top-1/2 transform -translate-y-1/2 px-3 py-1 rounded-xl text-white gradient-border`}
        >
          Submit
        </button>
        <button
          disabled={isLoading}
          onClick={() => {
            setAmount(0);
            setCoupon("");
          }}
          className={`absolute ${
            locale === "en"
              ? "right-2 md:right-6"
              : "right-[11.5rem] md:right-[14.5rem]"
          } top-1/2 transform -translate-y-1/2 px-3 py-1 rounded-xl text-white gradient-border`}
        >
          reset
        </button> */}
      </div>
    </div>
  );
};

export default Summary;

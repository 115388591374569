import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Loader from "../components/Modal/Loader";
import { useSelector } from "react-redux";

export default function LoginRedirect() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const profileSaveData = useSelector((state) => state.profile);

  useEffect(() => {
    console.log(profileSaveData);
    if (profileSaveData) {
      if (
        !profileSaveData.email_verified ||
        profileSaveData.is_suspend === "1" ||
        !profileSaveData.email ||
        profileSaveData.email.includes("private") ||
        !profileSaveData.name ||
        !profileSaveData.last_name ||
        !profileSaveData.mobile
      )
        return;
      setLoading(true);
      setTimeout(() => {
        navigate(localStorage.getItem("last_visited_url") || "/");
      }, 2500);
    }
  }, [profileSaveData]);

  return <div>{loading && <Loader />}</div>;
}

import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const animate = (refs, avoidTop) => {
  
  refs.current.forEach((refc, index) => {
    
    gsap.timeline().fromTo(refc, 
    {
        opacity: 0.25,
        scale: 0.9,
    },
    {
      opacity: 1,
      scale: 1,
      scrollTrigger: {
        trigger: refc,
        start: "top bottom",
        end: index === refs.current.length - 1 ? "top top" : "top middle",
        scrub: true
      }
    })
   
  });

};

export default animate;

import React from "react";
import { Link } from "react-router-dom";
import language from "../../Json/locale.json";
import useStorage from "../../hooks/useStorage";

const Read = React.forwardRef((props, ref) => {
  const locale = useStorage();
  return (
    <div className="w-full flex mt-[84px] justify-center">
      <div className="w-full relative h-[700px] flex justify-center" ref={ref}>
        <div
          className="h-[700px] rounded-[8px] bg-image-gradient [--bg-url:url('./assets/images/read.jpg')] bg-cover w-11/12 max-w-[1800px] relative webkistyle3d"
          ref={ref}
        >
          <div className="absolute bottom-[24px] md:bottom-[48px] left-[24px] md:left-[48px]">
            <h2 className="text-white text-[28px] md:text-[32px]">
              {language.read_title[locale]}
            </h2>
            <p className="text-white text-[16px] mb-[30px]">
              {language.read_desc[locale]}
            </p>
            <Link
              to="/library"
              className="flex justify-center align-center text-white text-[16px] w-[150px] px-[20px] py-[6px] gradient-border relative"
            >
              {language.discover_more[locale]}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
});

export default Read;

import { useState, useEffect } from "react";
import language from "../../Json/locale.json";
import useStorage from "../../hooks/useStorage";

export default function SummerDate({
  summerDates,
  summerGroup,
  selectedSummerDate,
  setSelectedSummerDate,
  selectedSummerGroup,
  setSelectedSummerGroup,
  count,
  showDates,
  setShowDates,
}) {
  const locale = useStorage();
  const [alert, setAlert] = useState("");
  // console.log(summerDates, summerGroup, count);

  const summerGroupFiltered = summerGroup.filter((group) => {
    const groupDates = group.dates.map((date) => date.trim());
    return groupDates.some((date) => {
      const dateObj = summerDates.find((dateObj) => dateObj.date === date);
      return dateObj && count <= dateObj.available_qty;
    });
  });

  const handleSummerDate = (grp) => {
    setShowDates(false);
    const filteredData = summerDates.filter((date) => {
      if (count <= date.available_qty) return grp.dates.includes(date.date);
    });
    const allDatesAvailable = grp.dates.every((inputDate) => {
      return filteredData.some(
        (availableDate) => inputDate === availableDate.date
      );
    });
    if (!allDatesAvailable) {
      setAlert(language.inventory_checking[locale]);
    } else {
      setSelectedSummerGroup(grp);
      setSelectedSummerDate(filteredData);
    }
  };

  const handleAddDate = (date) => {
    const isDateSelected = selectedSummerDate.find(
      (dt) => dt.date === date.date
    );
    setSelectedSummerGroup();
    if (isDateSelected) {
      const updatedDates = selectedSummerDate.filter(
        (dt) => dt.date !== date.date
      );
      setSelectedSummerDate(updatedDates);
    } else {
      setSelectedSummerDate([...selectedSummerDate, date]);
    }
  };

  useEffect(() => {
    if (alert) {
      setTimeout(() => {
        setAlert("");
      }, 3000);
    }
  }, [alert]);

  // console.log(summerGroupFiltered);

  return (
    <div className="h-full flex flex-col justify-between font-SansSerif mb-[14rem] md:mb-0">
      <div>
        <h5 className="mb-[20px] text-white text-center text-[17px]">
          {language.package[locale]}
        </h5>
        {alert && (
          <p className="text-[#FF0000] text-center bg-red-200 rounded-[5px] p-[5px] mb-[1rem]">
            • {alert}
          </p>
        )}
        <div className="flex flex-col justify-center items-center w-full mb-[48px] gap-[12px]">
          {summerGroupFiltered.map((grp) => (
            <div
              className="flex justify-center items-center"
              key={grp.id}
              onClick={() => handleSummerDate(grp)}
            >
              <label
                htmlFor={`group${grp.id}`}
                className="text-white ml-[1rem] ms-[10px] text-[16px]"
              >
                <div
                  id={`group${grp.id}`}
                  className={`${
                    selectedSummerGroup?.id === grp.id
                      ? "bg-white"
                      : "bg-primaryBg"
                  } w-[23rem] flex items-center p-[20px] rounded-[13px] cursor-pointer`}
                >
                  <input
                    type="radio"
                    id={`group${grp.id}`}
                    name={`group${grp.id}`}
                    checked={selectedSummerGroup?.id === grp.id}
                    className={`w-4 h-4 text-blue-600 rounded border-gray-300 border-[1px] bg-[#151515] mr-[15px] ${
                      selectedSummerGroup?.id === grp.id ? "" : "opacity-0"
                    }`}
                  />
                  <div
                    className={`w-4 h-4 text-blue-600 rounded-[1.25rem] border-gray-300 border-[1px] bg-[#151515] ${
                      locale === "en" ? "ml-[-32px] mr-[20px]" : "mr-[-32px]"
                    } md-ml-[-8px] ${
                      selectedSummerGroup?.id === grp.id ? "hidden" : ""
                    }`}
                  />
                  <div>
                    <h3
                      className={`${
                        selectedSummerGroup?.id === grp.id
                          ? "text-[#111]"
                          : "text-white"
                      } text-[17px]`}
                    >
                      {grp.group_name} - AED {grp.price}
                    </h3>
                  </div>
                </div>
              </label>
            </div>
          ))}

          <div className="flex justify-center items-center">
            <label
              htmlFor={`group${9999}`}
              className="text-white ml-[1rem] ms-[10px] text-[16px]"
              onClick={() => {
                setShowDates(true);
                setSelectedSummerGroup();
                setSelectedSummerDate([]);
                var elmntToView = document.getElementById("custom-dates");
                elmntToView.scrollIntoView({ behavior: "smooth" });
              }}
            >
              <div
                className={`${
                  showDates ? "bg-white" : "bg-primaryBg"
                } w-[23rem] flex items-center p-[20px] rounded-[13px] cursor-pointer`}
              >
                <input
                  type="radio"
                  id={`group${9999}`}
                  name={`group${9999}`}
                  checked={showDates}
                  className={`w-4 h-4 text-blue-600 rounded border-gray-300 border-[1px] bg-[#151515] mr-[15px] ${
                    showDates ? "" : "opacity-0"
                  }`}
                />
                <div
                  className={`w-4 h-4 text-blue-600 rounded-[1.25rem] border-gray-300 border-[1px] bg-[#151515] ${
                    locale === "en" ? "ml-[-32px] mr-[20px]" : "mr-[-32px]"
                  } md-ml-[-8px] ${showDates ? "hidden" : ""}`}
                />
                <div>
                  <h3
                    className={`${
                      showDates ? "text-[#111]" : "text-white"
                    } text-[17px]`}
                  >
                    Individual Options - Day Pass
                  </h3>
                </div>
              </div>
            </label>
          </div>
        </div>

        <div
          id="custom-dates"
          className={`showDates ${showDates ? "expanded" : ""}`}
        >
          <h5 className="mb-[20px] text-white text-center text-[17px]">
            {language.summer_dates[locale]}
          </h5>
          <div className="grid grid-cols-2 gap-4 showDatesContent">
            {summerDates.map(
              (date) =>
                count <= date.available_qty && (
                  <div
                    className="flex justify-center items-center"
                    key={date.id}
                  >
                    <label
                      htmlFor={`date${date.id}`}
                      className="text-white ml-[1rem] ms-[10px] text-[16px]"
                    >
                      <div
                        className={`${
                          selectedSummerDate.find((dt) => dt.date === date.date)
                            ? "bg-white"
                            : "bg-primaryBg"
                        } flex justify-around items-center p-4 rounded cursor-pointer w-[10rem]`}
                      >
                        <input
                          type="checkbox"
                          id={`date${date.id}`}
                          name={`date${date.id}`}
                          checked={selectedSummerDate.find(
                            (dt) => dt.date === date.date
                          )}
                          onChange={() => handleAddDate(date)}
                          className={`w-4 h-4 text-blue-600 rounded border-gray-300 border-[1px] bg-[#151515] ml-[2px] md:ml-0 ${
                            selectedSummerDate.find(
                              (dt) => dt.date === date.date
                            )
                              ? ""
                              : "opacity-0"
                          }`}
                        />
                        <div
                          className={`w-4 h-4 text-blue-600 rounded border-gray-300 border-[1px] bg-[#151515] ${
                            locale === "en" ? "ml-[-35px]" : "mr-[-16px]"
                          } md-ml-[-8px] ${
                            selectedSummerDate.find(
                              (dt) => dt.date === date.date
                            )
                              ? "hidden"
                              : ""
                          }`}
                        />
                        <div>
                          <h3
                            className={`${
                              selectedSummerDate.find(
                                (dt) => dt.date === date.date
                              )
                                ? "text-[#111]"
                                : "text-white"
                            } text-[15px]`}
                          >
                            {date.date}
                          </h3>
                          <p
                            className={`${
                              selectedSummerDate.find(
                                (dt) => dt.date === date.date
                              )
                                ? "text-[#111]"
                                : "text-white"
                            } text-[12px]`}
                          >
                            AED {date.price}
                          </p>
                        </div>
                      </div>
                    </label>
                  </div>
                )
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

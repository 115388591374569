import ReactPixel from "react-facebook-pixel";

const useFacebookPixel = () => {
  const trackPageView = () => {
    ReactPixel.init("672661037701850");
    ReactPixel.pageView();
  };

  return {
    trackPageView,
  };
};

export default useFacebookPixel;

import React, { useEffect } from "react";
import Loader from "../components/Modal/Loader";
import { useAuth0 } from "@auth0/auth0-react";

export default function Logout() {
  const { logout } = useAuth0();
  useEffect(() => {
    logout({ returnTo: window.location.origin });
  }, []);
  return (
    <div>
      <Loader />
    </div>
  );
}

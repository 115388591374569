import { useState, useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";
import close from "../../assets/svg/close.svg";
import FormSteps from "./FormSteps";
import Calendar from "./Calendar";
import FluentFood from "./FluentFood";
import SubPackages from "./SubPackages";
import TimeSlot from "./TimeSlot";
import Transport from "./Transport";
import EventDetails from "./EventDetails";
import Users from "./Users";
import Summary from "./Summary";
import { ReactComponent as Guests } from "../../assets/svg/reserve_guests.svg";
import { ReactComponent as Dates } from "../../assets/svg/reserve_date.svg";
import { ReactComponent as Time } from "../../assets/svg/reserve_time.svg";
import { ReactComponent as List } from "../../assets/svg/list-svgrepo-com.svg";
import { ReactComponent as UserGroup } from "../../assets/svg/users-group-svgrepo-com.svg";
import moment from "moment";
import { fetchClient } from "../../axios-config";
import { useAuth0 } from "@auth0/auth0-react";
import language from "../../Json/locale.json";
import useStorage from "../../hooks/useStorage";
import Details from "./Details";
import SummerDate from "./SummerDate";
import ReactPixel from "react-facebook-pixel";
import { notifyInfo } from "../../utils/notifyToast";

const Modal = ({ isOpen, closeModal, packageSlug, setPackageSlug }) => {
  const { getAccessTokenSilently, isAuthenticated } = useAuth0();
  const [step, setStep] = useState(-1);
  const [packageLoading, setPackageLoading] = useState(false);
  const [packageDetails, setPackageDetails] = useState();
  const [packageSlots, setPackageSlots] = useState();
  const [dateAvailability, setDateAvailability] = useState();
  const [addChild, setAddChild] = useState(0);
  const [addPerson, setAddPerson] = useState(0);
  const [selectedDate, setSelectedDate] = useState("");
  const [realDate, setRealDate] = useState("");
  const [adultPrice, setAdultPrice] = useState("");
  const [childPrice, setChildPrice] = useState("");
  const [subPackages, setSubPackages] = useState([]);
  const [meals, setMeals] = useState([]);
  const [transports, setTransports] = useState([]);
  const [isOneWay, setOneWay] = useState(true);
  const [transport_id, setTransportID] = useState(0);
  const [transport_name, setTransportName] = useState("");
  const [timeSlots, setTimeSlots] = useState([]);
  const [selectedTimeSlot, setSelectedTimeSlot] = useState(-1);
  const [timeSlotValue, setTimeSlotValue] = useState("");
  const [time_slot, setTimeSlot] = useState("");
  const [btnPackageName, setPackageName] = useState("SELECT PACKAGE");
  const [btnMealName, setMealName] = useState("SELECT MEALS");
  const [packagePrice, setPackagePrice] = useState(0);
  const [subpackages_adult_qty, setSubAdultQty] = useState(0);
  const [subpackages_child_qty, setSubChildQty] = useState(0);
  const [mealPrice, setMealPrice] = useState(0);
  const [transportPrice, setTransportPrice] = useState(0);
  const [isBooking, showBooking] = useState(false);
  const [isDateSelector, showDateSelector] = useState(false);
  const [isTimeSlotSelector, showTimeSlotSelector] = useState(false);
  const [isSubPackageSelector, showSubPackageSelector] = useState(false);
  const [isMealSelector, showMealSelector] = useState(false);
  const [isTransportSelector, showTransportSelector] = useState(false);
  const [progressCount, setProgressCount] = useState(1);
  const [progressValue, setProgressValue] = useState(100);
  const [isLoading, setLoading] = useState(false);
  const [total_adult_tickets, setTotalAdultTickets] = useState(0);
  const [total_child_tickets, setTotalChildTickets] = useState(0);
  const [enable_date_picker, setEnableDatePicker] = useState(false);
  const [enable_time_picker, setEnableTimePicker] = useState(false);
  const [enable_meal, setEnableMeal] = useState(false);
  const [enable_sub_packages, setEnableSubPackages] = useState(false);
  const [enable_transport, setEnableTransport] = useState(false);
  const [offValue, setOffValue] = useState(0);
  const [maxDiscountVal, setMaxDiscountVal] = useState(0);
  const [minCart, setMinCart] = useState(0);
  const [total, setTotal] = useState(0);
  const [offType, setOffType] = useState("");
  const [skipAddon, setSkipAddon] = useState(0);
  const [skipTranport, setSkipTransport] = useState(0);
  const [skipFood, setSkipFood] = useState(0);
  const [couponBuy, setCouponBuy] = useState("");
  const [couponBuyQty, setCouponBuyQty] = useState(0);
  const [couponGet, setCouponGet] = useState("");
  const [couponGetQty, setCouponGetQty] = useState(0);
  const [coupon, setCoupon] = useState("");
  const [disabled_dates, setDisabledDates] = useState([]);
  const [disabled_weeks, setDisabledWeeks] = useState([]);
  const [isSubTitle, showSubTitle] = useState([]);
  const [isMealTitle, showMealTitle] = useState([]);
  const [amount, setAmount] = useState(0);
  const [adult_overall_limit, setAdultOverAllLimit] = useState("");
  const [child_overall_limit, setChildOverAllLimit] = useState("");
  const [adult_booked_times, setAdultBookedTimes] = useState("");
  const [child_booked_times, setChildBookedTimes] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [details, setDetails] = useState({
    name: "",
    dob: "",
  });
  const [packageTickets, setPackageTickets] = useState([]);
  const [groupTickets, setGroupTickets] = useState([]);
  const [attendeesList, setAttendessList] = useState([]);
  const [attendeeAlert, setAttendeeAlert] = useState("");
  const [collectDetails, setCollectDetails] = useState(false);
  const [selectedSummerDate, setSelectedSummerDate] = useState([]);
  const [selectedSummerGroup, setSelectedSummerGroup] = useState("");
  const [summerDates, setSummerDates] = useState([]);
  const [summerGroup, setSummerGroup] = useState([]);
  const [summerCamp, setSummerCamp] = useState(false);
  const [resetCoupon, setResetCoupon] = useState(false);
  const [showDates, setShowDates] = useState(false);
  const [shipping, setShipping] = useState(false);
  const [customChildValue, setCustomChildValue] = useState([]);
  const [data, setData] = useState([]);
  const locale = useStorage();

  const resetCloseModal = () => {
    setStep(-1);
    setPackageDetails();
    setPackageSlots();
    setDateAvailability();
    setAddChild(0);
    setAddPerson(0);
    setSelectedDate("");
    setRealDate("");
    setAdultPrice("");
    setChildPrice("");
    setSubPackages([]);
    setMeals([]);
    setTransports([]);
    setOneWay(true);
    setTransportID(0);
    setTransportName("");
    setTimeSlots([]);
    setSelectedTimeSlot(-1);
    setTimeSlotValue("");
    setTimeSlot("");
    setPackageName("SELECT PACKAGE");
    setMealName("SELECT MEALS");
    setPackagePrice(0);
    setSubAdultQty(0);
    setSubChildQty(0);
    setMealPrice(0);
    setTransportPrice(0);
    showBooking(false);
    showDateSelector(false);
    showTimeSlotSelector(false);
    showSubPackageSelector(false);
    showMealSelector(false);
    showTransportSelector(false);
    setProgressCount(1);
    setProgressValue(100);
    setLoading(false);
    setTotalChildTickets(0);
    setEnableDatePicker(false);
    setEnableTimePicker(false);
    setEnableMeal(false);
    setEnableSubPackages(false);
    setEnableTransport(false);
    setOffValue(0);
    setMaxDiscountVal(0);
    setMinCart(0);
    setTotal(0);
    setOffType("");
    setSkipAddon(0);
    setSkipTransport(0);
    setSkipFood(0);
    setCouponBuy("");
    setCouponBuyQty(0);
    setCouponGet("");
    setCouponGetQty(0);
    setCoupon("");
    setDisabledDates([]);
    setDisabledWeeks([]);
    setGroupTickets([]);
    showSubTitle([]);
    showMealTitle([]);
    setAdultOverAllLimit("");
    setChildOverAllLimit("");
    setAdultBookedTimes("");
    setChildBookedTimes("");
    setCustomChildValue([]);
    setPackageSlug("");
    setShipping(false);
    setCollectDetails(false);
    setSummerCamp(false);
    setSummerDates([]);
    setSummerGroup([]);
    setTotalAdultTickets(0);
    setAmount(0);
    setOpenModal(false);
    setDetails({
      name: "",
      dob: "",
    });
    setAttendessList([]);
    setAttendeeAlert("");
    setSelectedSummerDate([]);
    setSelectedSummerGroup("");
    setShowDates(false);
    closeModal();
  };

  useEffect(() => {
    const temp = [];
    temp.push(<Guests fill={step === 0 && "#111"} step={0} />);
    if (collectDetails) {
      temp.push(<UserGroup fill={step === 1 && "#111"} step={1} />);
    }
    if (enable_date_picker) {
      temp.push(<Dates fill={step === 2 && "#111"} step={2} />);
    }
    if (summerCamp) {
      temp.push(<List fill={step === 3 && "#111"} step={3} />);
    }
    if (enable_time_picker) {
      temp.push(<Time fill={step === 4 && "#111"} step={4} />);
    }
    setData(temp);
  }, [enable_date_picker, enable_time_picker, collectDetails, summerCamp]);

  let isMeal = false,
    isSub = false,
    isTime = false,
    isDate = false,
    isTransport = false;

  useEffect(() => {
    if (packageSlug) {
      const getPackageDetails = async () => {
        setPackageLoading(true);
        const formData = new FormData();
        formData.append("action", "packagesDetails");
        formData.append("slug", packageSlug);
        const data = await fetchClient(
          getAccessTokenSilently,
          isAuthenticated,
          "",
          formData,
          false
        );
        if (data) setPackageDetails(data.data);
        ReactPixel.track("ViewContent", {
          content_ids: [`p-${packageSlug}`],
          content_type: "product",
        });
        setPackageLoading(false);
      };
      getPackageDetails();
    }
  }, [packageSlug]);

  let minDate = new Date(packageDetails?.start_date);

  let maxDate = new Date(packageDetails?.end_date);

  // useEffect(() => {
  //   let index = 2;
  //   let temp = [];
  //   temp.push({
  //     id: 1,
  //     value: "user",
  //     icon: <i className="far fa-user booking-flow-icon"></i>,
  //     detail:
  //       packageDetails?.id === "2501" ||
  //       packageDetails?.id === "2502" ||
  //       packageDetails?.id === "2503" ||
  //       packageDetails?.id === "2504" ||
  //       packageDetails?.id === "2505" ||
  //       packageDetails?.id === "2596" ||
  //       packageDetails?.id === "2595" ||
  //       packageDetails?.id === "2633" ||
  //       packageDetails?.id === "2634"
  //         ? addPerson *
  //             (packageDetails?.id === "2501" ||
  //             packageDetails?.id === "2502" ||
  //             packageDetails?.id === "2503" ||
  //             packageDetails?.id === "2504" ||
  //             packageDetails?.id === "2505" ||
  //             packageDetails?.id === "2596" ||
  //             packageDetails?.id === "2634"
  //               ? customChildValue.reduce(function (sum, item) {
  //                   return Number(sum) + Number(item.price);
  //                 }, 0)
  //               : adultPrice) +
  //           addChild *
  //             (packageDetails?.id === "2595" || packageDetails?.id === "2633"
  //               ? customChildValue.reduce(function (sum, item) {
  //                   return Number(sum) + Number(item.price);
  //                 }, 0)
  //               : childPrice)
  //         : addPerson * adultPrice + addChild * childPrice,
  //   });
  //   if (enable_date_picker) {
  //     temp.push({
  //       id: index,
  //       value: "date",
  //       icon: <i className="far fa-calendar-alt booking-flow-icon"></i>,
  //       detail: realDate,
  //     });
  //     index++;
  //   }
  //   if (enable_time_picker) {
  //     temp.push({
  //       id: index,
  //       value: "time",
  //       icon: <i className="far fa-clock  booking-flow-icon"></i>,
  //       detail: time_slot,
  //     });
  //     index++;
  //   }
  //   if (enable_sub_packages) {
  //     temp.push({
  //       id: index,
  //       value: "sub_packages",
  //       icon: <i className="far fa-plus-square   booking-flow-icon"></i>,
  //       detail: packagePrice,
  //     });
  //     index++;
  //   }
  //   if (enable_transport) {
  //     temp.push({
  //       id: index,
  //       value: "transports",
  //       icon: <i className="fas fa-car booking-flow-icon"></i>,
  //       detail: transportPrice,
  //     });
  //     index++;
  //   }
  //   if (enable_meal) {
  //     temp.push({
  //       id: index,
  //       value: "meals",
  //       icon: <i className="fas fa-utensils booking-flow-icon"></i>,
  //       detail: mealPrice,
  //     });
  //   }
  //   setStepList(temp);
  // }, [
  //   enable_date_picker,
  //   enable_time_picker,
  //   packageDetails,
  //   realDate,
  //   packagePrice,
  //   mealPrice,
  //   time_slot,
  //   timeSlotValue,
  //   transportPrice,
  //   enable_sub_packages,
  //   enable_meal,
  //   enable_transport,
  // ]);

  useEffect(() => {
    console.log(packageDetails);
    if (packageDetails) {
      if (packageDetails?.price) {
        setAdultPrice(packageDetails?.price);
      }
      if (packageDetails?.child_price) {
        setChildPrice(packageDetails?.child_price);
      }
      if (packageDetails?.adult_default_qty) {
        setAddPerson(Number(packageDetails?.adult_default_qty));
      }
      if (packageDetails?.child_default_qty) {
        setAddChild(Number(packageDetails?.child_default_qty));
      }
      if (packageDetails?.collect_participant_details === "1") {
        setCollectDetails(true);
      }
      if (packageDetails?.summer_camp === "1") {
        setSummerCamp(true);
        setSummerDates(packageDetails.summer_camp_event_dates);
        setSummerGroup(packageDetails.summer_camp_groups);
      }
      if (
        packageDetails?.enable_date_picker != "1" &&
        packageDetails?.enable_meal != "1" &&
        packageDetails?.enable_sub_packages != "1" &&
        packageDetails?.enable_time_picker != "1" &&
        packageDetails?.enable_transport != "1"
      ) {
        showBooking(true);
      }
      let disabled_dates_data = [];

      if (packageDetails?.event_dates?.length) {
        const event_date = packageDetails?.event_dates;
        var currDate = new Date();
        var newDate = new Date(
          currDate.getFullYear() + 1,
          currDate.getMonth(),
          currDate.getDate()
        );
        const yearData = [...event_date].map((Edate) =>
          moment(Edate).format("YYYY-MM-DD")
        );
        for (var d = new Date(); d <= newDate; d.setDate(d.getDate() + 1)) {
          yearData.push(moment(d).format("YYYY-MM-DD"));
        }
        const result = yearData.filter(function (e) {
          return yearData.indexOf(e) == yearData.lastIndexOf(e);
        });
        const convertedDate = result.map(
          (re) =>
            new Date(
              Number(re.split("-")[0]),
              Number(re.split("-")[1]) - 1,
              Number(re.split("-")[2])
            )
        );
        disabled_dates_data.push(...convertedDate);
      }

      if (packageDetails?.disabled_dates?.length) {
        disabled_dates_data.push(...packageDetails?.disabled_dates);
      }

      if (packageDetails?.disable_booking_prior == "1") {
        const curDate = new Date();

        if (curDate.getHours() >= 1) {
          let day = curDate.getDate() + 1;
          let date =
            curDate.getMonth() + 1 + "/" + day + "/" + curDate.getFullYear();
          let date1 =
            curDate.getMonth() +
            1 +
            "/" +
            curDate.getDate() +
            "/" +
            curDate.getFullYear();
          disabled_dates_data.push(date);
          disabled_dates_data.push(date1);
        } else {
          let date =
            curDate.getMonth() +
            1 +
            "/" +
            curDate.getDate() +
            "/" +
            curDate.getFullYear();
          disabled_dates_data.push(date);
        }
      }

      setDisabledDates(disabled_dates_data);
      if (packageDetails?.disabled_weeks) {
        let weeks = (packageDetails?.disabled_weeks).split(",");
        for (var i = 0; i < weeks.length; i++) {
          if (weeks[i] == "monday") {
            setDisabledWeeks((weeks) => [...weeks, 1]);
          } else if (weeks[i] == "tuesday") {
            setDisabledWeeks((weeks) => [...weeks, 2]);
          } else if (weeks[i] == "wednesday") {
            setDisabledWeeks((weeks) => [...weeks, 3]);
          } else if (weeks[i] == "thursday") {
            setDisabledWeeks((weeks) => [...weeks, 4]);
          } else if (weeks[i] == "friday") {
            setDisabledWeeks((weeks) => [...weeks, 5]);
          } else if (weeks[i] == "saturday") {
            setDisabledWeeks((weeks) => [...weeks, 6]);
          } else if (weeks[i] == "sunday") {
            setDisabledWeeks((weeks) => [...weeks, 0]);
          }
        }
      }
      if (packageDetails?.id) {
        // const dateCheck = new FormData();
        // dateCheck.append("action", "dateAvailabilityCheck");
        // dateCheck.append("id", packageDetails?.id);
        // dateCheck.append("date", moment(new Date()).format("MM/DD/YYYY"));
        // getDateAvailability(dateCheck);
        const slotsData = new FormData();
        slotsData.append("action", "packageSlots");
        slotsData.append("id", packageDetails?.id);
        getPackageSlots(slotsData);
      }
      let count = 6;
      let flag = false;
      if (packageDetails?.enable_date_picker == "1") {
        flag = true;
      }
      if (!flag) {
        setEnableDatePicker(false);
        count--;
      } else {
        setEnableDatePicker(true);
        flag = false;
      }
      setSubPackages([]);
      if (packageDetails?.enable_sub_packages == "1") {
        for (let i = 0; i < packageDetails?.sub_packages.length; i++) {
          if (
            packageDetails?.sub_packages[i].enable == "1" &&
            packageDetails?.sub_packages[i].package_name &&
            packageDetails?.sub_packages[i].package_name.length > 2 &&
            packageDetails?.sub_packages[i].adult_price
          ) {
            flag = true;
            setSubPackages((subPackages) => [
              ...subPackages,
              {
                value: `${packageDetails?.sub_packages[i]?.package_name}`,
                label: `${packageDetails?.sub_packages[i]?.package_name}`,
                adult_price: `${packageDetails?.sub_packages[i]?.adult_price}`,
                child_price: `${packageDetails?.sub_packages[i]?.child_price}`,
                adult_person: 0,
                id: `${packageDetails?.sub_packages[i]?.id}`,
                child_person: 0,
              },
            ]);
          }
        }
      }
      if (!flag) {
        setEnableSubPackages(false);
        count--;
      } else {
        setEnableSubPackages(true);
        flag = false;
      }
      setMeals([]);
      if (packageDetails?.enable_meal == "1") {
        for (let i = 0; i < packageDetails?.meals.length; i++) {
          if (
            packageDetails?.meals[i].enable == "1" &&
            packageDetails?.meals[i].meal_name &&
            packageDetails?.meals[i].meal_name.length > 1 &&
            packageDetails?.meals[i].price
          ) {
            flag = true;
            setMeals((meals) => [
              ...meals,
              {
                value: `${packageDetails?.meals[i]?.meal_name}`,
                label: `${packageDetails?.meals[i]?.meal_name}`,
                price: `${packageDetails?.meals[i]?.price}`,
                person: 0,
                description: `${packageDetails?.meals[i]?.description}`,
                id: `${packageDetails?.meals[i]?.id}`,
              },
            ]);
          }
        }
      }
      if (!flag) {
        setEnableMeal(false);
        count--;
      } else {
        setEnableMeal(true);
        flag = false;
      }
      setTransports([]);
      if (packageDetails?.enable_transport == "1") {
        for (let i = 0; i < packageDetails?.transport.length; i++) {
          if (
            packageDetails?.transport[i].enable == "1" &&
            packageDetails?.transport[i].name.length > 1 &&
            packageDetails?.transport[i].one_way.length > 0
          ) {
            flag = true;
            setTransports((transport) => [
              ...transport,
              {
                value: `${packageDetails?.transport[i]?.name}`,
                label: `${packageDetails?.transport[i]?.name}`,
                one_way: `${packageDetails?.transport[i]?.one_way}`,
                two_way: `${packageDetails?.transport[i]?.two_way}`,
                id: `${packageDetails?.transport[i]?.id}`,
              },
            ]);
          }
        }
      }
      if (!flag) {
        setEnableTransport(false);
        count--;
      } else {
        setEnableTransport(true);
        flag = false;
      }
      if (packageDetails?.enable_time_picker == "1") {
        flag = true;
      }
      if (!flag) {
        setEnableTimePicker(false);
        count--;
      } else {
        setEnableTimePicker(true);
        flag = false;
      }
      setProgressCount(count);
      if (count > 1) {
        setProgressCount(count);
        setProgressValue(100 / count);
        showBooking(false);
      } else {
        setProgressValue(100);
      }
      if (timeSlots && timeSlots[0]?.value) {
        setTimeSlotValue(timeSlots[0].value.slice(0, 7));
      }
      if (packageDetails?.adult_booked_times) {
        setAdultBookedTimes(packageDetails?.adult_booked_times);
      }
      if (packageDetails?.adult_overall_limit) {
        setAdultOverAllLimit(packageDetails?.adult_overall_limit);
      }
      if (packageDetails?.child_booked_times) {
        setChildBookedTimes(packageDetails?.child_booked_times);
      }
      if (packageDetails?.child_overall_limit) {
        setChildOverAllLimit(packageDetails?.child_overall_limit);
      }
      getPackageTickets(packageDetails?.id);
    }
  }, [packageDetails]);

  // useEffect(() => {
  //   if (dateAvailability) {
  //     setTotalAdultTickets(dateAvailability?.data.adult_tickets);
  //     setTotalChildTickets(dateAvailability?.data.child_tickets);
  //   }
  // }, [dateAvailability]);

  useEffect(() => {
    let flag = false;
    for (var i = 0; i < subPackages.length; i++) {
      if (subPackages[i].adult_person > 0 || subPackages[i].child_person > 0) {
        flag = true;
        showSubTitle(true);
      }
    }
    if (flag == false) {
      showSubTitle(false);
    }
  }, [subPackages]);

  useEffect(() => {
    let flag = false;
    for (var i = 0; i < meals.length; i++) {
      if (meals[i].person > 0) {
        flag = true;
        showMealTitle(true);
      }
    }
    if (flag == false) {
      showMealTitle(false);
    }
  }, [meals]);

  useEffect(() => {
    let totalAmount = 0;
    // if (
    //   packageDetails?.id === "2501" ||
    //   packageDetails?.id === "2502" ||
    //   packageDetails?.id === "2503" ||
    //   packageDetails?.id === "2504" ||
    //   packageDetails?.id === "2505" ||
    //   packageDetails?.id === "2596" ||
    //   packageDetails?.id === "2595" ||
    //   packageDetails?.id === "2633" ||
    //   packageDetails?.id === "2634"
    // ) {
    //   totalAmount =
    //     addPerson *
    //       (packageDetails?.id === "2501" ||
    //       packageDetails?.id === "2502" ||
    //       packageDetails?.id === "2503" ||
    //       packageDetails?.id === "2504" ||
    //       packageDetails?.id === "2505" ||
    //       packageDetails?.id === "2596" ||
    //       packageDetails?.id === "2634"
    //         ? customChildValue.reduce(function (sum, item) {
    //             return Number(sum) + Number(item.price);
    //           }, 0)
    //         : packageDetails?.price) +
    //     addChild *
    //       (packageDetails?.id === "2595" || packageDetails?.id === "2633"
    //         ? customChildValue.reduce(function (sum, item) {
    //             return Number(sum) + Number(item.price);
    //           }, 0)
    //         : packageDetails?.child_price) +
    //     packagePrice +
    //     mealPrice +
    //     transportPrice;
    // } else {
    totalAmount =
      packageTickets.reduce(
        (acc, item) => (item.qty ? acc + Number(item.qty * item.price) : acc),
        0
      ) +
      // groupTickets.reduce((acc, ticketGroup) => {
      //   const groupTotal = ticketGroup.tickets.reduce((groupAcc, ticket) => {
      //     return groupAcc + ticket.price * ticket.qty;
      //   }, 0);
      //   return acc + groupTotal;
      // }, 0) +
      packagePrice +
      mealPrice +
      transportPrice;
    // }

    let cpTotal = totalAmount;
    if (skipAddon == "1") {
      cpTotal = cpTotal - packagePrice;
    }
    if (skipTranport == "1") {
      cpTotal = cpTotal - transportPrice;
    }
    if (skipFood == "1") {
      cpTotal = cpTotal - mealPrice;
    }

    console.log("skipdata", skipAddon, skipTranport, skipFood);
    // console.log(
    //   addPerson,
    //   addChild,
    //   packagePrice,
    //   mealPrice,
    //   transportPrice,
    //   totalAmount
    // );
    if (offType == "aed") {
      if (totalAmount - offValue > 0) {
        if (maxDiscountVal) {
          offValue = Math.min(parseInt(offValue), parseInt(maxDiscountVal));
        }
        if (minCart) {
          if (totalAmount >= minCart) {
            setTotal(totalAmount - offValue);
          } else {
            setTotal(totalAmount);
          }
        } else {
          setTotal(totalAmount - offValue);
        }
      } else {
        setTotal(0);
      }
    } else if (offType == "percentage") {
      if (totalAmount - (cpTotal * offValue) / 100 > 0) {
        let maxDiscount = ((cpTotal * offValue) / 100).toFixed(2);
        if (maxDiscountVal) {
          maxDiscount = Math.min(
            parseInt(maxDiscount),
            parseInt(maxDiscountVal)
          );
        }
        if (minCart) {
          if (totalAmount >= minCart) {
            setTotal(totalAmount - maxDiscount);
          } else {
            setTotal(totalAmount);
          }
        } else {
          setTotal(totalAmount - maxDiscount);
        }
      } else {
        setTotal(0);
      }
    } else if (offType == "buy_x_get_y") {
      let buyQty = couponBuyQty;
      let freeQty = couponGetQty;
      let tempChildQty = addChild;
      let tempChildQtyt = addChild;
      let tempAdultQty = addPerson;
      let tempAdultQtyt = addPerson;
      let buyType = couponBuy;
      let freeType = couponGet;

      if (buyType == "adult") {
        for (var i = 1; i <= tempAdultQtyt; i++) {
          if (i % buyQty == 0) {
            if (freeType == "child") {
              for (var j = 1; j <= freeQty; j++) {
                if (tempChildQty > 0) tempChildQty = tempChildQty - 1;
              }
            } else if (freeType == "adult") {
              for (var j = 1; j <= freeQty; j++) {
                if (tempAdultQty > i) tempAdultQty = tempAdultQty - 1;
              }
            }
          }
        }
      } else if (buyType == "child") {
        for (var i = 1; i <= tempChildQtyt; i++) {
          if (i % buyQty == 0) {
            if (freeType == "child") {
              for (var j = 1; j <= freeQty; j++) {
                if (tempChildQty > i) tempChildQty = tempChildQty - 1;
              }
            } else if (freeType == "adult") {
              for (var j = 1; j <= freeQty; j++) {
                if (tempAdultQty > 0) tempAdultQty = tempAdultQty - 1;
              }
            }
          }
        }
      }
      var total_amount =
        tempChildQty * packageDetails?.child_price +
        tempAdultQty * packageDetails?.price;
      var couponVal =
        addPerson * adultPrice + addChild * childPrice - total_amount;
      setTotal(totalAmount - couponVal);
    } else {
      setTotal(totalAmount);
    }
  }, [
    packageTickets,
    groupTickets,
    packagePrice,
    mealPrice,
    transportPrice,
    customChildValue,
  ]);

  // `useEffect(() => {
  //   console.log(packageSlots);
  //   setTimeSlots([]);
  //   for (let i = 0; i < packageSlots?.length; i++) {
  //     if (packageSlots[i].adult_tickets === "") {
  //       var adultTicket = 9999;
  //     } else {
  //       var adultTicket = packageSlots[i].adult_tickets;
  //     }
  //     if (packageSlots[i].child_tickets === "") {
  //       var childTicket = 9999;
  //     } else {
  //       var childTicket = packageSlots[i].child_tickets;
  //     }
  //     console.log(adultTicket, childTicket);
  //     if (
  //       adultTicket >= addPerson &&
  //       childTicket >= addChild &&
  //       packageSlots[i].time_slot != "" &&
  //       packageSlots[i].enable === "1"
  //     ) {
  //       setTimeSlots((timeSlots) => [
  //         ...timeSlots,
  //         {
  //           value: `${packageSlots[i]?.time_slot}`,
  //           label: `${packageSlots[i]?.time_slot}`,
  //           adult_limit: `${
  //             packageSlots[i].adult_tickets == ""
  //               ? 1000
  //               : packageSlots[i].adult_tickets
  //           }`,
  //           child_limit: `${
  //             packageSlots[i]?.child_tickets == ""
  //               ? 1000
  //               : packageSlots[i]?.child_tickets
  //           }`,
  //           id: `${packageSlots[i]?.id}`,
  //         },
  //       ]);
  //     }
  //   }
  // }, [packageSlots, packageTickets]);`

  const changeTimeSlot = (idx) => {
    let index = parseInt(idx);
    setTimeSlot(timeSlots[index].time_slot.slice(0, 7));
    setSelectedTimeSlot(index);
    setTimeSlotValue(timeSlots[index].time_slot.slice(0, 7));
  };

  const checkTransport = (item, flag) => {
    if (item == null && flag == false) {
      setTransportID(0);
      setTransportPrice(0);
      return;
    }
    setTransportName(item.value);
    setOneWay(flag);
    setTransportID(item.id);
    if (flag) {
      setTransportPrice(parseInt(item.one_way));
    } else {
      setTransportPrice(parseInt(item.two_way));
    }
  };

  const handleDateChange = (date) => {
    const d = new Date(date.value);
    console.log(date.value || new Date());
    // if (
    //   d.getDate() == "31" &&
    //   d.getMonth() == "11" &&
    //   packageDetails.destination_id == "1"
    // ) {
    //   alert(
    //     "Please call us on +971 6 802 1111 or email us on mleihamanagement@discovermleiha.ae to make your booking"
    //   );
    //   setSelectedDate(null);
    //   setRealDate("");
    //   return false;
    // }

    setLoading(true);
    setSelectedDate(date.value);
    setRealDate(moment(date.value).format("DD/MM"));
    setTimeSlot("");
    setSelectedTimeSlot(-1);
    const dateCheck = new FormData();
    dateCheck.append("action", "getPackagesDailyLimit");
    dateCheck.append("package_id", packageDetails?.id);
    dateCheck.append(
      "date",
      moment(date.value || new Date()).format("MM/DD/YYYY")
    );
    getDateAvailability(dateCheck);
    if (packageDetails?.enable_time_picker == "1") {
      const slotsData = new FormData();
      slotsData.append("action", "packageSlots");
      slotsData.append("id", packageDetails?.id);
      slotsData.append("date", moment(date).format("MM/DD/YYYY"));
      getPackageSlots(slotsData);
    }
  };

  const getPackageSlots = async (slotsData) => {
    setLoading(true);
    const data = await fetchClient(
      getAccessTokenSilently,
      isAuthenticated,
      "",
      slotsData,
      false
    );
    if (data) setTimeSlots(data.data);
    setLoading(false);
  };

  const getDateAvailability = async (dateCheck) => {
    setLoading(true);
    const data = await fetchClient(
      getAccessTokenSilently,
      isAuthenticated,
      "",
      dateCheck,
      false
    );
    if (data) setDateAvailability(data?.data || []);
    setLoading(false);
  };

  const setNumPerson = (adult, child) => {
    setAddPerson(adult);
    setAddChild(child);
  };

  const addSubAdult = (id, state, item) => {
    if (state) {
      if (
        item.adult_person == 0 ||
        item.adult_price == "" ||
        parseInt(item.adult_price) == 0
      ) {
        return;
      }
      subPackages[id].adult_person = item.adult_person - 1;
    } else {
      if (item.adult_price == "" || parseInt(item.adult_price) == 0) {
        return;
      }
      subPackages[id].adult_person = item.adult_person + 1;
    }
    setSubPackages((subPackages) => [...subPackages]);
    calulatePackages();
  };

  const addSubChild = (id, state, item) => {
    if (state) {
      if (
        item.child_person == 0 ||
        item.child_price == "" ||
        parseInt(item.child_price) == 0
      ) {
        return;
      }
      subPackages[id].child_person = item.child_person - 1;
    } else {
      if (item.child_price == "" || parseInt(item.child_price) == 0) {
        return;
      }
      subPackages[id].child_person = item.child_person + 1;
    }
    setSubPackages((subPackages) => [...subPackages]);
    calulatePackages();
  };

  const calulatePackages = () => {
    let sum_person = 0;
    let sum_adult = 0;
    let sum_child = 0;
    let sum_price = 0;
    for (let i = 0; i < subPackages?.length; i++) {
      sum_adult += parseInt(subPackages[i]?.adult_person);
      sum_price +=
        parseInt(subPackages[i]?.adult_price) *
        parseInt(subPackages[i]?.adult_person);
      sum_child += parseInt(subPackages[i]?.child_person);
      if (
        subPackages[i]?.child_price != "" &&
        subPackages[i]?.child_price != null
      ) {
        sum_price +=
          parseInt(subPackages[i]?.child_price) *
          parseInt(subPackages[i]?.child_person);
      }
    }
    setSubAdultQty(sum_adult);
    setSubChildQty(sum_child);
    setPackagePrice(sum_price);
    if (sum_adult + sum_child == 0) {
      setPackageName("SELECT PACKAGE");
    } else {
      setPackageName(sum_adult + sum_child + " items, " + "AED " + sum_price);
    }
  };

  const addMealPerson = (id, state, item) => {
    if (state) {
      if (item.person == 0) {
        return;
      }
      meals[id].person = item.person - 1;
    } else {
      meals[id].person = item.person + 1;
    }
    setMeals((meals) => [...meals]);
    calulateMeals();
  };

  const calulateMeals = () => {
    let sum_person = 0;
    let sum_price = 0;
    for (let i = 0; i < meals?.length; i++) {
      sum_person += parseInt(meals[i]?.person);
      sum_price += parseInt(meals[i]?.price) * parseInt(meals[i]?.person);
    }
    setMealPrice(sum_price);
    if (sum_person == 0) {
      setMealName("SELECT MEALS");
    } else {
      setMealName(sum_person + " items, " + "AED " + sum_price);
    }
  };

  const nextCheckPackage = () => {
    let temp = 100 / progressCount;
    if (!isDate) {
      isDate = true;
      if (parseInt(progressValue + temp) == 100) {
        showBooking(true);
      }
      if (!enable_date_picker) {
        showTimeSlotSelector(true);
        nextPage();
      }
    } else if (!isTime) {
      isTime = true;
      if (parseInt(progressValue + temp) == 100) {
        showBooking(true);
      }
      if (!enable_time_picker) {
        showSubPackageSelector(true);
        nextPage();
      }
    } else if (!isSub) {
      isSub = true;
      if (parseInt(progressValue + temp) == 100) {
        showBooking(true);
      }
      if (!enable_sub_packages) {
        showMealSelector(true);
        nextPage();
      }
    } else if (!isTransport) {
      isTransport = true;
      if (parseInt(progressValue + temp) == 100) {
        showBooking(true);
      }
      if (!enable_transport) {
        showTransportSelector(true);
        nextPage();
      }
    } else if (!isMeal) {
      isMeal = true;
      if (parseInt(progressValue + temp) == 100) {
        showBooking(true);
      }
      if (!enable_meal) {
        showBooking(true);
        nextPage();
      }
    }
  };

  const validateCoupon = () => {
    if (!coupon) {
      notifyInfo(language.no_coupon[locale]);
      return;
    }
    let subpackage_id = [];
    let subpackage_adult = [];
    let subpackage_child = [];
    for (let i = 0; i < subPackages?.length; i++) {
      if (
        subPackages[i].adult_person == 0 &&
        subPackages[i].child_person == 0
      ) {
        continue;
      }
      subpackage_id.push(subPackages[i].id);
      subpackage_adult.push(subPackages[i].adult_person);
      subpackage_child.push(subPackages[i].child_person);
    }
    let meal_id = [];
    let meal_qty = [];
    for (let i = 0; i < meals?.length; i++) {
      if (meals[i].person == 0) {
        continue;
      }
      meal_id.push(meals[i].id);
      meal_qty.push(meals[i].person);
    }
    const tickets = packageTickets.map((ticket) => {
      return { ticket_id: ticket.id, qty: ticket.qty };
    });
    // const groupTicketsData = groupTickets.flatMap((ticketGroup) =>
    //   ticketGroup.tickets.map((ticket) => ({
    //     group_id: ticketGroup.id,
    //     qty: ticket.qty,
    //   }))
    // );
    // const mergedTickets = tickets.concat(groupTicketsData);
    // console.log(mergedTickets);
    // const filteredTickets = mergedTickets.filter((ticket) => ticket.qty > 0);
    // console.log(filteredTickets);

    let bookData = {
      package_id: packageDetails?.id,
      tickets: tickets,
      // adult_qty: addPerson,
      // child_qty: addChild,
    };
    if (subpackage_id.length > 0) {
      bookData = {
        ...bookData,
        subpackages_id: subpackage_id.map((id) => Number(id)),
        subpackages_adult_qty: subpackage_adult,
        subpackages_child_qty: subpackage_child,
      };
    }
    if (timeSlots) {
      bookData = {
        ...bookData,
        time_slot_id: timeSlots[selectedTimeSlot]?.id,
        time_slot_data: timeSlots[selectedTimeSlot],
      };
    }
    if (packageDetails?.enable_date_picker) {
      bookData = {
        ...bookData,
        date: moment(selectedDate).format("YYYY-MM-DD"),
      };
    }
    if (meal_id.length > 0) {
      bookData = { ...bookData, meals_id: meal_id.map((id) => Number(id)) };
    }
    if (meal_qty.length > 0) {
      bookData = { ...bookData, meals_qty: meal_qty };
    }
    if (transports.length > 0) {
      if (transport_id != 0) {
        bookData = {
          ...bookData,
          transport_id: transport_id,
          transport_type: isOneWay,
          transport_name: transport_name,
        };
      }
    }
    if (shipping) bookData = { ...bookData, shipping_id: "1" };
    bookData.total =
      ((summerCamp
        ? selectedSummerGroup
          ? selectedSummerGroup.price
          : selectedSummerDate.reduce((sum, value) => {
              return sum + value.price;
            }, 0)
        : 0) +
        total) *
      packageTickets.reduce((sum, item) => sum + item.qty, 0);
    //   +
    // groupTickets.reduce((acc, ticketGroup) => {
    //   const groupTotal = ticketGroup.tickets.reduce((groupAcc, ticket) => {
    //     return groupAcc + ticket.price * ticket.qty;
    //   }, 0);
    //   return acc + groupTotal;
    // }, 0);
    bookData = { packages: [{ ...bookData }] };
    if (summerCamp) {
      bookData = {
        packages: [
          {
            ...bookData.packages[0],
            summercamps: [
              {
                qty: "2",
                dates: selectedSummerDate.map((item) => item.date),
              },
            ],
          },
        ],
      };
    }
    console.log(attendeesList);
    if (collectDetails) {
      bookData = {
        packages: [
          {
            ...bookData.packages[0],
            attendees: attendeesList
              .filter((att) => att.checked)
              .map((at) => at.id),
          },
        ],
      };
    }
    if (coupon) {
      bookData = { ...bookData, coupon: coupon };
    }
    const data = new FormData();
    data.append("action", "validateCoupon");
    data.append("items", JSON.stringify(bookData));
    data.append("site", "houseofwisdom.ae");
    data.append("channel", "houseofwisdom-web");
    getCouponValidity(data);
  };

  const getCouponValidity = async (formData) => {
    setLoading(true);
    const data = await fetchClient(
      getAccessTokenSilently,
      isAuthenticated,
      "",
      formData,
      false
    );
    if (data) {
      setAmount(data.data.packages[0].total);
      setResetCoupon(true);
    }
    setLoading(false);
  };

  const bookYourPackage = async () => {
    setLoading(true);
    let subpackage_id = [];
    let subpackage_adult = [];
    let subpackage_child = [];
    for (let i = 0; i < subPackages?.length; i++) {
      if (
        subPackages[i].adult_person == 0 &&
        subPackages[i].child_person == 0
      ) {
        continue;
      }
      subpackage_id.push(subPackages[i].id);
      subpackage_adult.push(subPackages[i].adult_person);
      subpackage_child.push(subPackages[i].child_person);
    }
    let meal_id = [];
    let meal_qty = [];
    for (let i = 0; i < meals?.length; i++) {
      if (meals[i].person == 0) {
        continue;
      }
      meal_id.push(meals[i].id);
      meal_qty.push(meals[i].person);
    }
    var customPrice = {};
    console.log(customChildValue);
    if (
      packageDetails.id === "2501" ||
      packageDetails.id === "2502" ||
      packageDetails.id === "2503" ||
      packageDetails.id === "2504" ||
      packageDetails.id === "2505" ||
      packageDetails.id === "2596" ||
      packageDetails.id === "2634"
    ) {
      customPrice = {
        adult_price: customChildValue.reduce(function (sum, item) {
          return Number(sum) + Number(item.price);
        }, 0),
        notes: customChildValue.map((ch) => ch.label).join(),
      };
    } else if (packageDetails.id === "2595" || packageDetails.id === "2633") {
      customPrice = {
        child_price: customChildValue.reduce(function (sum, item) {
          return Number(sum) + Number(item.price);
        }, 0),
        notes: customChildValue.map((ch) => ch.label).join(),
      };
    }

    const tickets = packageTickets
      .filter((packageTick) => packageTick.qty)
      .map((ticket) => {
        return { ticket_id: ticket.id, qty: ticket.qty };
      });
    // const groupTicketsData = groupTickets.flatMap((ticketGroup) =>
    //   ticketGroup.tickets.map((ticket) => ({
    //     group_id: ticketGroup.id,
    //     qty: ticket.qty,
    //   }))
    // );
    // const mergedTickets = tickets.concat(groupTicketsData);
    // console.log(mergedTickets);
    // const filteredTickets = mergedTickets.filter((ticket) => ticket.qty > 0);
    // console.log(filteredTickets);

    let bookData = {
      package_id: packageDetails?.id,
      tickets: tickets,
      // adult_qty: addPerson,
      // child_qty: addChild,
    };
    if (subpackage_id.length > 0) {
      bookData = {
        ...bookData,
        subpackages_id: subpackage_id.map((id) => Number(id)),
        subpackages_adult_qty: subpackage_adult,
        subpackages_child_qty: subpackage_child,
      };
    }
    if (timeSlots) {
      bookData = {
        ...bookData,
        time_slot_id: timeSlots[selectedTimeSlot]?.id,
        time_slot_data: timeSlots[selectedTimeSlot],
      };
    }
    if (packageDetails?.enable_date_picker) {
      bookData = {
        ...bookData,
        date: moment(selectedDate).format("YYYY-MM-DD"),
      };
    }
    if (meal_id.length > 0) {
      bookData = { ...bookData, meals_id: meal_id.map((id) => Number(id)) };
    }
    if (meal_qty.length > 0) {
      bookData = { ...bookData, meals_qty: meal_qty };
    }
    if (transports.length > 0) {
      if (transport_id != 0) {
        bookData = {
          ...bookData,
          transport_id: transport_id,
          transport_type: isOneWay,
          transport_name: transport_name,
        };
      }
    }
    if (shipping) bookData = { ...bookData, shipping_id: "1" };
    bookData.total =
      ((summerCamp
        ? selectedSummerGroup
          ? selectedSummerGroup.price
          : selectedSummerDate.reduce((sum, value) => {
              return sum + value.price;
            }, 0)
        : 0) +
        total) *
      packageTickets.reduce((sum, item) => sum + item.qty, 0);
    //   +
    // groupTickets.reduce((acc, ticketGroup) => {
    //   const groupTotal = ticketGroup.tickets.reduce((groupAcc, ticket) => {
    //     return groupAcc + ticket.price * ticket.qty;
    //   }, 0);
    //   return acc + groupTotal;
    // }, 0);
    bookData = { packages: [{ ...bookData }] };
    if (summerCamp) {
      bookData = {
        packages: [
          {
            ...bookData.packages[0],
            summercamps: [
              {
                qty: "2",
                dates: selectedSummerDate.map((item) => item.date),
              },
            ],
          },
        ],
      };
    }
    console.log(attendeesList);
    if (collectDetails) {
      bookData = {
        packages: [
          {
            ...bookData.packages[0],
            attendees: attendeesList
              .filter((att) => att.checked)
              .map((at) => at.id),
          },
        ],
      };
    }
    if (coupon) {
      bookData = { ...bookData, coupon: coupon };
    }
    bookData = {
      ...bookData,
      site: window.location.host,
      // redirect_to: "https://discovershurooq.ae/order-history",
    };
    console.log(bookData);
    const bookFormData = new FormData();
    bookFormData.append("action", "createPurchase");
    bookFormData.append("items", JSON.stringify(bookData));
    bookFormData.append("site", "houseofwisdom.ae");
    bookFormData.append("channel", "houseofwisdom-web");
    // bookFormData.append(
    //   "redirect_to",
    //   "https://discovershurooq.ae/order-history"
    // );
    const data = await fetchClient(
      getAccessTokenSilently,
      isAuthenticated,
      "",
      bookFormData,
      false
    );
    if (data) initiatePayment(data);
    else setLoading(false);
    ReactPixel.track("InitiateCheckout", {
      content_ids: [`p-${packageSlug}`],
      content_type: "product",
      value: `${bookData.packages[0].total}`,
      currency: "AED",
    });
  };

  const initiatePayment = async (bookingData) => {
    const formData = new FormData();
    formData.append("action", "initiatePayment");
    formData.append("purchase_number", bookingData.purchase_number);
    formData.append("purchase_id", bookingData.id);
    formData.append(
      "redirect_to",
      `${window.location.origin}/purchase_status/${bookingData.purchase_number}`
    );
    const data = await fetchClient(
      getAccessTokenSilently,
      isAuthenticated,
      "",
      formData,
      false
    );
    if (data) window.location.href = data.payment_link;
    else setLoading(false);
  };

  useEffect(() => {
    if (step === 1 && collectDetails) retrieveAttendees();
  }, [step]);

  const retrieveAttendees = async () => {
    setLoading(true);
    const formData = new FormData();
    formData.append("action", "get_attendees");
    const data = await fetchClient(
      getAccessTokenSilently,
      isAuthenticated,
      "",
      formData,
      false
    );
    if (data)
      setAttendessList(
        data.data.map((dt) => {
          return { ...dt, checked: false };
        })
      );
    setOpenModal(false);
    setDetails({
      name: "",
      dob: "",
    });
    setLoading(false);
  };

  const addAttendees = async () => {
    if (!details.name) {
      alert(language.enter_name[locale]);
      return;
    }
    if (!details.dob) {
      alert(language.dob[locale]);
      return;
    }
    setLoading(true);
    const formData = new FormData();
    formData.append("action", "add_attendees");
    formData.append("name", details.name);
    formData.append("dob", moment(details.dob).format("YYYY-MM-DD"));
    const data = await fetchClient(
      getAccessTokenSilently,
      isAuthenticated,
      "",
      formData,
      false
    );
    if (data) retrieveAttendees();
    setLoading(false);
  };

  const getPackageTickets = async (id) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("action", "getPackageTickets");
    formData.append("package_id", id);
    const data = await fetchClient(
      getAccessTokenSilently,
      isAuthenticated,
      "",
      formData,
      false
    );
    if (data) {
      console.log(data.data);
      setPackageTickets(
        data.data.tickets.map((dt) => {
          return { ...dt, qty: 0 };
        })
      );
      // setGroupTickets(
      //   data.data.tickets_group.map((group) => {
      //     let updatedTickets = group.tickets.map((ticket) => {
      //       return { ...ticket, qty: 0 };
      //     });
      //     return { ...group, tickets: updatedTickets };
      //   })
      // );
    }
    setLoading(false);
  };

  const count = useMemo(() => {
    var count = 0;
    count = packageTickets.reduce((sum, item) => sum + item.qty, 0);
    // +
    // groupTickets.reduce((acc, ticketGroup) => {
    //   const groupTotal = ticketGroup.tickets.reduce((groupAcc, ticket) => {
    //     return groupAcc + ticket.price * ticket.qty;
    //   }, 0);
    //   return acc + groupTotal;
    // }, 0);
    return count;
  }, [packageTickets, groupTickets]);

  const nextPage = () => {
    let err = "";
    if (step === 0) {
      // if (packageDetails.adult_min_qty || packageDetails.child_min_qty) {
      //   if (addPerson < packageDetails.adult_min_qty) {
      //     err += `${language.adult_min[locale]} ${packageDetails.adult_min_qty}`;
      //   }
      //   if (
      //     packageDetails.adult_max_qty &&
      //     addPerson > packageDetails.adult_max_qty
      //   ) {
      //     err += `${language.adult_max[locale]} ${packageDetails.adult_max_qty}`;
      //   }
      //   if (addChild < packageDetails.child_min_qty) {
      //     err += `\n${language.child_min[locale]} ${packageDetails.child_min_qty}`;
      //   }
      //   if (
      //     packageDetails.child_max_qty &&
      //     addChild > packageDetails.child_max_qty
      //   ) {
      //     err += `\n${language.child_max[locale]} ${packageDetails.child_max_qty}`;
      //   }

      //   if (err != "") {
      //     alert(err);
      //     return false;
      //   }
      // }

      // if (packageDetails.overall_min_limit) {
      // let total_person = packageTickets.reduce(
      //               (sum, item) => sum + item.qty,
      //               0
      //             );
      // packageTickets.find((ticket) => {
      //   if (ticket.qty < Number(ticket.min_select)) {
      //     err = `Select Minimum ${ticket.min_select} ${ticket.label}`;
      //   }
      // });

      // groupTickets.forEach((ticketGroup) => {
      //   ticketGroup.tickets.forEach((ticket) => {
      //     if (ticket.qty < Number(ticket.min_select)) {
      //       err = `Select Minimum ${ticket.min_select} ${ticket.label}`;
      //     }
      //   });
      // });
      // if (err != "") {
      //   alert(err);
      //   return false;
      // }
      // {
      //     alert(`Select Minimum ${packageDetails.overall_min_limit} Person`);
      //     return false;
      //   }
      // }

      if (!packageTickets.reduce((sum, item) => sum + item.qty, 0)) {
        alert(language.min_passangers[locale]);
        return false;
      }
      // let invalidTickets = [];

      // for (let i = 0; i < packageTickets.length; i++) {
      //   const item = packageTickets[i];
      //   if (
      //     parseInt(item.min_select) &&
      //     !parseInt(item.qty) &&
      //     parseInt(item.qty) < parseInt(item.min_select)
      //   ) {
      //     invalidTickets.push(
      //       `${item.label} - Minimum Selection of ${item.min_select} is required`
      //     );
      //   }
      // }

      // if (invalidTickets.length > 0) {
      //   alert(invalidTickets.join("\n"));
      //   return false;
      // }
    }
    if (step === 1 && collectDetails) {
      const count = packageTickets.reduce((sum, item) => sum + item.qty, 0);
      // +
      // groupTickets.reduce((acc, ticketGroup) => {
      //   const groupTotal = ticketGroup.tickets.reduce((groupAcc, ticket) => {
      //     return groupAcc + ticket.price * ticket.qty;
      //   }, 0);
      //   return acc + groupTotal;
      // }, 0);
      const checkedAttendees = attendeesList.filter(
        (attendee) => attendee.checked
      );

      if (checkedAttendees.length !== count) {
        setAttendeeAlert(
          `${language.Kindly_select[locale]} ${count} ${language.attendees[locale]}`
        );
        return false;
      }
    }
    if (step === 2) {
      if (enable_date_picker && !isTimeSlotSelector) {
        if (realDate == "Invalid date") {
          alert(language.select_date[locale]);
          return false;
        }
        console.log(packageTickets, dateAvailability);
        const limitReached = packageTickets.some((ticket) => {
          const availability = dateAvailability.find(
            (avail) => avail.ticket_id === Number(ticket.id)
          );
          return availability ? ticket.qty > availability?.daily_limit : false;
        });
        console.log(limitReached);
        if (limitReached) {
          alert(language.dailyLimit[locale]);
          return false;
        }
        // if (
        //   total_adult_tickets >= addPerson &&
        //   total_child_tickets >= addChild
        // ) {
        //   let temp = 100 / progressCount;
        //   setProgressValue(progressValue + temp);
        //   if (isTransportSelector) {
        //     showMealSelector(true);
        //     nextCheckPackage();
        //   } else if (isSubPackageSelector) {
        //     showTransportSelector(true);
        //     nextCheckPackage();
        //   } else if (isTimeSlotSelector) {
        //     showSubPackageSelector(true);
        //     nextCheckPackage();
        //   } else if (isDateSelector) {
        //     // showTimeSlotSelector(true);
        //     nextCheckPackage();
        //   } else {
        //     // showDateSelector(true);
        //     nextCheckPackage();
        //   }
        //   return true;
        // } else {
        //   alert("Selected Date is Fully Booked");
        //   return false;
        // }
      }
    }
    if (step === 3 && summerCamp) {
      if (!selectedSummerDate.length) {
        alert(language.select_date[locale]);
        return false;
      }
    }
    if (step === 4) {
      if (enable_time_picker) {
        if (time_slot == "") {
          alert(language.select_time[locale]);
          return false;
        }
        if (timeSlots?.length < 0) {
          alert(language.time_fully_booked[locale]);
          return false;
        }
      }
      // if (isLoading) {
      //   return false;
      // }
      // let temp = 100 / progressCount;
      // setProgressValue(progressValue + temp);
      // if (isMealSelector) {
      //   showMealSelector(false);
      //   showSubPackageSelector(false);
      //   showTimeSlotSelector(false);
      //   showDateSelector(false);
      //   showTransportSelector(false);
      // } else if (isTransportSelector) {
      //   showMealSelector(true);
      //   nextCheckPackage();
      // } else if (isSubPackageSelector) {
      //   showTransportSelector(true);
      //   nextCheckPackage();
      // } else if (isTimeSlotSelector) {
      //   showSubPackageSelector(true);
      //   nextCheckPackage();
      // } else if (isDateSelector) {
      //   showTimeSlotSelector(true);
      //   nextCheckPackage();
      // } else {
      //   // showDateSelector(true);
      //   nextCheckPackage();
      // }
    }
    return true;
  };

  const nextStep = () => {
    if (nextPage()) {
      let nextStep;
      if (
        (step === 3 || step === 2 || step === 1 || step === 0) &&
        !collectDetails &&
        !enable_date_picker &&
        !enable_time_picker &&
        !summerCamp
      ) {
        nextStep = 5; // Render 5th component
      } else if (step === 0 && !collectDetails) {
        nextStep = 2; // Render 2nd component
      } else if (step === 1 && !enable_date_picker && !enable_time_picker) {
        nextStep = summerCamp ? 3 : 5; // Render 3rd component if summerCamp is true, else move to 5th component
      } else if (step === 2 && !summerCamp) {
        nextStep = enable_time_picker ? 4 : 5; // Render 4th component
      } else if (step === 3 && !enable_time_picker) {
        nextStep = 5; // Stay on 3rd component if enable_time_picker is false
      } else {
        nextStep = step + 1; // Increment step
      }

      console.log(summerCamp, nextStep);

      setStep(nextStep);
    }
  };

  const prevStep = () => {
    let previousStep;
    if (
      step === 5 &&
      !collectDetails &&
      !enable_date_picker &&
      !enable_time_picker &&
      !summerCamp
    ) {
      previousStep = 0; // Render 1st component
    } else if (
      step === 5 &&
      !enable_time_picker &&
      !enable_date_picker &&
      summerCamp
    ) {
      previousStep = 3; // Render 3rd component
    } else if (
      (step === 5 || step === 4) &&
      !summerCamp &&
      enable_date_picker
    ) {
      previousStep = 2; // Render 2nd component
    } else if (
      (step === 5 || step === 4 || step === 3) &&
      !enable_date_picker &&
      collectDetails
    ) {
      previousStep = 1; // Render 1st component
    } else if (step === 2 && enable_date_picker) {
      previousStep = 0; // Render 1st component
    } else if (step === 3 && !enable_time_picker && summerCamp) {
      previousStep = 1; // Render 1st component if on 3rd and time picker disabled due to summerCamp
    } else if (step === 4 && !enable_time_picker && !summerCamp) {
      previousStep = 2; // Render 2nd component if on 4th and time picker disabled and not due to summerCamp
    } else {
      previousStep = step - 1; // Decrement step
    }
    console.log(previousStep);
    setStep(previousStep);
  };

  // function gotoSlide(slide_name) {
  //   if (addPerson == 0 && addChild == 0) {
  //     alert("Select Passengers - Minimum 1");
  //     return false;
  //   }
  //   switch (slide_name) {
  //     case "user":
  //       isDate = false;
  //       isTime = false;
  //       isTransport = false;
  //       isSub = false;
  //       isMeal = false;
  //       showMealSelector(false);
  //       showSubPackageSelector(false);
  //       showTimeSlotSelector(false);
  //       showDateSelector(false);
  //       showTransportSelector(false);
  //       return true;
  //     case "date":
  //       isDate = true;
  //       isTime = false;
  //       isTransport = false;
  //       isSub = false;
  //       isMeal = false;
  //       showMealSelector(false);
  //       showSubPackageSelector(false);
  //       showTimeSlotSelector(false);
  //       showTransportSelector(false);
  //       showDateSelector(true);
  //       return true;
  //     case "time":
  //       if (realDate == "" && enable_date_picker) {
  //         alert("Please Select a Date");
  //         return false;
  //       }
  //       if (total_adult_tickets < addPerson || total_child_tickets < addChild) {
  //         alert("Selected Date is Fully Booked");
  //         return false;
  //       }
  //       isDate = true;
  //       isTime = true;
  //       isTransport = false;
  //       isSub = false;
  //       isMeal = false;
  //       showMealSelector(false);
  //       showSubPackageSelector(false);
  //       showTimeSlotSelector(true);
  //       showTransportSelector(false);
  //       // showDateSelector(true);
  //       return true;
  //     case "sub_packages":
  //       if (realDate == "" && enable_date_picker) {
  //         alert("Please Select a Date");
  //         return false;
  //       }
  //       if (total_adult_tickets < addPerson || total_child_tickets < addChild) {
  //         alert("Selected Time is Fully Booked");
  //         return false;
  //       }
  //       if (time_slot == "" && enable_time_picker) {
  //         return false;
  //       }
  //       isDate = true;
  //       isTime = true;
  //       isSub = true;
  //       isTransport = false;
  //       isMeal = false;
  //       showTransportSelector(false);
  //       showMealSelector(false);
  //       showSubPackageSelector(true);
  //       showTimeSlotSelector(true);
  //       showDateSelector(true);
  //       return true;
  //     case "transports":
  //       if (realDate == "" && enable_date_picker) {
  //         alert("Please Select a Date");
  //         return false;
  //       }
  //       if (total_adult_tickets < addPerson || total_child_tickets < addChild) {
  //         alert("Selected Date is Fully Booked");
  //         return false;
  //       }
  //       if (time_slot == "" && enable_time_picker) {
  //         return false;
  //       }
  //       isDate = true;
  //       isTime = true;
  //       isSub = true;
  //       isTransport = true;
  //       isMeal = false;
  //       showMealSelector(false);
  //       showSubPackageSelector(true);
  //       showTimeSlotSelector(true);
  //       showTransportSelector(true);
  //       showDateSelector(true);
  //       return true;
  //     case "meals":
  //       if (realDate == "" && enable_date_picker) {
  //         alert("Please Select a Date");
  //         return false;
  //       }
  //       if (total_adult_tickets < addPerson || total_child_tickets < addChild) {
  //         alert("Selected Date is Fully Booked");
  //         return false;
  //       }
  //       if (time_slot == "" && enable_time_picker) {
  //         return false;
  //       }
  //       isDate = true;
  //       isTime = true;
  //       isSub = true;
  //       isTransport = true;
  //       isMeal = true;
  //       showMealSelector(true);
  //       showSubPackageSelector(true);
  //       showTimeSlotSelector(true);
  //       showTransportSelector(true);
  //       showDateSelector(true);
  //       return true;
  //   }
  // }

  const Continue = () => (
    <button
      className="flex justify-center align-center text-white text-[16px] px-[20px] py-[6px] w-fit gradient-border relative "
      onClick={() => {
        if (step === 5) {
          // if (
          //   (adult_overall_limit == "" ||
          //     parseInt(adult_overall_limit) >=
          //       parseInt(adult_booked_times) + addPerson) &&
          //   (child_overall_limit == "" ||
          //     parseInt(child_overall_limit) >=
          //       parseInt(child_booked_times) + addChild)
          // ) {
          bookYourPackage();
          // }
        } else {
          nextStep();
        }
      }}
      disabled={
        isLoading ||
        packageDetails?.disabled === "1" ||
        packageDetails?.enable === "0"
      }
    >
      {isLoading && (
        <div
          role="status"
          className="flex justify-center w-full h-full items-center"
        >
          <svg
            aria-hidden="true"
            class="mr-2 mt-[2px] w-5 h-5 text-[#616161] animate-spin fill-white"
            viewBox="0 0 100 101"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
              fill="currentColor"
            />
            <path
              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
              fill="currentFill"
            />
          </svg>
          <span class="sr-only">Loading...</span>
        </div>
      )}
      {step === 5
        ? // ? (adult_overall_limit == "" ||
          //     parseInt(adult_overall_limit) >=
          //       parseInt(adult_booked_times) + addPerson) &&
          //   (child_overall_limit == "" ||
          //     parseInt(child_overall_limit) >=
          //       parseInt(child_booked_times) + addChild)
          language.pay[locale]
        : // : language.no_avaiable_qty[locale]
          language.continue[locale]}
    </button>
  );

  const Back = () => (
    <button
      className="flex justify-center align-center text-white text-[16px] px-[20px] py-[6px] rounded-[24px] w-fit border-[1.5px] border-[#FFFFFF33]"
      onClick={prevStep}
    >
      {language.back[locale]}
    </button>
  );

  return (
    <>
      {isOpen && (
        <div
          className={`flex justify-center ${
            step === -1 ? "items-start" : "items-center"
          } overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none backdrop-blur-sm`}
        >
          <div className="relative w-full h-full md:min-w-[400px] md:min-h-[400px] md:w-auto md:h-auto md:my-6 mx-auto max-w-[1110px]">
            <div
              className={`bg-modalBg md:max-h-[90vh] h-full min-h-screen md:min-h-[400px] px-[24px] md:px-[60px] md:py-[40px] border-0 md:rounded-lg shadow-lg relative flex flex-col w-full outline-none focus:outline-none overflow-scroll`}
            >
              {step !== -1 && (
                <div className="fixed top-0 left-[24px] right-[24px] z-10 bg-modalBg md:max-h-[90vh] pt-[20px] pb-[10px] md:static md:pt-0 md:pb-0 flex items-center justify-between mb-[60px] md:mb-[36px]">
                  <h3 className="text-[20px] md:text-[32px] text-white">
                    {step > 4
                      ? step > 3
                        ? ""
                        : language.summary[locale]
                      : step < 0
                      ? ""
                      : language.event_signup[locale]}
                  </h3>
                  {step < 5 && (
                    <button
                      className="relative opacity-7 h-[36px] w-[36px] block bg-[#FFFFFF4D] py-0 rounded-full"
                      onClick={resetCloseModal}
                    >
                      <img
                        src={close}
                        alt="close"
                        className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
                      />
                    </button>
                  )}
                </div>
              )}

              {step === -1 && (
                <EventDetails
                  nextStep={nextStep}
                  closeModal={resetCloseModal}
                  packageDetails={packageDetails}
                  packageLoading={packageLoading}
                />
              )}
              {step > -1 && step < 5 && <FormSteps step={step} data={data} />}

              {step === 0 && (
                <>
                  <Users
                    // addChild={addChild}
                    // setAddChild={setAddChild}
                    // addPerson={addPerson}
                    // setAddPerson={setAddPerson}
                    // adult_price={packageDetails?.price}
                    // child_price={packageDetails?.child_price}
                    // setNumPerson={setNumPerson}
                    setCustomChildValu={setCustomChildValue}
                    // packageDetails_id={packageDetails?.id}
                    summer_camp={packageDetails?.summer_camp}
                    packageTickets={packageTickets}
                    setPackageTickets={setPackageTickets}
                    groupTickets={groupTickets}
                    setGroupTickets={setGroupTickets}
                  />
                  <div className="flex justify-center items-center w-full mt-[36px] gap-[8px] fixed bottom-0 left-0 z-10 bg-modalBg md:max-h-[90vh] pt-[10px] pb-[20px] md:static md:pt-0 md:pb-0">
                    <Back />
                    <Continue />
                  </div>
                </>
              )}
              {step === 1 && collectDetails && (
                <>
                  <Details
                    details={details}
                    setDetails={setDetails}
                    attendeesList={attendeesList}
                    setAttendessList={setAttendessList}
                    addAttendees={addAttendees}
                    openModal={openModal}
                    setOpenModal={setOpenModal}
                    attendeeAlert={attendeeAlert}
                    setAttendeeAlert={setAttendeeAlert}
                    isLoading={isLoading}
                  />
                  <div className="flex justify-center items-center w-full mt-[36px] gap-[8px] fixed bottom-0 left-0 z-10 bg-modalBg md:max-h-[90vh] pt-[10px] pb-[20px] md:static md:pt-0 md:pb-0">
                    <Back />
                    <Continue />
                  </div>
                </>
              )}
              {step === 2 && enable_date_picker && (
                <>
                  <Calendar
                    selectedDate={selectedDate}
                    handleDateChange={handleDateChange}
                    minDate={minDate}
                    maxDate={maxDate}
                    disabled_dates={disabled_dates}
                    disabled_weeks={disabled_weeks}
                  />
                  <div className="flex justify-center items-center w-full mt-[36px] gap-[8px] fixed bottom-0 left-0 z-10 bg-modalBg md:max-h-[90vh] pt-[10px] pb-[20px] md:static md:pt-0 md:pb-0">
                    <Back />
                    <Continue />
                  </div>
                </>
              )}
              {step === 3 && summerCamp && (
                <>
                  <SummerDate
                    summerDates={summerDates}
                    summerGroup={summerGroup}
                    selectedSummerDate={selectedSummerDate}
                    setSelectedSummerDate={setSelectedSummerDate}
                    selectedSummerGroup={selectedSummerGroup}
                    setSelectedSummerGroup={setSelectedSummerGroup}
                    count={count}
                    showDates={showDates}
                    setShowDates={setShowDates}
                  />
                  <div className="flex justify-center items-center w-full mt-[36px] gap-[8px] fixed bottom-0 left-0 z-10 bg-modalBg md:max-h-[90vh] pt-[10px] pb-[20px] md:static md:pt-0 md:pb-0">
                    <Back />
                    <Continue />
                  </div>
                </>
              )}
              {step === 4 && enable_time_picker && (
                <>
                  <TimeSlot
                    selectedTimeSlot={selectedTimeSlot}
                    timeSlots={timeSlots}
                    changeTimeSlot={changeTimeSlot}
                  />
                  <div className="flex justify-center items-center w-full mt-[36px] gap-[8px] fixed bottom-0 left-0 z-10 bg-modalBg md:max-h-[90vh] pt-[10px] pb-[20px] md:static md:pt-0 md:pb-0">
                    <Back />
                    <Continue />
                  </div>
                </>
              )}
              {step === 5 && (
                <>
                  <Summary
                    // addChild={addChild}
                    // addPerson={addPerson}
                    packageTickets={packageTickets}
                    groupTickets={groupTickets}
                    // adult_price={packageDetails?.price}
                    // child_price={packageDetails?.child_price}
                    selectedDate={selectedDate}
                    selectedTimeSlot={selectedTimeSlot}
                    timeSlots={timeSlots}
                    packageName={packageDetails?.package_name}
                    packageSlug={packageSlug}
                    shipping_req={packageDetails?.shipping_required}
                    total={total}
                    enable_date_picker={enable_date_picker}
                    enable_time_picker={enable_time_picker}
                    shipping={shipping}
                    setShipping={setShipping}
                    selectedSummerDate={selectedSummerDate}
                    selectedSummerGroup={selectedSummerGroup}
                    summerCamp={summerCamp}
                    count={count}
                    isLoading={isLoading}
                    amount={amount}
                    setAmount={setAmount}
                    coupon={coupon}
                    setCoupon={setCoupon}
                    validateCoupon={validateCoupon}
                    resetCoupon={resetCoupon}
                    setResetCoupon={setResetCoupon}
                  />
                  <div className="flex justify-center items-center w-full mt-[36px] gap-[8px] z-10 ">
                    <Back />
                    <Continue />
                  </div>
                </>
              )}
              {/* {step === 3 && (
                <SubPackages
                  addSubAdult={addSubAdult}
                  addSubChild={addSubChild}
                  subPackages={subPackages}
                />
              )}
              {step === 4 && (
                <FluentFood meals={meals} addMealPerson={addMealPerson} />
              )}
              {step === 5 && (
                <Transport
                  transports={transports}
                  transport_id={transport_id}
                  checkTransport={checkTransport}
                  isOneWay={isOneWay}
                  setTransportID={setTransportID}
                />
              )} */}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Modal;

import React, { useEffect, useState } from "react";
import pods from "../assets/svg/pods.svg";
import books from "../assets/svg/books.svg";
import lab from "../assets/svg/lab.svg";
import ReserveCard from "./ReserveCard";
import ReservePods from "../components/ReservePods/ReservePods";
import ContactModal from "./ContactModal";
import { Link } from "react-router-dom";
import language from "../Json/locale.json";
import useStorage from "../hooks/useStorage";

const Reserve = React.forwardRef(({ data }, ref) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [openContactForm, setOpenContactForm] = useState(false);
  const [selectedType, setSelectedType] = useState("");
  const locale = useStorage();
  localStorage.setItem(
    "last_visited_url",
    window.location.pathname + window.location.search
  );

  useEffect(() => {
    if (isModalOpen) {
      document
        .getElementsByTagName("body")[0]
        ?.classList.add("overflow-hidden");
    } else {
      document
        .getElementsByTagName("body")[0]
        ?.classList.remove("overflow-hidden");
    }
  }, [isModalOpen]);

  return (
    <div className="w-full flex justify-center mt-[84px]">
      <div className="w-11/12 max-w-[1800px]" ref={ref}>
        <div className="flex justify-between items-center mb-[10px]">
          <h3 className="text-white text-[20px] md:text-[32px]">
            {language.reserve_title[locale]}
          </h3>
          {data.length > 1 && (
            <Link to="/reserve" className="text-[#8D8D8D] text-[16px]">
              {language.discover_more[locale]}
            </Link>
          )}
        </div>
        <p className="text-white text-[17px] mb-[20px]">
          {language.reserve_desc[locale]}
        </p>
        <div className="flex gap-[12px] overflow-x-scroll">
          {data.map((card) => (
            <ReserveCard
              card={card}
              setIsModalOpen={setIsModalOpen}
              setOpenContactForm={setOpenContactForm}
              setSelectedType={setSelectedType}
            />
          ))}
        </div>
      </div>
      <ReservePods
        isOpen={isModalOpen}
        closeModal={() => {
          window.history.replaceState(null, null, window.location.pathname);
          setIsModalOpen(false);
        }}
      />
      <ContactModal
        openContactForm={openContactForm}
        setOpenContactForm={setOpenContactForm}
        selectedType={selectedType}
      />
    </div>
  );
});

export default Reserve;

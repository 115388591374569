import React from "react";
import { useState } from "react";
import language from "../../Json/locale.json";
import useStorage from "../../hooks/useStorage";

const GuestCount = ({ count, setCount }) => {
  const locale = useStorage();
  return (
    <div className="h-full flex flex-col justify-between mb-[45px] md:mb-0">
      <div></div>
      <div>
        <h5 className="mb-[20px] text-white text-center text-[17px] font-SansSerif">
          {language.no_of_people[locale]}
        </h5>
        <div className="flex justify-center items-center w-full mb-[36px]">
          <button
            className="rounded-full border-[#C8C8C8] border-[1px] text-white w-[60px] h-[60px] text-[28px]"
            onClick={() => {
              setCount((prevState) => (prevState - 1 <= 0 ? 1 : prevState - 1));
            }}
          >
            -
          </button>
          <span className="min-w-[50px] mx-[15px] text-white text-[25px] text-center">
            {count}
          </span>
          <button
            className="rounded-full border-[#C8C8C8] border-[1px] text-white w-[60px] h-[60px] text-[28px]"
            onClick={() => {
              setCount((prevState) => prevState + 1);
            }}
          >
            +
          </button>
        </div>
      </div>
      <div></div>
    </div>
  );
};

export default GuestCount;

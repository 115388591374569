import React, { useEffect, useRef } from "react";
import Banner from "../components/Banner";
import Events from "../components/Events";
import gallery1 from "../assets/images/ramadan/484A7236.jpg";
import gallery2 from "../assets/images/ramadan/484A7240.jpg";
import gallery3 from "../assets/images/ramadan/484A7242.jpg";
import gallery4 from "../assets/images/ramadan/484A7243.jpg";
import gallery5 from "../assets/images/ramadan/484A7248.jpg";
import gallery6 from "../assets/images/ramadan/484A7250.jpg";
import gallery7 from "../assets/images/ramadan/484A7255.jpg";
import gallery8 from "../assets/images/ramadan/484A7256.jpg";
import gallery9 from "../assets/images/ramadan/484A7258.jpg";
import gallery10 from "../assets/images/ramadan/484A7260.jpg";
import ramadaniyatBanner from "../assets/images/ramadaniyat-banner.png";
import language from "../Json/locale.json";
import useStorage from "../hooks/useStorage";
import useFacebookPixel from "../hooks/useFacebookPixel";

const RamadanOffer = () => {
  const ref = useRef([]);
  const galleryList = [
    gallery1,
    gallery2,
    gallery3,
    gallery4,
    gallery5,
    gallery6,
    gallery7,
    gallery8,
    gallery9,
    gallery10,
  ];
  const locale = useStorage();
  const { trackPageView } = useFacebookPixel();

  useEffect(() => {
    trackPageView();
  }, [trackPageView]);

  return (
    <>
      <Banner url={ramadaniyatBanner} title desc="" />

      <div className="w-full flex justify-center">
        <div className="sm:w-8/12 md:w-6/12 w-11/12 max-w-[2000px]">
          <h2 class="text-white text-[32px] my-[4rem]  text-center">
            {language.ramadan_title[locale]}
          </h2>
          <p className="text-white text-[18px] my-[4rem] text-center">
            {language.ramadan_desc1[locale]}
          </p>
          <p className="text-white text-[18px] my-[4rem] text-center">
            {language.ramadan_desc2[locale]}
            <br />
            {language.ramadan_desc3[locale]}
          </p>
        </div>
      </div>
      {/* <div className="flex items-center justify-center">
        <Link
          to="/membership"
          className="text-white text-[16px] px-[20px] py-[6px] gradient-border relative mt-[1rem]"
        >
          Get it Now!
        </Link>
      </div> */}
      <Events
        packages={galleryList}
        ref={(el) => (ref.current[0] = el)}
        ramadan
      />
    </>
  );
};

export default RamadanOffer;

import React, { useEffect, useState } from "react";

export default function useStorage() {
  const [locale, setLocale] = useState(localStorage.getItem("lang") || "en");

  useEffect(() => {
    const onLocaleChange = () => {
      setLocale(localStorage.getItem("lang") || locale);
    };

    window.addEventListener("storage", onLocaleChange);
    return () => {
      window.removeEventListener("storage", onLocaleChange);
    };
  }, []);

  return locale;
}

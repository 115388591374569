import React from "react";
import diamond from "../../assets/svg/diamond.svg";
import { useAuth0 } from "@auth0/auth0-react";
import language from "../../Json/locale.json";
import useStorage from "../../hooks/useStorage";
import moment from "moment";

const PriceCard = ({
  idx,
  title,
  discountedPrice,
  price,
  access,
  discount,
  memberShipData,
  memberShipValue,
  level,
  selectedMemberShip,
  setIsModalOpen,
  offerTag,
  discountPercent,
}) => {
  const { loginWithRedirect, isAuthenticated } = useAuth0();
  const locale = useStorage();

  var start = moment(memberShipData?.membership_expiry_date, "YYYY-MM-DD");
  var end = moment(moment(new Date()).format("YYYY-MM-DD"), "YYYY-MM-DD");
  var daysDiff = moment.duration(start.diff(end)).asDays();

  return (
    <div className="w-full bg-primaryBg p-[28px] flex flex-col justify-between rounded-[8px]">
      <div>
        {offerTag && (
          <div>
            <div className="ribbon-wrapper mb-[1rem] float-right">
              <div className="glow">&nbsp;</div>
              <div className="ribbon-front">{offerTag}</div>
              <div className="ribbon-edge-topleft"></div>
              <div className="ribbon-edge-topright"></div>
              <div className="ribbon-edge-bottomleft"></div>
              <div className="ribbon-edge-bottomright"></div>
            </div>
          </div>
        )}
        <img src={diamond} alt="" className="h-[25px] md:h-[40px]" />
        <div className="mt-[30px]">
          <h4 className="text-[20px] md:text-[32px] text-white">{title}</h4>
          {!offerTag && <p className="text-[16px] text-white">{price}</p>}
          {offerTag && (
            <>
              <s className="text-[16px] text-white">{discountedPrice}</s>
              <p className="text-[20px] md:text-[28px] text-white font-bold text-[#e2572b]">
                {offerTag} - {price}
              </p>
            </>
          )}
          <div className="mt-[48px]">
            <p className="text-[16px] text-white">{language.access[locale]}</p>
            <p className="text-[16px] text-white opacity-50">
              {access.map((acc) => (
                <>
                  <span>{acc}</span> <br />
                </>
              ))}{" "}
            </p>
            <br />

            <p className="text-[16px] text-white">
              {language.discounts[locale]}
            </p>
            <p className="text-[16px] text-white opacity-50">
              {discount.map((disc) => (
                <>
                  <span>{disc}</span> <br />
                </>
              ))}{" "}
            </p>
          </div>
        </div>
      </div>
      {(daysDiff > 90 || !selectedMemberShip) && (
        <button
          className="flex justify-center align-center text-white text-[16px] px-[20px] py-[6px] gradient-border relative mt-[36px] w-fit"
          disabled={selectedMemberShip}
          onClick={() =>
            isAuthenticated ? setIsModalOpen() : loginWithRedirect()
          }
        >
          {!isAuthenticated
            ? language.register[locale]
            : selectedMemberShip
            ? language.current_plan[locale]
            : memberShipValue
            ? Number(memberShipValue.level) > Number(level)
              ? language.downgrade[locale]
              : language.upgrade[locale]
            : language.join_membership[locale]}
        </button>
      )}
      {daysDiff < 90 && selectedMemberShip && (
        <button
          className="flex justify-center align-center text-white text-[16px] px-[20px] py-[6px] gradient-border relative mt-[20px] w-fit"
          onClick={() =>
            isAuthenticated ? setIsModalOpen() : loginWithRedirect()
          }
        >
          {!isAuthenticated
            ? language.register[locale]
            : language.renew_membership[locale]}
        </button>
      )}
    </div>
  );
};

export default PriceCard;

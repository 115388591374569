import React from "react";

export default function Pagination({ handlePageChange }) {
  return (
    <div className="flex items-center justify-center gap-[4rem] mt-[2rem]">
      <button
        type="button"
        class="w-[44px] h-[44px] bg-white/30 rounded-full inline-flex items-center justify-center text-gray-400"
        onClick={() => handlePageChange("back")}
      >
        <svg
          class="w-5 text-white"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z"
            clip-rule="evenodd"
          ></path>
        </svg>
      </button>
      <button
        type="button"
        class="w-[44px] h-[44px] bg-white/30 rounded-full inline-flex items-center justify-center text-gray-400"
        onClick={() => handlePageChange("next")}
      >
        <svg
          class="w-5 text-white"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
            clip-rule="evenodd"
          ></path>
        </svg>
      </button>
    </div>
  );
}

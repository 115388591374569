import close from "../assets/svg/close.svg";
import React, { Fragment, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";

export default function GalleryPopup({ gallery, isOpen, setIsGalleryOpen }) {
  const cancelButtonRef = useRef(null);
  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        style={{ zIndex: 9999 }}
        className="relative"
        initialFocus={cancelButtonRef}
        onClose={setIsGalleryOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>
        <div className="fixed z-10 inset-0">
          <div className="flex items-end justify-center pt-4 px-4 pb-40 text-center sm:block sm:p-0">
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative inline-block bg-modalBg rounded-[10px] text-left overflow-hidden shadow-xl transform transition-all p-6 mt-[1rem]">
                <div className="fixed top-0 left-[24px] right-[24px] z-10 bg-modalBg md:max-h-[90vh] md:static flex items-center justify-end">
                  <button
                    className="relative opacity-7 h-[36px] w-[36px] block bg-[#FFFFFF4D] py-0 rounded-full mt-[10px] md:mt-0 mb-[10px] md:mb-[20px]"
                    onClick={() => setIsGalleryOpen(false)}
                  >
                    <img
                      src={close}
                      alt="close"
                      className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
                    />
                  </button>
                </div>
                <img
                  className="rounded-[8px] w-full h-[500px] object-cover"
                  src={gallery}
                />
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

import port1 from "../assets/images/portuguese/1J0A3217.webp";
import port2 from "../assets/images/portuguese/1J0A3292.webp";
import port3 from "../assets/images/portuguese/1J0A3300.webp";
import port4 from "../assets/images/portuguese/1J0A3342.webp";
import port5 from "../assets/images/portuguese/image.webp";
import language from "../Json/locale.json";
import useStorage from "../hooks/useStorage";

export default function TakwinExhibition() {
  const locale = useStorage();
  const images = [port1, port2, port3, port4, port5];

  return (
    <>
      <div className="w-full flex justify-center mt-[10rem]">
        <div className="w-11/12 max-w-[1800px] relative mt-[30px]">
          <h2 className="text-white text-[32px] text-center">
            {language.portuguese[locale]}
          </h2>
          <h2 className="text-white text-[21px] text-center my-4">
            {language.portuguese_date[locale]}
          </h2>
          {language.portuguese_desc[locale].map((port) => (
            <h6 className="text-white text-[16px] mt-[8px] mb-[24px] text mt-4">
              {port}
            </h6>
          ))}
        </div>
      </div>
      <div className="flex-col flex justify-center align-center w-full mt-8">
        <button
          className="m-auto w-fit text-white text-[16px] px-[20px] py-[6px] relative mt-[1rem] underline-offset-2	 underline"
          onClick={() => window.open("https://bo.discovershurooq.ae/files/how%20file657b187131f7b.pdf")}
        >
          {language.portuguese_visit[locale]}
        </button>

           <button
          className="m-auto w-fit text-white text-[16px] px-[20px] py-[6px] relative mt-[1rem] underline-offset-2	 underline"
          onClick={() => window.open("https://chsc.uc.pt/en/eventos-chsc/sharjah-2/")}
        >
          {language.portuguese_visit_two[locale]}
        </button>

          
        <div className="flex flex-wrap justify-center items-center mt-16 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-10">
          {images.map((image, index) => (
            <img
              key={index}
              src={image}
              className="max-w-[350px] h-auto max-h-[200px] object-contain"
              alt={`port ${index + 1}`}
            />
          ))}
        </div>
      </div>
    </>
  );
}

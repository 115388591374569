import React from "react";
import { useState } from "react";
import PackageDetails from "../components/PackageDetails/PackageDetails";

const Action1 = () => {
  const [isModalOpen, setIsModalOpen] = useState(true);
  return (
    <>
      <button
        onClick={() => {
          setIsModalOpen(true);
        }}
        type="button"
        className="text-white mt-[150px]"
      >
        Action2
      </button>
      <PackageDetails
        isOpen={isModalOpen}
        closeModal={() => {
          setIsModalOpen(false);
        }}
      />
    </>
  );
};

export default Action1;

import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { storeData } from "./redux/storeData";
import { Provider } from "react-redux";
import { createStore } from "redux";
import { Auth0Provider, useAuth0 } from "@auth0/auth0-react";

const root = ReactDOM.createRoot(document.getElementById("root"));

const store = createStore(
  storeData,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

Bugsnag.start({
  apiKey: "bf26d26ab201d9dbce7394af1a400813",
  plugins: [new BugsnagPluginReact()],
});

const ErrorBoundary = Bugsnag.getPlugin("react").createErrorBoundary(React);

const AppWithBugsnag = () => {
  const { isAuthenticated, user, isLoading } = useAuth0();

  useEffect(() => {
    if (!isLoading && isAuthenticated && user) {
      // If the user is authenticated and available, update Bugsnag user info as per the user details
      Bugsnag.setUser(user.sub, user.name, user.email);
    }
  }, [isLoading, isAuthenticated, user]);

  return (
    <ErrorBoundary>
      <ToastContainer />
      <App />
    </ErrorBoundary>
  );
};

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <Auth0Provider
        domain={process.env.REACT_APP_DOMAIN_NAME}
        clientId={process.env.REACT_APP_CLIENT_ID}
        redirectUri={`${window.location.origin}/loginRedirect`}
        audience={`https://${process.env.REACT_APP_AUTH0_AUDIENCE}/api/v2/`}
        scope="openid profile email read:current_user"
        cacheLocation="localstorage"
        useRefreshTokens
      >
        <AppWithBugsnag />
      </Auth0Provider>
    </Provider>
  </React.StrictMode>
);

reportWebVitals();

import React, { useState, Fragment, useRef } from "react";
import { Datepicker, localeAr, localeEn } from "@mobiscroll/react";
import close from "../../assets/svg/close.svg";
import language from "../../Json/locale.json";
import useStorage from "../../hooks/useStorage";
import { Dialog, Transition } from "@headlessui/react";
import { useEffect } from "react";

export default function Details({
  details,
  setDetails,
  attendeesList,
  setAttendessList,
  addAttendees,
  openModal,
  setOpenModal,
  attendeeAlert,
  setAttendeeAlert,
  isLoading,
}) {
  const locale = useStorage();
  const cancelButtonRef = useRef(null);

  useEffect(() => {
    if (attendeeAlert) {
      setTimeout(() => {
        setAttendeeAlert("");
      }, 3000);
    }
  }, [attendeeAlert]);

  return (
    <div className="flex flex-col gap-[1rem]">
      {attendeeAlert && (
        <p className="text-[#FF0000] text-center bg-red-200 rounded-[5px] p-[5px]">
          • {attendeeAlert}
        </p>
      )}
      {attendeesList.map((attendee) => (
        <div className="flex items-center">
          <input
            type="checkbox"
            id={`attendee_check_${attendee.id}`}
            name={`attendee_check_${attendee.id}`}
            checked={attendee.checked}
            onChange={() => {
              setAttendessList((prev) => {
                const updatedAttendees = prev.map((att) => {
                  if (att.id === attendee.id) {
                    return { ...att, checked: !att.checked };
                  }
                  return att;
                });
                return [...updatedAttendees];
              });
            }}
            className={`w-4 h-4 text-blue-600 rounded border-gray-300 border-[1px] bg-[#151515] ml-[20px] md:ml-0 ${
              attendee.checked ? "" : "opacity-0"
            }`}
          />
          <div
            className={`w-4 h-4 text-blue-600 rounded border-gray-300 border-[1px] bg-[#151515] ${
              locale === "en" ? "ml-[-16px]" : "mr-[-16px]"
            } md-ml-[-8px] ${attendee.checked ? "hidden" : ""}`}
          />
          <label
            htmlFor={`attendee_check_${attendee.id}`}
            className="text-white ml-[1rem] ms-[10px] text-[16px]"
          >
            {attendee.name}
          </label>
        </div>
      ))}
      <div className="flex justify-center align-center">
        <button
          className="flex justify-center align-center text-white text-[16px] px-[20px] py-[6px] rounded-[24px] w-fit border-[1.5px] border-[#FFFFFF33] gradient-border relative"
          onClick={() => setOpenModal(true)}
        >
          Add Attendees
        </button>
      </div>
      <Transition.Root show={openModal} as={Fragment}>
        <Dialog
          as="div"
          style={{ zIndex: 9999 }}
          className="relative"
          initialFocus={cancelButtonRef}
          onClose={() => {}}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          <div className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-end justify-center pt-4 px-4 pb-40 text-center sm:block sm:p-0">
              <span
                className="hidden sm:inline-block sm:align-middle sm:h-screen"
                aria-hidden="true"
              >
                &#8203;
              </span>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative inline-block bg-modalBg rounded-[30px] text-left overflow-hidden shadow-xl transform transition-all p-8 mt-[14rem] bg-[#000000eb]">
                  <div className="flex justify-between items-center mb-[2rem]">
                    <p className="text-[17px] md:text-[20px] text-white">
                      Attendee Details
                    </p>
                    <button
                      className="relative opacity-7 h-[36px] w-[36px] block bg-[#FFFFFF4D] py-0 rounded-full"
                      onClick={() => setOpenModal(false)}
                    >
                      <img
                        src={close}
                        alt="close"
                        className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
                      />
                    </button>
                  </div>
                  <div className="flex flex-col justify-center items-center gap-[2rem]">
                    <input
                      className="px-[24px] py-[16px] rounded-[50px] w-full text-white me-[12px] bg-[#11111180]"
                      type="text"
                      placeholder={language.name[locale]}
                      name="name"
                      required
                      value={details.name}
                      onChange={(e) =>
                        setDetails((prev) => {
                          prev.name = e.target.value;
                          return { ...prev };
                        })
                      }
                    />
                    <div className="flex flex-col mobiDatePicker">
                      <Datepicker
                        controls={["calendar"]}
                        themeVariant="dark"
                        locale={locale === "en" ? localeEn : localeAr}
                        touchUi={true}
                        theme="material"
                        inputComponent="input"
                        inputProps={{
                          placeholder: "Date of Birth",
                        }}
                        value={details.dob}
                        name="dob"
                        onChange={(date) =>
                          setDetails((prev) => {
                            prev.dob = date.value;
                            return { ...prev };
                          })
                        }
                      />
                    </div>
                    <div className="flex justify-center align-center">
                      <button
                        className="flex justify-center align-center text-white text-[16px] px-[20px] py-[6px] rounded-[24px] w-fit border-[1.5px] border-[#FFFFFF33] gradient-border relative"
                        onClick={addAttendees}
                        disabled={isLoading}
                      >
                        {isLoading && (
                          <div
                            role="status"
                            className="flex justify-center w-full h-full items-center"
                          >
                            <svg
                              aria-hidden="true"
                              class="mr-2 mt-[2px] w-5 h-5 text-[#616161] animate-spin fill-white"
                              viewBox="0 0 100 101"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                fill="currentColor"
                              />
                              <path
                                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                fill="currentFill"
                              />
                            </svg>
                            <span class="sr-only">Loading...</span>
                          </div>
                        )}{" "}
                        Add
                      </button>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
}

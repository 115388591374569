import { useEffect } from "react";
import { clarity } from "react-microsoft-clarity";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Footer from "./components/Footer";
import Header from "./components/Header";
import ScrollToTop from "./components/ScrollToTop";
import About from "./pages/About";
import Action from "./pages/Action";
import Action1 from "./pages/Action1";
import Contact from "./pages/Contact";
import Dine from "./pages/Dine";
import Happenings from "./pages/Happenings";
import TagPackages from "./pages/TagPackages";
import Home from "./pages/Home";
import LoginRedirect from "./pages/LoginRedirect";
import Membership from "./pages/Membership";
import News from "./pages/News";
import NewsDetail from "./pages/NewsDetail";
import Profile from "./pages/Profile";
import Reserve from "./pages/Reserve";
import BookClub from "./pages/BookClub";
import TC from "./pages/TC";
import Library from "./pages/Library";
import SignUp from "./pages/SignUp";
import Login from "./pages/Login";
import Logout from "./pages/Logout";
import ComponentWrapper from "./components/ComponentWrapper";
import RamadanOffer from "./pages/RamadanOffer";
import Duathlon from "./pages/Duathlon";
import SummerCamp from "./pages/SummerCamp";
import PurchaseStatus from "./pages/PurchaseStatus";
import TakwinExhibition from "./pages/TakwinExhibition";
import PortugueseExhibition from "./pages/PortugueseExhibition";

export default function App() {
  const routes = [
    { path: "/", page: Home },
    { path: "/happenings", page: Happenings },
    { path: "/happenings/tag/:tag_slug", page: TagPackages },
    { path: "/about", page: About },
    { path: "/dine", page: Dine },
    { path: "/reserve", page: Reserve },
    { path: "/membership", page: Membership },
    { path: "/membership-special", page: Membership },
    { path: "/library", page: Library },
    { path: "/bookclub", page: BookClub },
    { path: "/ramadaniyat", page: RamadanOffer },
    { path: "/duathlon", page: Duathlon },
    { path: "/takwin-exhibition", page: TakwinExhibition },
    { path: "/portuguese-exhibition", page: PortugueseExhibition },
    { path: "/LRsummercamp", page: SummerCamp },
    { path: "/pages/terms-conditions", page: TC },
    { path: "/contact", page: Contact },
    { path: "/purchase_status/:purchase_number", page: PurchaseStatus },
    { path: "/news", page: News },
    { path: "/newsdetail/:news_slug", page: NewsDetail },
    { path: "/action", page: Action },
    { path: "/action1", page: Action1 },
    { path: "/profile", page: Profile },
    { path: "/internallogin", page: Login },
    { path: "/internalsignup", page: SignUp },
    { path: "/loginRedirect", page: LoginRedirect },
    { path: "/logout", page: Logout },
  ];

  useEffect(() => {
    clarity.init("fnd3ymencl");
  }, []);

  return (
    <Router>
      <Header />
      <ScrollToTop>
        <div className="flex-1">
          <Routes>
            {routes.map((route, idx) => (
              <Route
                key={idx}
                path={route.path}
                element={
                  <ComponentWrapper>
                    <route.page />
                  </ComponentWrapper>
                }
              />
            ))}
          </Routes>
        </div>
      </ScrollToTop>
      <Footer />
    </Router>
  );
}

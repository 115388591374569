import React, { useState } from "react";
import news from "../assets/images/news.png";
import bookImg from "../assets/images/library-book-default.png";

function Card({ title, desc, img, onClick }) {
  return (
    <div
      className="mb-[48px] w-full flex flex-col md:flex-row md:gap-[125px] cursor-pointer"
      onClick={onClick}
    >
      <img
        src={`${process.env.REACT_APP_CMS}${img}`}
        alt=""
        className="w-full md:w-[50%] rounded-[8px]"
        onError={(e) => (e.target.src = bookImg)}
      />
      <div>
        <h2 className="text-[20px] md:text-[32px] text-white mt-[20px] md:mt-0 mb-[20px]">
          {title}
        </h2>
        <p className="text-[16px] text-white opacity-50">
          <div
            className="linetextellipsis"
            dangerouslySetInnerHTML={{
              __html: desc,
            }}
          ></div>
        </p>
      </div>
    </div>
  );
}

export default Card;

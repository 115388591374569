import React, { useEffect, useRef, useState } from "react";
import Banner from "../components/Banner";
import Events from "../components/Events";
import Reserve from "../components/Reserve";
import lab from "../assets/svg/al-jazri-lab.svg";
import animate from "../gsap/animate";
import { useSelector } from "react-redux";
import { fetchClient } from "../axios-config";
import { useAuth0 } from "@auth0/auth0-react";
import language from "../Json/locale.json";
import useStorage from "../hooks/useStorage";
import useFacebookPixel from "../hooks/useFacebookPixel";

const Happenings = () => {
  const { getAccessTokenSilently, isAuthenticated } = useAuth0();
  const ref = useRef([]);
  const [loading, setLoading] = useState(false);
  const [packages, setPackages] = useState([]);
  const [categories, setCategories] = useState([]);
  const { heading, description, image } = useSelector(
    (state) => state.data.happenings
  );
  const locale = useStorage();
  const { trackPageView } = useFacebookPixel();

  useEffect(() => {
    trackPageView();
  }, [trackPageView]);

  localStorage.setItem(
    "last_visited_url",
    window.location.pathname + window.location.search
  );

  useEffect(() => {
    animate(ref);
    const getPackages = async () => {
      setLoading(true);
      const formData = new FormData();
      formData.append("action", "packages");
      const data = await fetchClient(
        getAccessTokenSilently,
        isAuthenticated,
        "",
        formData,
        false
      );
      if (data) setPackages(data.data);
      setLoading(false);
    };
    const getCategories = async () => {
      setLoading(true);
      const formData = new FormData();
      formData.append("action", "listCategories");
      const data = await fetchClient(
        getAccessTokenSilently,
        isAuthenticated,
        "",
        formData,
        false
      );
      if (data) setCategories(data.data);
      setLoading(false);
    };
    getCategories();
    getPackages();
  }, []);

  return (
    <>
      <Banner
        url={process.env.REACT_APP_CMS + image.data.attributes.url}
        title={heading}
        desc={description}
      />
      <Events
        title={language.events[locale]}
        packages={packages}
        categories={categories}
        ref={(el) => (ref.current[0] = el)}
        loading={loading}
      />
      {/* <Reserve data={reserveData} ref={(el) => (ref.current[1] = el)} /> */}
    </>
  );
};

export default Happenings;

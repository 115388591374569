import takwinbanner from "../assets/images/takwinbanner.jpg";
import Banner from "../components/Banner";
import place1 from "../assets/images/takwin/place1.jpg";
import place2 from "../assets/images/takwin/place2.jpg";
import place3 from "../assets/images/takwin/place3.jpg";
import place4 from "../assets/images/takwin/place4.jpg";
import place5 from "../assets/images/takwin/place5.jpg";
import sponsLogo1 from "../assets/images/takwin/logo1.png";
import sponsLogo2 from "../assets/images/takwin/logo2.png";
import sponsLogo3 from "../assets/images/takwin/logo3.png";
import language from "../Json/locale.json";
import useStorage from "../hooks/useStorage";

export default function TakwinExhibition() {
  const locale = useStorage();
  const images = [place1, place2, place3, place4, place5];
  const sponsoredLogo = [sponsLogo2, sponsLogo3];
  const colabLogo = [sponsLogo1];

  return (
    <>
      <Banner url={takwinbanner} />
      <div className="w-full flex justify-center">
        <div className="w-11/12 max-w-[1800px] relative mt-[30px]">
          <h2 className="text-white text-[32px] text-center">
            {language.takwin[locale]}
          </h2>
          <h2 className="text-white text-[21px] text-center my-4">
            {language.takwin_date[locale]}
          </h2>
          {language.takwin_desc[locale].map((tak) => (
            <h6 className="text-white text-[16px] mt-[8px] mb-[24px] text">
              {tak}
            </h6>
          ))}
          <div className="flex text-white cursor-pointer pb-[8px] whitespace-nowrap text-[20px] md:text-[32px] gap-[36px] mt-16">
            {language.takwinHeader[locale]}
          </div>
          <div className="flex flex-wrap justify-center items-center mt-8 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
            {language.takwin_five_science[locale].map(
              ({ title, desc }, index) => (
                <div className="relative" key={index}>
                  <img
                    src={images[index]}
                    className="max-w-full h-auto max-h-[550px] md:max-h-[450px] object-contain"
                    alt={`Image ${index + 1}`}
                  />
                  <div className="absolute bottom-0 left-0 right-0 text-white text-left p-2">
                    <div className="font-bold">{title}</div>
                    {desc.split("|").map((d) => (
                      <div>{d}</div>
                    ))}
                  </div>
                </div>
              )
            )}
          </div>
          <div className="flex-col flex justify-center align-center w-full mt-16">
            <button
              className="m-auto w-full md:w-[50%] text-white text-[16px] px-[20px] py-[6px] gradient-border relative mt-[1rem]"
              onClick={() => window.open(`https://kfcris.com/${locale}`)}
            >
              {language.kfcric[locale]}
            </button>

            <button
              className="m-auto  w-full md:w-[50%]  text-white text-[16px] px-[20px] py-[6px] gradient-border relative mt-[1rem]"
              onClick={() => window.open(`/happenings`)}
            >
              {language.takwin_happenings[locale]}
            </button>
          </div>
          <div className="flex justify-center align-center text-white cursor-pointer pb-[8px] whitespace-nowrap text-[20px] md:text-[32px] gap-[36px] mt-16">
            {language.colab[locale]}
          </div>
          <div className="flex flex-wrap justify-center items-center mt-8 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-10">
            {colabLogo.map((image, index) => (
              <img
                key={index}
                src={image}
                className="max-w-[350px] h-auto max-h-[150px] object-contain"
                alt={`ColabImage ${index + 1}`}
              />
            ))}
          </div>
          <div className="flex justify-center align-center text-white cursor-pointer pb-[8px] whitespace-nowrap text-[20px] md:text-[32px] gap-[36px] mt-16">
            {language.sponsored_by[locale]}
          </div>
          <div className="flex flex-wrap justify-center items-center mt-8 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-10">
            {sponsoredLogo.map((image, index) => (
              <img
                key={index}
                src={image}
                className="max-w-[350px] h-auto max-h-[150px] object-contain"
                alt={`SponsImage ${index + 1}`}
              />
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

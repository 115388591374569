export default function TC({ tC }) {
  return (
    <div className="h-full flex flex-col justify-between font-SansSerif pl-[24px] md:mb-0">
      <ul className="text-white w-full list-disc md:pb-0 pb-[100px]">
        {tC.map((t, i) => (
          <li key={i}>{t.text}</li>
        ))}
      </ul>
    </div>
  );
}

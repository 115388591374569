import React from "react";
import bookImg from "../../assets/images/library-book-default.png";

const Book = ({ openModal, book, setBookId }) => {
  return (
    <div
      className="h-[440px] md:h-[600px] bg-primaryBg rounded-[8px] overflow-hidden cursor-pointer"
      onClick={() => {
        window.history.replaceState(
          null,
          null,
          `?go&type=library&uuid=${book.uuid}`
        );
        localStorage.setItem(
          "last_visited_url",
          window.location.pathname + window.location.search
        );
        setBookId(book.uuid);
        openModal();
      }}
    >
      <div className="h-2/3 bg-[#252525] flex justify-center items-center">
        <img
          alt=""
          src={
            book?.barcode || book?.isbnPlain
              ? `${
                  book.language === "eng"
                    ? process.env.REACT_APP_BOWKER
                    : process.env.REACT_APP_NONENG_BOOKCOVER
                }${book.barcode || book.isbnPlain}`
              : bookImg
          }
          className="w-[150px] md:w-[200px]"
          onError={(e) => (e.target.src = bookImg)}
        />
      </div>
      <div className="px-[20px] py-[24px] h-1/3 w-3/4">
        <h2 className="text-white text-[20px] md:text-[27px] twolinetextellipsis">
          {book.title}
        </h2>
        <p className="mt-[8px] text-white text-[12px] md:text-[16px]">
          {book.author?.split(",").map((name, i) => (
            <>
              <>{name}</>
              <>{book.author.split(",").length > i + 1 && ","}</>
            </>
          ))}
        </p>
      </div>
    </div>
  );
};

export default Book;

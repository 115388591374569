import React from "react";
import { Link } from "react-router-dom";
import close from "../assets/svg/close.svg";

const SignUp = () => {
  return (
    <div className="pt-[84px] w-full flex justify-center">
      <div className="w-11/12 max-w-[1800px] relative">
        <div
          className={`flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none backdrop-blur-sm`}
        >
          <div className="relative w-full h-full md:min-w-[400px] md:min-h-[400px] md:w-auto md:h-auto md:my-6 mx-auto max-w-[1110px]">
            <div
              className={`bg-modalBg md:max-h-[90vh] h-full min-h-screen md:min-h-[400px] px-[24px] py-[20px] md:px-[60px] md:py-[40px] border-0 md:rounded-lg shadow-lg relative flex flex-col w-full outline-none focus:outline-none overflow-scroll`}
            >
              <div className="fixed top-0 px-[24px] left-0 w-full z-10 bg-modalBg md:max-h-[90vh] pt-[20px] pb-[10px] md:static md:pt-0 md:pb-0 flex items-center justify-between mb-[60px] md:mb-[36px]">
                <h3 className="text-[20px] md:text-[32px] text-white">
                  Sign Up
                </h3>
                <button
                  className="relative opacity-7 h-[36px] w-[36px] block bg-[#FFFFFF4D] py-0 rounded-full self-end"
                  //   onClick={}
                >
                  <img
                    src={close}
                    alt="close"
                    className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
                  />
                </button>
              </div>

              <div className="w-full h-full flex flex-col justify-center items-center">
                <input
                  className="px-[24px] py-[16px] rounded-[50px] w-full md:w-[350px] mx-[64px] text-white me-[12px] bg-[#11111180]"
                  type="email"
                  placeholder="Email address"
                  name=""
                  id=""
                />

                <input
                  className="mt-[12px] px-[24px] py-[16px] rounded-[50px] w-full md:w-[350px] mx-[64px] text-white me-[12px] bg-[#11111180]"
                  type="password"
                  placeholder="Password"
                  name=""
                  id=""
                />

                <input
                  className="mt-[12px] px-[24px] py-[16px] rounded-[50px] w-full md:w-[350px] mx-[64px] text-white me-[12px] bg-[#11111180]"
                  type="password"
                  placeholder="Confirm Password"
                  name=""
                  id=""
                />

                <button className="flex justify-center align-center text-white text-[16px] px-[20px] py-[6px] w-fit gradient-border relative self-center mt-[48px]">
                  Sign Up
                </button>

                <p className="text-white text-center text-[12px] mt-[36px]">
                  {" "}
                  <span className="opacity-50">
                    Already have account?{" "}
                  </span>{" "}
                  <Link to="/internallogin"> Log In</Link>{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUp;

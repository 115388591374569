import React from "react";
import close from "../assets/svg/close.svg";
import ContactForm from "./ContactForm";
import language from "../Json/locale.json";
import useStorage from "../hooks/useStorage";

export default function ContactModal({
  openContactForm,
  setOpenContactForm,
  selectedType,
}) {
  const locale = useStorage();
  return (
    <>
      {openContactForm && (
        <div className="flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none backdrop-blur-sm">
          <div className="relative w-full h-full md:min-w-[400px] md:min-h-[400px] md:w-auto md:h-auto md:my-6 mx-auto max-w-3xl">
            <div className="bg-modalBg md:max-h-[90vh] h-full min-h-screen md:min-h-[400px] px-[24px] py-[20px] md:px-[40px] md:py-[40px] border-0 md:rounded-lg shadow-lg relative flex flex-col w-full outline-none focus:outline-none overflow-scroll">
              <div className="fixed top-0 left-[24px] right-[24px] z-10 bg-modalBg md:max-h-[90vh] pt-[20px] pb-[10px] md:static md:pt-0 md:pb-0 flex items-center justify-between mb-[60px] md:mb-[36px] rounded-t ">
                <h3 className="text-[20px] md:text-[32px] text-white">
                  {language.contact_title[locale]}
                </h3>
                <button
                  className="relative opacity-7 h-[36px] w-[36px] block bg-[#FFFFFF4D] py-0 rounded-full"
                  onClick={() => setOpenContactForm(false)}
                >
                  <img
                    src={close}
                    alt="close"
                    className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
                  />
                </button>
              </div>
              <ContactForm
                selectedType={selectedType}
                openContactForm={openContactForm}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
}

import React, { useEffect, useRef } from "react";
import Banner from "../components/Banner";
import { useSelector } from "react-redux";
import about2 from "../assets/images/about_us_2.png";
import about3 from "../assets/images/about_us_3.png";
import animate from "../gsap/animate";
import language from "../Json/locale.json";
import useStorage from "../hooks/useStorage";
import useFacebookPixel from "../hooks/useFacebookPixel";

const About = () => {
  const ref = useRef([]);
  const { heading, description, image } = useSelector(
    (state) => state.data.about
  );
  const locale = useStorage();
  const { trackPageView } = useFacebookPixel();

  useEffect(() => {
    trackPageView();
  }, [trackPageView]);

  localStorage.setItem(
    "last_visited_url",
    window.location.pathname + window.location.search
  );

  useEffect(() => {
    animate(ref);
  }, []);

  return (
    <>
      <Banner
        url={process.env.REACT_APP_CMS + image.data.attributes.url}
        title={heading}
        desc={description}
      />

      <div className="w-full flex justify-center items-center">
        <div className="w-11/12 max-w-[1800px] flex flex-col justify-center items-center">
          <div
            ref={(el) => (ref.current[0] = el)}
            className="mt-[84px] w-full md:w-1/2"
          >
            <p className=" text-white text-[16px] md:text-[22px] w-full leading-[33px]">
              {language.about_1_desc[locale]}
              <br />
              <br />
              {language.about_2_desc[locale]}
              {/* <span className="opacity-50">Read more</span> */}
            </p>
          </div>

          <div
            ref={(el) => (ref.current[1] = el)}
            className="rounded-[8px] mt-[84px] h-[435px] md:h-[700px] w-full"
          >
            <img
              src={about2}
              alt=""
              className="w-full rounded-[8px] h-[435px] md:h-[700px] object-cover"
            />
          </div>

          <div
            className="w-full md:w-1/2 mt-[84px]"
            ref={(el) => (ref.current[2] = el)}
          >
            <h3 className="text-[20px] md:text-[32px] text-white mb-[16px]">
              {language.our_mission[locale]}
            </h3>
            <p className="text-white text-[16px] md:text-[22px] ">
              {language.about_3_desc[locale]}
            </p>
            <h3 className="text-[20px] md:text-[32px] text-white mb-[16px] mt-[3rem]">
              {language.design_future[locale]}
            </h3>
            <p className="text-white text-[16px] md:text-[22px] ">
              {language.about_4_desc[locale]}
              <br />
              <br />
              {language.about_5_desc[locale]}
            </p>
          </div>
          <div
            className="w-full flex justify-between mt-[84px] h-fit"
            ref={(el) => (ref.current[3] = el)}
          >
            <img
              src={about3}
              alt=""
              className="rounded-[8px] w-full h-[435px] md:h-[700px]"
            />
          </div>
          <div
            className="w-full md:w-1/2 mt-[84px]"
            ref={(el) => (ref.current[4] = el)}
          >
            <h3 className="text-[20px] md:text-[32px] text-white mb-[16px]">
              {language.history_culture[locale]}
            </h3>
            <p className="text-white text-[16px] md:text-[22px]">
              {language.about_6_desc[locale]}
              <br />
              <br />
              {language.about_7_desc[locale]}
              <br />
              <br />
              {language.about_8_desc[locale]}
              <br />
              <br />
              {language.about_9_desc[locale]}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default About;

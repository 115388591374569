import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

function Banner() {
  const { heading, description, button_text, button_link, image } = useSelector(
    (state) => state.data.home
  );

  return (
    <div
      className={`w-full h-screen md:h-[900px] bg-cover bg-center flex justify-center items-center`}
      style={{
        backgroundImage: `linear-gradient(360deg, rgba(0, 0, 0, 0.0001) 0%, rgba(0, 0, 0, 0.3) 100%),url(${
          process.env.REACT_APP_CMS + image.data.attributes.url
        })`,
      }}
    >
      <div className="flex flex-col justify-center items-center mt-[-200px] md:mt-[-300px] h-full w-[85%] md:w-[600px] pageIntroBannerConetnt">
        <h2 className="text-white text-[32px] text-center">{heading}</h2>
        <h6 className="text-white text-[16px] mt-[8px] mb-[24px] text-center">
          {description}
        </h6>
        <Link
          to={button_link}
          className="flex justify-center align-center text-white text-[16px] px-[20px] py-[6px] gradient-border relative"
        >
          {button_text}
        </Link>
      </div>
    </div>
  );
}

export default Banner;

import React, { useEffect, useRef, useState } from "react";
import Explore from "../components/Home/Explore";
import { Link } from "react-router-dom";
import Banner from "../components/Home/Banner";
import Events from "../components/Events";
import Membership from "../components/Home/Membership";
import BannerImage from "../assets/images/ATAW_2023_Banner.png";
import Read from "../components/Home/Read";
import Reserve from "../components/Reserve";
import pods from "../assets/svg/Book-Pod.svg";
import books from "../assets/svg/reserve-book.svg";
import lab from "../assets/svg/al-jazri-lab.svg";
import animate from "../gsap/animate";
import { fetchClient } from "../axios-config";
import { useAuth0 } from "@auth0/auth0-react";
import { useLocation } from "react-router-dom";
import language from "../Json/locale.json";
import useStorage from "../hooks/useStorage";
import useFacebookPixel from "../hooks/useFacebookPixel";

const images = [pods, books, lab];

export default function Home() {
  const { getAccessTokenSilently, isAuthenticated } = useAuth0();
  const ref = useRef([]);
  const [loading, setLoading] = useState(false);
  const [packages, setPackages] = useState([]);
  const [data, setData] = useState([]);
  const location = useLocation();
  const locale = useStorage();
  const { trackPageView } = useFacebookPixel();

  useEffect(() => {
    trackPageView();
  }, [trackPageView]);

  localStorage.setItem("last_visited_url", location.pathname);

  useEffect(() => {
    animate(ref);
    setData(
      language.reserve_pods[locale].slice(0, 3).map((pods, idx) => {
        return {
          ...pods,
          image: images[idx],
        };
      })
    );
    if (!packages.length) {
      setLoading(true);
      const formData = new FormData();
      formData.append("action", "packages");
      const getPackages = async () => {
        const data = await fetchClient(
          getAccessTokenSilently,
          isAuthenticated,
          "",
          formData,
          false
        );
        if (data) setPackages(data.data);
        setLoading(false);
      };
      getPackages();
    }
  }, []);

  return (
    <>
      <Banner />
      {/* <div className="w-full flex justify-center mt-[1rem]">
        <h6 className="text-[20px] md:text-[32px] text-white w-11/12 max-w-[1800px] ">
          Summer Camp 2023
        </h6>
      </div>
      <Link
        to="/?go&type=package&uuid=ATATW2023-wk1"
        className={`w-full h-[60vh] md: bg-cover bg-center flex justify-center items-center mt-[1rem]`}
        style={{
          backgroundImage: `linear-gradient(360deg, rgba(0, 0, 0, 0.3) 0%,rgba(0, 0, 0, 0.0001)  100%),url(${BannerImage})`,
        }}
      /> */}
      <Events
        title={language.experience_title[locale]}
        packages={packages}
        ref={(el) => (ref.current[0] = el)}
        loading={loading}
        experience
      />
      <Read ref={(el) => (ref.current[1] = el)} />
      <Membership ref={(el) => (ref.current[2] = el)} showHeader={true} />
      <Reserve data={data} ref={(el) => (ref.current[3] = el)} />
      {/* <Explore /> */}
    </>
  );
}

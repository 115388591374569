import React from "react";

function Banner({ url, title, desc }) {
  return (
    <div
      className={`bg-hero-gradient w-full h-[700px] bg-cover bg-center flex justify-center items-center ${url}`}
    >
      <div className="flex flex-col justify-center items-center h-full w-[600px]">
        <h2 className="text-white text-[16px] opacity-50">{title}</h2>
        <h6 className="text-white text-[36px] mt-[8px] mb-[24px] text-center">
          {desc}
        </h6>
      </div>
    </div>
  );
}

export default Banner;

import React, { useEffect, useState } from "react";
import book from "../../assets/images/library-book-default.png";
import close from "../../assets/svg/close.svg";
import GreenTick from "../../assets/svg/green_tick.svg";
import RedX from "../../assets/svg/red_x.svg";
import { Rating } from "react-simple-star-rating";
import Loader from "../Modal/Loader";
import { fetchClient } from "../../axios-config";
import { useAuth0 } from "@auth0/auth0-react";
import { Link } from "react-router-dom";
import language from "../../Json/locale.json";
import useStorage from "../../hooks/useStorage";

const Modal = ({ isOpen, closeModal, bookId, setOpenSearchResults }) => {
  const { isAuthenticated, getAccessTokenSilently, loginWithRedirect } =
    useAuth0();
  const [bookValue, setBookValue] = useState();
  const [locationValue, setLocationValue] = useState([]);
  const [memberShipData, setMemberShipData] = useState();
  const [validateData, setValidateData] = useState();
  const [bookReserved, setBookReserved] = useState();
  const [reserveLoading, setReserveLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const locale = useStorage();

  useEffect(() => {
    if (isOpen) {
      document
        .getElementsByTagName("body")[0]
        ?.classList.add("overflow-hidden");
    } else {
      document
        .getElementsByTagName("body")[0]
        ?.classList.remove("overflow-hidden");
    }
  }, [isOpen]);

  const closePopup = () => {
    document
      .getElementsByTagName("body")[0]
      ?.classList.remove("overflow-hidden");
    setBookValue();
    setLocationValue([]);
    setValidateData();
    setBookReserved();
    setReserveLoading(false);
    setOpenSearchResults && setOpenSearchResults(false);
    closeModal();
  };

  useEffect(() => {
    if (isAuthenticated && isOpen) {
      const getMembershipDetails = async () => {
        const memberShipFormData = new FormData();
        memberShipFormData.append("action", "membershipDetails");
        const data = await fetchClient(
          getAccessTokenSilently,
          isAuthenticated,
          "",
          memberShipFormData,
          false
        );
        if (data) setMemberShipData(data.data);
      };
      getMembershipDetails();
    }
  }, [isAuthenticated, isOpen]);

  useEffect(() => {
    if (bookId) {
      const getBookData = async () => {
        setLoading(true);
        const data = await fetchClient(
          getAccessTokenSilently,
          isAuthenticated,
          `?action=LMSBook&book_id=${bookId}`,
          "",
          false
        );
        if (data) {
          setBookValue(data.data[0]);
          getLocationData();
        }
      };
      const getLocationData = async () => {
        const data = await fetchClient(
          getAccessTokenSilently,
          isAuthenticated,
          `?action=LMSBookCopies&book_id=${bookId}`,
          "",
          false
        );
        if (data) {
          setLocationValue(
            Array.from(
              new Set(
                data.data.map((value) => {
                  return {
                    collection: value.collection,
                    callNum: value.callNum,
                  };
                })
              )
            )
          );
          getValidationData();
        }
      };
      const getValidationData = async () => {
        const data = await fetchClient(
          getAccessTokenSilently,
          isAuthenticated,
          `?action=LMSBookAvailability&book_id=${bookId}&size=20`,
          "",
          false
        );
        if (data) {
          setValidateData(data.data[0]);
        }
        setLoading(false);
      };
      getBookData();
    }
  }, [bookId]);

  const handleReserve = async () => {
    setReserveLoading(true);
    const data = await fetchClient(
      getAccessTokenSilently,
      isAuthenticated,
      `?action=LMSReserve&book_id=${bookId}`,
      "",
      false
    );
    if (data) {
      setBookReserved(data.data[0]);
    }
    setReserveLoading(false);
  };

  return (
    <>
      {isOpen && (
        <div className="flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none backdrop-blur-sm">
          <div className="relative w-full h-full md:min-w-[950px] md:min-h-[400px] md:w-auto md:h-auto md:my-6 mx-auto max-w-6xl">
            <div className="bg-modalBg md:max-h-[90vh] h-full md:min-h-[400px] px-[24px] py-0 pb-[24px] md:px-[60px] md:py-[40px] border-0 md:rounded-lg shadow-lg relative flex flex-col md:flex-row w-full outline-none focus:outline-none gap-[48px]">
              <div className="fixed top-0 left-0 right-[24px] z-10 bg-modalBg md:max-h-[90vh] pt-[20px] pb-[10px] md:hidden w-auto flex justify-end">
                <button
                  className="block md:hidden relative opacity-7 h-[36px] min-w-[36px] w-[36px] bg-[#FFFFFF4D] py-0 rounded-full"
                  onClick={closePopup}
                >
                  <img
                    src={close}
                    alt="close"
                    className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
                  />
                </button>
              </div>
              <div className="min-w-full h-fit py-[60px] md:py-[107] md:min-w-[50%] bg-[#252525] flex justify-center items-center mt-[76px] md:mt-0 rounded-[8px]">
                {loading ? (
                  <div
                    role="status"
                    class="space-y-8 animate-pulse md:space-y-0 md:space-x-8 md:flex md:items-center w-[150px] md:w-[254px] h-[360px]"
                  >
                    <div class="flex justify-center items-center w-[150px] md:w-[254px] h-[360px] bg-gray-300 rounded dark:bg-gray-700"></div>
                    <span class="sr-only">Loading...</span>
                  </div>
                ) : (
                  <img
                    alt=""
                    src={
                      bookValue?.barcode || bookValue?.isbn
                        ? `${
                            bookValue.language === "eng"
                              ? process.env.REACT_APP_BOWKER
                              : process.env.REACT_APP_NONENG_BOOKCOVER
                          }${bookValue.barcode || bookValue.isbn}`
                        : book
                    }
                    className="w-[150px] md:w-[254px]"
                    onError={(e) => (e.target.src = book)}
                  />
                )}
              </div>
              <div className="w-full md:w-[50%]">
                {loading ? (
                  <Loader />
                ) : (
                  <>
                    <h2 className="text-white text-[20px] md:text-[32px]">
                      {bookValue?.title}
                    </h2>
                    <p className="text-[16px] text-white opacity-50 mt-[8px]">
                      {bookValue?.author?.split(",").map((name, i) => (
                        <>
                          <>{name}</>
                          <>
                            {bookValue?.author.split(",").length > i + 1 && ","}
                          </>
                        </>
                      ))}
                    </p>
                    {/* <div className="flex items-center gap-[12px]">
                      <Rating
                        readonly
                        size={16}
                        allowFraction
                        initialValue={parseFloat(bookValue?.avgRating).toFixed(
                          1
                        )}
                        onClick={handleRating}
                        onPointerEnter={onPointerEnter}
                        onPointerLeave={onPointerLeave}
                        onPointerMove={onPointerMove}
                      />
                      <p className="text-white text-[12px] opacity-50 mt-[4px]">
                        {parseFloat(bookValue?.avgRating).toFixed(1)}
                      </p>
                    </div> */}
                    {locationValue.length > 0 && (
                      <>
                        <p className="mt-[36px] text-white text-[16px]">
                          {" "}
                          {language.location[locale]}:{" "}
                          <span className="opacity-50">
                            {" "}
                            {locationValue.map((loc, i) => (
                              <>
                                <>{loc.collection}</>
                                <>{locationValue.length > i + 1 && ", "}</>
                              </>
                            ))}
                          </span>{" "}
                        </p>
                        <p className="mt-[10px] text-white text-[16px]">
                          {" "}
                          {language.call_no[locale]}:{" "}
                          <span className="opacity-50">
                            {" "}
                            {locationValue.map((loc, i) => (
                              <>
                                <>{loc.callNum}</>
                                <>{locationValue.length > i + 1 && ", "}</>
                              </>
                            ))}
                          </span>{" "}
                        </p>
                      </>
                    )}
                    {validateData?.status === "Available" &&
                      validateData?.count > 0 &&
                      isAuthenticated && (
                        <div className="mt-[36px] flex gap-[8px]">
                          {reserveLoading ? (
                            <div
                              role="status"
                              className="flex justify-center w-full h-full md:!h-[40px] items-center"
                            >
                              <svg
                                aria-hidden="true"
                                class="me-2 w-8 h-8 text-[#616161] animate-spin fill-white"
                                viewBox="0 0 100 101"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                  fill="currentColor"
                                />
                                <path
                                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                  fill="currentFill"
                                />
                              </svg>
                              <span class="sr-only">Loading...</span>
                            </div>
                          ) : (
                            <>
                              {!bookReserved?.status && bookValue.url &&
                                isAuthenticated &&
                                memberShipData && (
                                  <button
                                    className="flex justify-center align-center text-white text-[16px] px-[20px] py-[6px] w-fit gradient-border relative"
                                    onClick={() =>
                                      bookValue.url
                                        ? window.open(bookValue.url)
                                        : handleReserve()
                                    }
                                  >
                                    {bookValue.url
                                      ? language.read_online[locale]
                                      : language.reserve_title[locale]}
                                  </button>
                                )}
                            </>
                          )}
                          <button className="hidden flex justify-center align-center text-white text-[16px] px-[20px] py-[6px] w-fit border-[1.5px] border-[#FFFFFF33] gradient-border rounded-[24px] relative">
                            {language.share[locale]}
                          </button>
                        </div>
                      )}
                    {!isAuthenticated && (
                      <button
                        className="flex justify-center align-center text-white text-[16px] px-[20px] py-[6px] w-fit border-[1.5px] border-[#FFFFFF33] rounded-[24px] gradient-border relative mt-[2rem]"
                        onClick={loginWithRedirect}
                      >
                        {language.login[locale]}
                      </button>
                    )}
                    {isAuthenticated && !memberShipData && (
                      <Link
                        onClick={closePopup}
                        to="/membership"
                        className="flex justify-center align-center text-white text-[16px] px-[20px] py-[6px] w-fit border-[1.5px] border-[#FFFFFF33] rounded-[24px] gradient-border relative mt-[2rem]"
                      >
                        {language.upgrade_membership[locale]}
                      </Link>
                    )}
                    {(validateData?.status !== "Available" ||
                      !validateData?.count) &&
                      isAuthenticated && (
                        <h2 className="text-[16px] text-white">
                          {language.no_reserve[locale]}
                        </h2>
                      )}
                    {bookReserved && (
                      <div className="flex gap-[.5rem] mt-[1.5rem]">
                        {bookReserved.status === "success" ? (
                          <img
                            src={GreenTick}
                            alt="close"
                            className="w-[30px]"
                          />
                        ) : (
                          <img src={RedX} alt="close" className="w-[30px]" />
                        )}
                        <h2 className="text-[16px] text-white">
                          {bookReserved.status === "success"
                            ? language.reserved_it[locale]
                            : bookReserved.message}
                        </h2>
                      </div>
                    )}
                  </>
                )}
              </div>
              <button
                className="hidden md:block relative opacity-7 h-[36px] min-w-[36px] w-[36px] bg-[#FFFFFF4D] py-0 rounded-full"
                onClick={closeModal}
              >
                <img
                  src={close}
                  alt="close"
                  className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
                />
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Modal;

import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import DatePicker from "../Modal/DatePicker";
import GuestCount from "../Modal/GuestCount";
import SelectPod from "./SelectPod";
import Summary from "../Modal/Summary";
import TimeSlot from "./TimeSlot";
import moment from "moment";
import close from "../../assets/svg/close.svg";
import FormSteps from "../Modal/FormSteps";
import { ReactComponent as Guests } from "../../assets/svg/reserve_guests.svg";
import { ReactComponent as Dates } from "../../assets/svg/reserve_date.svg";
import { ReactComponent as Time } from "../../assets/svg/reserve_time.svg";
import { ReactComponent as Pods } from "../../assets/svg/reserve_select.svg";
import { ReactComponent as Terms } from "../../assets/svg/tc.svg";
import axios from "axios";
import { fetchClient } from "../../axios-config";
import { useAuth0 } from "@auth0/auth0-react";
import { notifyInfo } from "../../utils/notifyToast";
import language from "../../Json/locale.json";
import useStorage from "../../hooks/useStorage";
import TC from "./TC";
import TimeSlots from "../../Json/timeSlots.json";
import DisabledDates from "../../Json/disableDates.json";

const Modal = ({ isOpen, closeModal }) => {
  const { loginWithRedirect, isAuthenticated, getAccessTokenSilently } =
    useAuth0();
  const [step, setStep] = useState(0);
  const [count, setCount] = useState(1);
  const [loading, setLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [duration, setDuration] = useState(2);
  const [timeBlock, setTimeBlock] = useState();
  const [timeRange, setTimeRange] = useState();
  const [resetCoupon, setResetCoupon] = useState(false);
  const [notes, setNotes] = useState({ sanitizedValue: "", value: "" });
  const [selectedTimeBlock, setSelectedTimeBlock] = useState([]);
  const [disabledDates, setDisabledDates] = useState([]);
  const [tC, setTC] = useState([]);
  const [coupon, setCoupon] = useState("");
  const [amount, setAmount] = useState();
  const systemHours = moment().add(15, "minutes").hours().toString();
  const systemMinutes = moment().add(15, "minutes").minutes().toString();
  const [time, setTime] = useState({
    startTime: (systemHours.length === 1 ? "0" : "") + systemHours,
    endTime: (systemMinutes.length === 1 ? "0" : "") + systemMinutes,
  });
  const [selectedPod, setSelectedPod] = useState();
  const [pods, setPods] = useState([]);
  const [ap, setAp] = useState(
    moment().add(15, "minutes").hours() >= 12 ? "PM" : "AM"
  );
  const locale = useStorage();

  const data = [
    <Guests fill={step === 0 ? "#111" : "white"} />,
    <Dates fill={step === 1 ? "#111" : "white"} />,
    <Time fill={step === 2 ? "#111" : "white"} />,
    <Pods fill={step === 3 ? "#111" : "white"} />,
    <Terms fill={step === 4 ? "#111" : "white"} />,
  ];

  useEffect(() => {
    const getDisabledDates = async () => {
      setLoading(true);
      const data = await fetchClient(
        getAccessTokenSilently,
        isAuthenticated,
        `?action=space_operating_settings`,
        "",
        false
      );
      if (data)
        setDisabledDates(
          data.data.disabled_dates
            .filter((dt) => dt)
            .map((date) => moment(date, "MM/DD/YYYY").format("YYYY-MM-DD"))
        );
      setLoading(false);
    };
    const getTC = async () => {
      setLoading(true);
      const data = await fetchClient(
        getAccessTokenSilently,
        isAuthenticated,
        `space-booking-term?&populate=*&_locale=${
          localStorage.getItem("lang") || "en"
        }`,
        "",
        true
      );
      console.log(data);
      if (data?.data?.attributes?.SpaceTerms)
        setTC(data.data.attributes.SpaceTerms);
      setLoading(false);
    };
    if (isOpen) {
      getDisabledDates();
      getTC();
    }
  }, [isOpen]);

  useEffect(() => {
    if (timeRange) {
      console.log(timeRange);
      var times = [];
      var breakStartTime = "2:00 AM";

      var breakEndTime = "3:00 AM";

      var interval = duration * 60;
      var tempStartTime = convertTimeToNumber(timeRange.start_time);
      var tempEndTime = convertTimeToNumber(timeRange.end_time);
      var convertStartTime = timeStringToFloat(
        moment(
          time.startTime +
            ":" +
            (Math.round(time.endTime / 10) * 10 >= 60
              ? 59
              : Math.round(time.endTime / 10) * 10) +
            " " +
            ap,
          ["h:mm A"]
        ).format("h:mm A")
      );
      var timeAsDecimal = decimalToTimeString(
        Number(systemHours) + Number(systemMinutes) / 60
      );
      // console.log(
      //   convertToShortDate(convertStartTime.date, convertStartTime.time) <
      //     convertToShortDate(selectedDate, timeAsDecimal.time),
      //   moment(selectedDate).format("YYYYMMDD") ===
      //     moment(new Date()).format("YYYYMMDD"),
      //   convertToShortDate(convertStartTime.date, convertStartTime.time) <
      //     convertToShortDate(tempStartTime.date, tempStartTime.time),
      //   convertToShortDate(convertStartTime.date, convertStartTime.time) >
      //     convertToShortDate(tempEndTime.date, tempEndTime.time)
      // );
      tempStartTime =
        convertToShortDate(convertStartTime.date, convertStartTime.time) <
          convertToShortDate(selectedDate, timeAsDecimal.time) &&
        moment(selectedDate).format("YYYYMMDD") ===
          moment(new Date()).format("YYYYMMDD")
          ? {
              date: moment(selectedDate).format("Do MMMM YYYY"),
              time: convertStartTime.time,
            }
          : convertToShortDate(convertStartTime.date, convertStartTime.time) <
              convertToShortDate(tempStartTime.date, tempStartTime.time) ||
            convertToShortDate(convertStartTime.date, convertStartTime.time) >
              convertToShortDate(tempEndTime.date, tempEndTime.time)
          ? tempStartTime
          : convertStartTime;

      var currentDate = convertToShortDate(
        tempStartTime.date,
        tempStartTime.time
      );

      while (
        currentDate.isBefore(
          convertToShortDate(tempEndTime.date, tempEndTime.time)
        )
      ) {
        var startTime = convertToShortDate(
          tempStartTime.date,
          tempStartTime.time
        );
        var endTime = convertToShortDate(tempEndTime.date, tempEndTime.time);

        while (startTime.isBefore(endTime)) {
          var endTimeSlot = startTime.clone().add(interval, "minutes");
          var breakStartTimeMoment = moment(breakStartTime, "h:mm A").add(
            1,
            "seconds"
          );
          var breakEndTimeMoment = moment(breakEndTime, "h:mm A");

          var startTimeMoment = moment(startTime.format("HH:mm"), "h:mm A");
          var endTimeSlotMoment = moment(endTimeSlot.format("HH:mm"), "h:mm A");
          // console.log(
          //   startTimeMoment,
          //   startTimeMoment.isBetween(
          //     breakStartTimeMoment,
          //     breakEndTimeMoment,
          //     null,
          //     "[]"
          //   ),
          //   endTimeSlotMoment,
          //   endTimeSlotMoment.isBetween(
          //     breakStartTimeMoment,
          //     breakEndTimeMoment,
          //     null,
          //     "[]"
          //   )
          // );
          if (
            !(
              startTimeMoment.isBetween(
                breakStartTimeMoment,
                breakEndTimeMoment,
                null,
                "[]"
              ) ||
              endTimeSlotMoment.isBetween(
                breakStartTimeMoment,
                breakEndTimeMoment,
                null,
                "[]"
              )
            )
          ) {
            // console.log(
            //   startTime.format("Do MMM YYYY h:mm A"),
            //   endTimeSlot.format("Do MMM YYYY h:mm A")
            // );
            var timeString = startTime.format("Do MMM YYYY h:mm A");

            times.push({
              startTime: timeString,
              endTime: endTimeSlot.format("Do MMM YYYY h:mm A"),
            });
          } else if (
            breakEndTimeMoment.format("HH:mm") < endTimeSlot.format("HH:mm")
          ) {
            var adjustedStartTime = moment(
              endTimeSlot.format("Do MMMM YYYY") +
                " " +
                breakEndTimeMoment.format("HH:mm"),
              "Do MMMM YYYY h:mm A"
            );

            var adjustedEndTime = adjustedStartTime
              .clone()
              .add(duration, "hours");
            // console.log(
            //   adjustedStartTime.format("Do MMM YYYY h:mm A"),
            //   adjustedEndTime.format("Do MMM YYYY h:mm A")
            // );
            times.push({
              startTime: adjustedStartTime.format("Do MMM YYYY h:mm A"),
              endTime: adjustedEndTime.format("Do MMM YYYY h:mm A"),
            });

            startTime = adjustedStartTime;
          }

          startTime.add(interval, "minutes");
        }

        currentDate.add(1, "days");
        tempStartTime.date = currentDate.format("Do MMMM YYYY");
      }

      if (times.length > 0) {
        var lastEndTime = moment(
          times[times.length - 1].endTime,
          "Do MMMM YYYY h:mm A"
        );
        if (
          lastEndTime.isSameOrAfter(
            convertToShortDate(tempEndTime.date, tempEndTime.time).add(
              1,
              "seconds"
            )
          )
        ) {
          times.pop();
        }
      }

      times.forEach((slot) => {
        slot.startTime = moment(slot.startTime, "Do MMMM YYYY h:mm A").format(
          "Do MMM h:mm A"
        );
        slot.endTime = moment(slot.endTime, "Do MMMM YYYY h:mm A").format(
          "Do MMM h:mm A"
        );
      });

      setTimeBlock();
      setSelectedTimeBlock(times);
      console.log(times);
    }
  }, [duration, ap, time, selectedDate, timeRange]);

  const decimalToTimeString = (decimalTime) => {
    const hours = Math.floor(decimalTime);
    const minutes = Math.round((decimalTime - hours) * 60);

    let formattedTime = `${hours}:${minutes < 10 ? "0" : ""}${minutes}`;

    formattedTime += hours < 12 ? " AM" : " PM";

    if (hours > 12) {
      formattedTime = `${hours - 12}:${minutes < 10 ? "0" : ""}${minutes} PM`;
    } else if (hours === 0) {
      formattedTime = `12:${minutes < 10 ? "0" : ""}${minutes} AM`;
    }

    return formattedTime;
  };

  const convertToShortDate = (date, time) =>
    moment(date + " " + time, "Do MMMM YYYY h:mm A");

  const convertTimeToNumber = (time) => {
    const dateTime = moment(time, "YYYY-MM-DD h:mm A");
    const formattedDate = dateTime.format("Do MMMM YYYY");
    const formattedTime = dateTime.format("hh:mm A");
    let hour24 = dateTime.hours();

    if (hour24 === 12 && dateTime.format("A") === "AM") {
      hour24 = 0;
    } else if (hour24 === 12 && dateTime.format("A") === "PM") {
      hour24 = 12;
    } else if (dateTime.format("A") === "PM") {
      hour24 += 12;
    }

    return {
      date: formattedDate,
      time: formattedTime,
    };
  };

  const timeStringToFloat = (time) => {
    const currentDate = moment(selectedDate).format("YYYY-MM-DD");
    const currentDateTime = moment(
      currentDate + " " + time,
      "YYYY-MM-DD h:mm A"
    );
    const formattedDate = currentDateTime.format("Do MMMM YYYY");
    const formattedTime = currentDateTime.format("hh:mm A");

    return {
      date: formattedDate,
      time: formattedTime,
    };
  };

  const formatDatetoApiValue = (time) => {
    const [day, month, hour, minute, period] = time.split(/[\s:-]/);
    const monthNumber = moment().month(month).format("MM");

    let formattedHour = parseInt(hour);
    if (period.toUpperCase() === "AM" && formattedHour === 12) {
      formattedHour = 0;
    } else if (period.toUpperCase() === "PM" && formattedHour !== 12) {
      formattedHour += 12;
    }

    const formattedDateTime = moment()
      .year(moment().year())
      .month(parseInt(monthNumber) - 1)
      .date(parseInt(day))
      .hour(formattedHour)
      .minute(parseInt(minute))
      .format("YYYY-MM-DD hh:mm A");

    return formattedDateTime;
  };

  const nextStep = () => {
    if (step === 2) {
      if (!selectedTimeBlock[timeBlock]) {
        notifyInfo(language.select_time_slot[locale]);
        return;
      }
    }
    if (step === 3) {
      if (!selectedPod) {
        notifyInfo(language.select_pod[locale]);
        return;
      }
    }
    setStep((prevState) => prevState + 1);
  };

  const prevStep = () => {
    setStep((prevState) => prevState - 1);
  };

  useEffect(() => {
    if (timeBlock?.toString()) {
      const getPods = async () => {
        setLoading(true);
        const formData = new FormData();
        formData.append("action", "availableSpaces");
        formData.append("total_attendees", count);
        formData.append(
          "start_date_timing",
          formatDatetoApiValue(selectedTimeBlock[timeBlock].startTime)
        );
        formData.append(
          "end_date_timing",
          formatDatetoApiValue(selectedTimeBlock[timeBlock].endTime)
        );
        const data = await fetchClient(
          getAccessTokenSilently,
          isAuthenticated,
          "",
          formData,
          false
        );
        if (data) setPods(data.data);
        setLoading(false);
      };
      getPods();
    }
  }, [timeBlock]);

  useEffect(() => {
    handleSelectedDate(selectedDate);
  }, [selectedDate]);

  const handleSelectedDate = async (date) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("action", "spaceWorkingHours");
    formData.append("date", moment(date).format("YYYY-MM-DD"));
    formData.append("iso_format", true);
    const data = await fetchClient(
      getAccessTokenSilently,
      isAuthenticated,
      "",
      formData,
      false
    );
    if (data) setTimeRange(data.data[0]);
    setLoading(false);
  };

  const validateCoupon = async () => {
    if (!coupon) {
      notifyInfo(language.no_coupon[locale]);
      return;
    }
    setLoading(true);
    const formData = new FormData();
    const purchaseItem = {
      spaces: [
        {
          number_of_attendees: count,
          start_date_timing: formatDatetoApiValue(
            selectedTimeBlock[timeBlock].startTime
          ),
          end_date_timing: formatDatetoApiValue(
            selectedTimeBlock[timeBlock].endTime
          ),
          space_id: selectedPod.id,
          notes: notes,
        },
      ],
      coupon: coupon,
    };
    console.log(purchaseItem);
    formData.append("action", "validateCoupon");
    formData.append("items", JSON.stringify(purchaseItem));
    formData.append("site", "houseofwisdom.ae");
    formData.append("channel", "houseofwisdom-web");
    const data = await fetchClient(
      getAccessTokenSilently,
      isAuthenticated,
      "",
      formData,
      false
    );
    if (data) {
      setAmount(data.data.spaces[0]);
      setResetCoupon(true);
    }
    setLoading(false);
  };

  const createPurchase = async () => {
    if (notes.value.trim().length < 4) {
      notifyInfo(language.no_notes[locale]);
      return;
    }
    setLoading(true);
    const formData = new FormData();
    const purchaseItem = {
      spaces: [
        {
          number_of_attendees: count,
          start_date_timing: formatDatetoApiValue(
            selectedTimeBlock[timeBlock].startTime
          ),
          end_date_timing: formatDatetoApiValue(
            selectedTimeBlock[timeBlock].endTime
          ),
          space_id: selectedPod.id,
          notes: notes.sanitizedValue,
        },
      ],
      coupon: coupon,
    };
    console.log(purchaseItem);
    formData.append("action", "createPurchase");
    formData.append("items", JSON.stringify(purchaseItem));
    formData.append("site", "houseofwisdom.ae");
    formData.append("channel", "houseofwisdom-web");
    const data = await fetchClient(
      getAccessTokenSilently,
      isAuthenticated,
      "",
      formData,
      false
    );
    if (data) initiatePayment(data);
    else setLoading(false);
  };

  const initiatePayment = async (value) => {
    const formData = new FormData();
    formData.append("action", "initiatePayment");
    formData.append("purchase_number", value.purchase_number);
    formData.append("purchase_id", value.id);
    formData.append(
      "redirect_to",
      `${window.location.origin}/profile?purchases`
    );
    const data = await fetchClient(
      getAccessTokenSilently,
      isAuthenticated,
      "",
      formData,
      false
    );
    if (data) window.location.href = data.payment_link;
    else setLoading(false);
  };

  const resetReserveModal = () => {
    setStep(0);
    setCount(0);
    setLoading(false);
    setSelectedDate(new Date());
    setDuration(2);
    setTimeBlock();
    setSelectedTimeBlock([]);
    setTime({
      startTime: (systemHours.length === 1 ? "0" : "") + systemHours,
      endTime: (systemMinutes.length === 1 ? "0" : "") + systemMinutes,
    });
    setSelectedPod();
    setPods([]);
    setAp(moment().add(15, "minutes").hours() >= 12 ? "PM" : "AM");
    setTimeRange();
    setNotes({ sanitizedValue: "", value: "" });
    setDisabledDates([]);
    setCoupon("");
    setAmount();
    closeModal();
  };

  const Back = () => (
    <button
      className="flex justify-center align-center text-white text-[16px] px-[20px] py-[6px] rounded-[24px] w-fit border-[1.5px] border-[#FFFFFF33]"
      onClick={prevStep}
      disabled={loading}
    >
      {language.back[locale]}
    </button>
  );

  const Continue = () => (
    <button
      className="flex justify-center align-center text-white text-[16px] px-[20px] py-[6px] w-fit gradient-border relative "
      onClick={
        step === 5
          ? createPurchase
          : isAuthenticated
          ? nextStep
          : loginWithRedirect
      }
      disabled={loading}
    >
      {loading && (
        <div
          role="status"
          className="flex justify-center w-full h-full items-center"
        >
          <svg
            aria-hidden="true"
            class="mr-2 mt-[2px] w-5 h-5 text-[#616161] animate-spin fill-white"
            viewBox="0 0 100 101"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
              fill="currentColor"
            />
            <path
              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
              fill="currentFill"
            />
          </svg>
          <span class="sr-only">Loading...</span>
        </div>
      )}
      {step === 5
        ? language.pay[locale]
        : step === 4
        ? language.agree[locale]
        : isAuthenticated
        ? language.continue[locale]
        : language.login[locale]}
    </button>
  );

  return (
    <>
      {isOpen && (
        <div className="flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none backdrop-blur-sm">
          <div className="relative w-full h-full md:min-w-[400px] md:min-h-[400px] md:w-auto md:h-auto md:my-6 mx-auto max-w-3xl">
            <div className="bg-modalBg md:max-h-[90vh] h-full min-h-screen md:min-h-[400px] px-[24px] py-[20px] md:px-[60px] md:py-[40px] border-0 md:rounded-lg shadow-lg relative flex flex-col w-full outline-none focus:outline-none overflow-scroll">
              <div className="fixed top-0 left-[24px] right-[24px] z-10 bg-modalBg md:max-h-[90vh] pt-[20px] pb-[10px] md:static md:pt-0 md:pb-0 flex items-center justify-between mb-[60px] md:mb-[36px] rounded-t ">
                <h3 className="text-[20px] md:text-[32px] text-white">
                  {step > 3
                    ? step === 4
                      ? language.terms_conditions[locale]
                      : language.summary[locale]
                    : language.reserve_pod[locale]}
                </h3>
                {step < 5 && (
                  <button
                    className="relative opacity-7 h-[36px] w-[36px] block bg-[#FFFFFF4D] py-0 rounded-full"
                    onClick={resetReserveModal}
                  >
                    <img
                      src={close}
                      alt="close"
                      className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
                    />
                  </button>
                )}
              </div>
              {step < 5 && <FormSteps step={step} data={data} />}
              {step === 0 && (
                <>
                  <GuestCount count={count} setCount={setCount} />
                  <div className="fixed bottom-0 left-0 z-10 bg-modalBg md:max-h-[90vh] pt-[10px] pb-[20px] md:static md:pt-0 md:pb-0 flex justify-center items-center w-full z-10">
                    <Continue />
                  </div>
                </>
              )}
              {step === 1 && (
                <>
                  <DatePicker
                    selectedDate={selectedDate}
                    setSelectedDate={setSelectedDate}
                    disabledDates={disabledDates}
                  />
                  <div className="fixed bottom-0 left-0 z-10 bg-modalBg md:max-h-[90vh] pt-[10px] pb-[20px] md:static md:pt-0 md:pb-0 flex justify-center items-center w-full z-10 mt-[36px] gap-[8px]">
                    <Back />
                    <Continue />
                  </div>
                </>
              )}
              {step === 2 && (
                <>
                  <TimeSlot
                    duration={duration}
                    setDuration={setDuration}
                    timeBlock={timeBlock}
                    setTimeBlock={setTimeBlock}
                    selectedTimeBlock={selectedTimeBlock}
                    ap={ap}
                    setAp={setAp}
                    time={time}
                    setTime={setTime}
                  />
                  <div className="fixed bottom-0 left-0 z-10 bg-modalBg md:max-h-[90vh] pt-[10px] pb-[20px] md:static md:pt-0 md:pb-0 flex justify-center items-center w-full z-10 mt-[36px] gap-[8px]">
                    <Back />
                    <Continue />
                  </div>
                </>
              )}
              {step === 3 && (
                <>
                  <SelectPod
                    loading={loading}
                    pods={pods}
                    selectedPod={selectedPod}
                    setSelectedPod={setSelectedPod}
                  />
                  <div className="fixed bottom-0 left-0 z-10 bg-modalBg md:max-h-[90vh] pt-[10px] pb-[20px] md:static md:pt-0 md:pb-0 flex justify-center items-center w-full z-10 mt-[36px] gap-[8px]">
                    <Back />
                    <Continue />
                  </div>
                </>
              )}
              {step === 4 && (
                <>
                  <TC tC={tC} />
                  <div className="fixed bottom-0 left-0 z-10 bg-modalBg md:max-h-[90vh] pt-[10px] pb-[20px] md:static md:pt-0 md:pb-0 flex justify-center items-center w-full z-10 mt-[36px] gap-[8px]">
                    <Back />
                    <Continue />
                  </div>
                </>
              )}
              {step === 5 && (
                <>
                  <Summary
                    loading={loading}
                    selectedTimeBlock={selectedTimeBlock[timeBlock]}
                    selectedPod={selectedPod}
                    selectedDate={selectedDate}
                    count={count}
                    notes={notes}
                    setNotes={setNotes}
                    amount={amount}
                    setAmount={setAmount}
                    coupon={coupon}
                    setCoupon={setCoupon}
                    validateCoupon={validateCoupon}
                    resetCoupon={resetCoupon}
                    setResetCoupon={setResetCoupon}
                  />
                  <div className="fixed bottom-0 left-0 z-10 bg-modalBg md:max-h-[90vh] pt-[10px] pb-[20px] md:static md:pt-0 md:pb-0 flex justify-center items-center w-full z-10 mt-[36px] gap-[8px]">
                    <Back />
                    <Continue />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Modal;

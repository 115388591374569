import React from "react";

function Banner({ url, title, desc }) {
  return (
    <div
      className={`BannerContainer w-full h-[60vh] md: bg-cover bg-center flex justify-center items-center header-banner-image`}
      style={{
        backgroundImage: `linear-gradient(360deg, rgba(0, 0, 0, 0.3) 0%,rgba(0, 0, 0, 0.0001)  100%),url(${url})`,
      }}
    >
      <div className="flex flex-col justify-center items-center h-full w-[90%] md:w-[600px] pageIntroBannerConetnt">
        <h2 className="text-white text-[32px]  text-center">{title}</h2>
        <h6
          className="text-white text-[16px] mt-[8px] mb-[24px] text-center"
          dangerouslySetInnerHTML={{ __html: desc }}
        />
      </div>
    </div>
  );
}

export default Banner;

import React, { useEffect, useState, useRef } from "react";
import { useDraggable } from "react-use-draggable-scroll";
import moment from "moment";
import { Link, useNavigate } from "react-router-dom";
import { LazyLoadComponent } from "react-lazy-load-image-component";
import leftslide from "../assets/images/leftslide.png";
import rightslide from "../assets/images/rightslide.png";
import PackageDetails from "./PackageDetails/PackageDetails";
import language from "../Json/locale.json";
import useStorage from "../hooks/useStorage";
import GalleryPopup from "./GalleryPopup";

const Events = ({
  title,
  packages,
  categories,
  loading,
  showTitle,
  experience,
  tag,
  ramadan,
}) => {
  const [eventsTab, setEventsTab] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isGalleryOpen, setIsGalleryOpen] = useState(false);
  const [packageSlug, setPackageSlug] = useState("");
  const [gallery, setGallery] = useState({});
  const [filteredPackages, setFilteredPackages] = useState([]);
  const scrollref = useRef();
  const navigate = useNavigate();
  const { events } = useDraggable(scrollref);
  const [width, setWidth] = useState(window.innerWidth);
  const locale = useStorage();

  useEffect(() => {
    window.addEventListener("resize", () => setWidth(window.innerWidth));
    return () => {
      window.removeEventListener("resize", () => setWidth(window.innerWidth));
    };
  }, []);

  useEffect(() => {
    if (experience) {
      setFilteredPackages(packages.slice(0, 8));
    } else {
      setFilteredPackages(packages);
    }
  }, [packages]);

  useEffect(() => {
    if (isModalOpen) {
      document
        .getElementsByTagName("body")[0]
        ?.classList.add("overflow-hidden");
    } else {
      document
        .getElementsByTagName("body")[0]
        ?.classList.remove("overflow-hidden");
    }
  }, [isModalOpen]);

  useEffect(() => {
    setFilteredPackages(
      packages.filter((packageData) => {
        if (eventsTab) {
          const selectedCategory =
            categories[eventsTab - 1]?.name.toLowerCase();
          return packageData.category.some(
            (cat) =>
              cat[locale === "ar" ? "name_ar" : "name"]?.toLowerCase() ===
              selectedCategory
          );
        }
        return true;
      })
    );
  }, [eventsTab]);

  return (
    <div className="w-full flex justify-center">
      <div
        className={`w-11/12 max-w-[1800px] relative ${
          !window.location.pathname.includes("happenings") && "mt-[30px]"
        }`}
      >
        {!showTitle && !ramadan && (
          <>
            <div
              className={`flex justify-between md:justify-center overflow-scroll ${
                experience && "h-[40px]"
              }`}
            >
              {!experience && !tag && (
                <ul className="flex text-[20px] md:text-[32px] gap-[36px] mt-[30px]">
                  <li
                    className={`text-white cursor-pointer pb-[8px] whitespace-nowrap ${
                      !eventsTab ? "border-b-[1px] border-white" : "opacity-50"
                    }`}
                    onClick={() => setEventsTab(0)}
                  >
                    {language.all[locale]}
                  </li>
                  {categories
                    ?.sort((a, b) => Number(a.position) - Number(b.position))
                    .map((category, idx) => (
                      <>
                        <li
                          className={`text-white cursor-pointer pb-[8px] whitespace-nowrap ${
                            eventsTab === idx + 1
                              ? "border-b-[1px] border-white"
                              : "opacity-50"
                          }`}
                          onClick={() => setEventsTab(idx + 1)}
                        >
                          {category.name}
                        </li>
                      </>
                    ))}
                </ul>
              )}
              {tag && (
                <ul className="flex text-[20px] md:text-[32px] gap-[36px] mt-[30px]">
                  <li className="text-white cursor-pointer pb-[8px] whitespace-nowrap border-b-[1px] border-white">
                    {language.events_title[locale]}
                  </li>
                </ul>
              )}

              <div className="flex justify-between items-center w-full">
                <h6 className="text-[20px] md:text-[32px] text-white">
                  {experience && title}
                </h6>
                {!window.location.pathname.includes("happenings") && (
                  <Link to="/happenings" className="text-[#8D8D8D] text-[16px]">
                    {language.discover_more[locale]}
                  </Link>
                )}
              </div>
            </div>
            <p className="text-white text-[17px] mb-[30px] mt-[20px]">
              {language.experience_desc[locale]}
            </p>
          </>
        )}
        {!loading && !ramadan && !filteredPackages.length && (
          <h2 className="text-white text-center mt-[7rem] text-[24px]">
            {language.no_packages_found[locale]}
          </h2>
        )}
        <div className="relative">
          {(experience || ramadan) && filteredPackages.length > 0 && (
            <button
              onClick={() =>
                document.getElementById("packages").scrollBy({
                  left: width > 820 ? -1000 : -360,
                  behavior: "smooth",
                })
              }
              className="absolute top-[40%] left-0 text-white w-10 text-center z-10 p-0 ml-[-15px] transition-all ease-in-out duration-300"
            >
              <img src={leftslide} alt="leftslide" />
            </button>
          )}
          <div
            id="packages"
            className={`w-full cursor-pointer ${
              experience || ramadan
                ? "flex gap-[12px] overflow-x-scroll"
                : "w-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-y-[24px] gap-x-[12px]"
            }`}
            {...events}
            ref={scrollref}
          >
            {!loading ? (
              <>
                {filteredPackages.map((packageData, idx) => (
                  <LazyLoadComponent id={idx}>
                    {ramadan ? (
                      <div
                        style={{
                          backgroundImage: `linear-gradient(rgb(0 0 0 / 50%), rgb(0 0 0 / 50%)),url(${packageData})`,
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "cover",
                        }}
                        onClick={() => {
                          setGallery(packageData);
                          setIsGalleryOpen(true);
                        }}
                        className="bg-image-gradient w-full h-[293px] rounded-[8px] p-[24px] md:p-[48px] flex items-end justify-start shrink-0 max-w-[450px]"
                      >
                        {/* <p className="text-[20px] md:text-[24px] mb-[8px] text-white twolinetextellipsis">
                        </p> */}
                      </div>
                    ) : (
                      <div
                        style={{
                          backgroundImage: `linear-gradient(rgb(0 0 0 / 50%), rgb(0 0 0 / 50%)),url(${
                            packageData.img.includes("https")
                              ? packageData.img
                              : process.env.REACT_APP_IMG_URL +
                                encodeURI(packageData.img)
                          })`,
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "cover",
                        }}
                        onClick={() => {
                          if (packageData.redirect_tag) {
                            navigate(
                              `/happenings/tag/${packageData.redirect_tag}`
                            );
                          } else {
                            window.history.replaceState(
                              null,
                              null,
                              `?go&type=package&uuid=${packageData.url_slug}`
                            );
                            localStorage.setItem(
                              "last_visited_url",
                              window.location.pathname + window.location.search
                            );
                            setPackageSlug(packageData.url_slug);
                            setIsModalOpen(true);
                          }
                        }}
                        className={`bg-image-gradient w-full h-[293px] rounded-[8px] p-[24px] md:p-[48px] flex items-end justify-start shrink-0 ${
                          experience && "max-w-[450px]"
                        }`}
                      >
                        <div>
                          <p className="text-[20px] md:text-[24px] mb-[8px] text-white twolinetextellipsis">
                            {packageData.package_name}
                          </p>
                          {packageData.start_date && packageData.end_date && (
                            <p className="text-[12px] md:text-[16px] text-white">
                              {moment(
                                packageData.start_date,
                                "YYYY-MM-DD"
                              ).format("LL")}
                              {packageData.start_date !==
                                packageData.end_date && (
                                <>
                                  {" - "}
                                  {moment(
                                    packageData.end_date,
                                    "YYYY-MM-DD"
                                  ).format("LL")}
                                </>
                              )}
                            </p>
                          )}
                        </div>
                      </div>
                    )}
                  </LazyLoadComponent>
                ))}
              </>
            ) : (
              <>
                {["", "", ""].map(() => (
                  <div
                    role="status"
                    class="space-y-8 animate-pulse md:space-y-0 md:space-x-8 md:flex md:items-center w-full h-[293px]"
                  >
                    <div class="flex justify-center items-center w-full h-[293px] bg-gray-300 rounded dark:bg-gray-700"></div>
                    <span class="sr-only">Loading...</span>
                  </div>
                ))}
              </>
            )}
          </div>
          {(experience || ramadan) && filteredPackages.length > 0 && (
            <button
              onClick={() =>
                document.getElementById("packages").scrollBy({
                  left: width > 820 ? 1000 : 360,
                  behavior: "smooth",
                })
              }
              className="absolute top-[40%] right-0 text-white w-10 text-center z-10 p-0 mr-[-15px] transition-all ease-in-out duration-300"
            >
              <img src={rightslide} alt="rightslide" />
            </button>
          )}
        </div>
      </div>
      <PackageDetails
        setPackageSlug={setPackageSlug}
        packageSlug={packageSlug}
        isOpen={isModalOpen}
        closeModal={() => {
          window.history.replaceState(null, null, window.location.pathname);
          localStorage.setItem("last_visited_url", window.location.pathname);
          setIsModalOpen(false);
        }}
      />
      <GalleryPopup
        gallery={gallery}
        isOpen={isGalleryOpen}
        setIsGalleryOpen={setIsGalleryOpen}
      />
    </div>
  );
};

export default Events;

import { FETCH_DATA, PROFILE_DATA } from "./action";

const values = {
  heading: "",
  description: "",
  image: { data: { attributes: { name: "", url: "" } } },
};

const INITIAL_STATE = {
  data: {
    home: {
      ...values,
      button_text: "",
      button_link: "",
    },
    read: values,
    happenings: values,
    reserve: values,
    dine: values,
    about: values,
    membership: values,
    bookclub: values,
  },
  profile: null,
};

export const storeData = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_DATA:
      return { ...state, data: action.payload };
    case PROFILE_DATA:
      return { ...state, profile: action.payload };
    default:
      return INITIAL_STATE;
  }
};

import React, { useState } from "react";
import language from "../../Json/locale.json";
import useStorage from "../../hooks/useStorage";

const Hotel = React.forwardRef(({ data }, ref) => {
  const [isLoading, setIsLoading] = useState(true);
  const locale = useStorage();

  const handleOpenWindow = () => data.link && window.open(data.link);

  return (
    <div className="w-full flex justify-center mt-[84px]">
      <div
        className="w-11/12 max-w-[1800px] md:gap-[125px] flex flex-col md:flex-row"
        ref={ref}
      >
        {isLoading && (
          <div class="w-full h-[500px] md:h-auto md:min-w-[500px] md:max-w-[500px] bg-[#616161] rounded-[8px] animate-pulse"></div>
        )}
        <img
          onLoad={() => setIsLoading(false)}
          src={`${process.env.REACT_APP_CMS}${data.image}`}
          alt=""
          className={`w-full md:w-1/2 rounded-[8px] ${isLoading && "hidden"}`}
        />

        <div className=" mt-[20px] md:mt-0">
          <h2 className="text-white text-[20px] md:text-[32px] mb-[16px]">
            {data.title}
          </h2>
          <p
            className="text-white text-[16px] md:w-2/3 mb-[48px]"
            dangerouslySetInnerHTML={{
              __html: data.desc,
            }}
          ></p>
          <div className="mb-[36px]">
            <h6 className="text-white text-[16px]">
              {language.opening_time[locale]}
            </h6>
            <h6 className="text-white text-[16px] opacity-50">{data.time}</h6>
          </div>
          <button
            className="flex justify-center align-center text-white text-[16px] px-[20px] py-[6px] gradient-border relative"
            onClick={handleOpenWindow}
          >
            {language.view_menu[locale]}
          </button>
        </div>
      </div>
    </div>
  );
});

export default Hotel;

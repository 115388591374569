import React from "react";
import { useState } from "react";
import ReservePods from "../components/ReservePods/ReservePods";

const Action = () => {
  const [isModalOpen, setIsModalOpen] = useState(true);
  return (
    <>
      <button
        onClick={() => {
          setIsModalOpen(true);
        }}
        type="button"
        className="text-white mt-[150px]"
      >
        Action
      </button>
      <ReservePods
        isOpen={isModalOpen}
        closeModal={() => {
          setIsModalOpen(false);
        }}
      />
    </>
  );
};

export default Action;

import React from "react";

const FormSteps = ({ data, step }) => {
  return (
    <div className="flex items-center w-fill md:min-w-[400px] mt-[84px] md:mt-0 mb-[36px]">
      <div className="flex items-center relative w-full">
        {data.map((st, i) => (
          <>
            <div
              className={`${
                step === st.props.step ? "bg-white" : "bg-[#616161]"
              } rounded-full transition duration-500 ease-in-out h-[45px] w-[45px] md:h-[60px] md:w-[60px] flex justify-center items-center`}
            >
              {st}
            </div>
            {i !== data.length - 1 && (
              <div className="flex-auto border-t-2 transition duration-500 ease-in-out border-[#434343]"></div>
            )}
          </>
        ))}
      </div>
      {/* <div className="flex items-center relative">
        <div
          className={`${
            step === 1 ? "bg-white" : "bg-[#616161]"
          } rounded-full transition duration-500 ease-in-out h-[45px] w-[45px] md:h-[60px] md:w-[60px] flex justify-center items-center`}
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M17 3H21C21.2652 3 21.5196 3.10536 21.7071 3.29289C21.8946 3.48043 22 3.73478 22 4V20C22 20.2652 21.8946 20.5196 21.7071 20.7071C21.5196 20.8946 21.2652 21 21 21H3C2.73478 21 2.48043 20.8946 2.29289 20.7071C2.10536 20.5196 2 20.2652 2 20V4C2 3.73478 2.10536 3.48043 2.29289 3.29289C2.48043 3.10536 2.73478 3 3 3H7V1H9V3H15V1H17V3ZM20 11H4V19H20V11ZM15 5H9V7H7V5H4V9H20V5H17V7H15V5ZM6 13H8V15H6V13ZM11 13H13V15H11V13ZM16 13H18V15H16V13Z"
              fill={step === 1 ? "#111" : "white"}
            />
          </svg>
        </div>
      </div>
      <div className="flex-auto border-t-2 transition duration-500 ease-in-out border-[#434343]"></div>
      <div className="flex items-center relative">
        <div
          className={`${
            step === 2 ? "bg-white" : "bg-[#616161]"
          } rounded-full transition duration-500 ease-in-out h-[45px] w-[45px] md:h-[60px] md:w-[60px] flex justify-center items-center`}
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12 22C6.477 22 2 17.523 2 12C2 6.477 6.477 2 12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22ZM12 20C14.1217 20 16.1566 19.1571 17.6569 17.6569C19.1571 16.1566 20 14.1217 20 12C20 9.87827 19.1571 7.84344 17.6569 6.34315C16.1566 4.84285 14.1217 4 12 4C9.87827 4 7.84344 4.84285 6.34315 6.34315C4.84285 7.84344 4 9.87827 4 12C4 14.1217 4.84285 16.1566 6.34315 17.6569C7.84344 19.1571 9.87827 20 12 20ZM13 12H17V14H11V7H13V12Z"
              fill={step === 2 ? "#111" : "white"}
            />
          </svg>
        </div>
      </div>
      <div className="flex-auto border-t-2 transition duration-500 ease-in-out border-[#434343]"></div>
      <div className="flex items-center relative">
        <div
          className={`${
            step === 3 ? "bg-white" : "bg-[#616161]"
          } rounded-full transition duration-500 ease-in-out h-[45px] w-[45px] md:h-[60px] md:w-[60px] flex justify-center items-center`}
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14 18V20L16 21V22H8.00004L7.99604 21.004L10 20V18H2.99204C2.86073 17.9992 2.73086 17.9725 2.60988 17.9215C2.4889 17.8704 2.37919 17.7959 2.28703 17.7024C2.19488 17.6088 2.12209 17.498 2.07284 17.3763C2.02359 17.2546 1.99885 17.1243 2.00004 16.993V4.007C2.00004 3.451 2.45504 3 2.99204 3H21.008C21.556 3 22 3.449 22 4.007V16.993C22 17.549 21.545 18 21.008 18H14ZM4.00004 5V14H20V5H4.00004Z"
              fill={step === 3 ? "#111" : "white"}
            />
          </svg>
        </div>
      </div> */}
    </div>
  );
};

export default FormSteps;

import React, { useEffect, useRef, useState } from "react";
import { useDraggable } from "react-use-draggable-scroll";
import Banner from "../components/Banner";
import Events from "../components/Events";
import horizImage from "../assets/images/bookclubapril.png";
import verticalImage from "../assets/images/bookclub-april-mobile.png";
import performance from "../assets/svg/performance icon.svg";
import book from "../assets/svg/Book icons.svg";
import box from "../assets/svg/Box icon.svg";
import discussion from "../assets/svg/discussion icon.svg";
import vintage from "../assets/svg/book pictue _ invisible cities 2.svg";
import invisibleCities from "../assets/svg/book pictue _ invisible cities 1.svg";
import bookclub_bookimage from "../assets/images/bookclub-book.jpg";
import bookbanner from "../assets/images/bookclub-banner.webp";
import animate from "../gsap/animate";
import { useSelector } from "react-redux";
import { fetchClient } from "../axios-config";
import { useAuth0 } from "@auth0/auth0-react";
import language from "../Json/locale.json";
import useStorage from "../hooks/useStorage";
import useFacebookPixel from "../hooks/useFacebookPixel";

const BookClub = () => {
  const { getAccessTokenSilently, isAuthenticated } = useAuth0();
  const ref = useRef([]);
  const [loading, setLoading] = useState(false);
  const [packages, setPackages] = useState([]);
  const [width, setWidth] = useState(window.innerWidth);
  const { heading, description, image } = useSelector(
    (state) => state.data.bookclub
  );
  const workScrollref = useRef();
  const { events } = useDraggable(workScrollref);
  const locale = useStorage();
  const { trackPageView } = useFacebookPixel();

  useEffect(() => {
    trackPageView();
  }, [trackPageView]);

  const monthsBox = [
    {
      title: "Register for Book Club",
      desc: "English",
      image:
        "https://cms.houseofwisdom.ae/uploads/boxmockup_English_1_891dd45ccf.png",
      link: "https://houseofwisdom.ae/happenings/?go&type=package&uuid=book-club-042023-en",
    },
    {
      title: "Register for Book Club",
      desc: "Arabic",
      image:
        "https://cms.houseofwisdom.ae/uploads/boxmockup_English_2_134876e7b2.png",
      link: "https://houseofwisdom.ae/happenings/?go&type=package&uuid=book-club-042023-ar",
    },
  ];

  const works = [
    {
      image: book,
      desc: {
        en: "At the beginning of every month, we will announce the new ‘Reading of The Month’.",
        ar: "مع بداية كل شهر، يتم الإعلان عن الكتاب المقرر قراءته وتناوله بالنقاش.",
      },
    },
    {
      image: box,
      desc: {
        en: "Participants must register to book their spot and get their book box (which includes the featured book and some additional goodies!).",
        ar: "بعدها، يقوم المشاركون بالتسجيل لحجز أماكنهم والحصول على كتاب الشهر للبدء في قراءته.",
      },
    },
    {
      image: discussion,
      desc: {
        en: "The book club meeting will include a small lecture that highlights the key concepts and ideas in the book followed by an interactive discussion to ask questions, share ideas, and connect with others in a meaningful way.",
        ar: "بعد ذلك، يتم تنظيم جلسة تفاعلية لمناقشة الأفكار والمفاهيم الواردة في الكتاب، والتعرف على ما لدى المشاركين من آراء وملاحظات بأساليب هادفة وبنّاءة.",
      },
    },
    {
      image: performance,
      desc: {
        en: "We wrap up the month with an activity in the form of experiences, workshops, or live performances to add a new dimension to the book and its characters.",
        ar: "وبنهاية الشهر، يتم تنظيم ورشة إبداعية أو عرض حيّ نضيف من خلاله بُعداً جديداً إلى الكتاب والشخصيات التي يتناولها.",
      },
    },
  ];

  useEffect(() => {
    animate(ref);
    const getPackages = async () => {
      setLoading(true);
      const formData = new FormData();
      formData.append("action", "packagesByTags");
      formData.append("tag", "BookClub");
      const data = await fetchClient(
        getAccessTokenSilently,
        isAuthenticated,
        "",
        formData,
        false
      );
      if (data) setPackages(data.data);
      setLoading(false);
    };
    getPackages();

    window.addEventListener("resize", () => setWidth(window.innerWidth));
    return () => {
      window.removeEventListener("resize", () => setWidth(window.innerWidth));
    };
  }, []);

  return (
    <>
      <Banner url={bookbanner} title={heading} desc={description} />
      <div className="w-full flex justify-center">
        <div className="sm:w-8/12 md:w-5/12 w-11/12 max-w-[2000px]">
          <p className="text-white text-[18px] my-[4rem] text-center">
            {language.bookclub_main_description[locale]}
          </p>
        </div>
      </div>
      <div className="w-full flex justify-center">
        <div className="w-11/12 max-w-[1800px] mt-[30px]">
          <ul className="flex text-[20px] md:text-[32px] gap-[36px] mt-[30px]">
            <li className="text-white cursor-pointer pb-[8px] whitespace-nowrap border-b-[1px] border-white">
              {language.bookclub_how_it_works_heading[locale]}
            </li>
          </ul>
          <div className="mt-[2rem]">
            <div
              id="monthsBox"
              className="w-full flex gap-[12px] overflow-x-scroll cursor-pointer"
              {...events}
              ref={workScrollref}
            >
              {works.map((work, idx) => (
                <div
                  style={{
                    backgroundImage:
                      "linear-gradient(rgb(0 0 0 / 50%), rgb(0 0 0 / 50%))",
                  }}
                  className="flex-1 min-w-[340px] h-auto rounded-[8px] p-[24px] flex justify-start shrink-0"
                >
                  <div>
                    <img src={work.image} />
                    <p className="text-[16px] md:text-[16px] text-white mt-[10px]">
                      {work.desc[locale]}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="w-full h-full flex justify-center">
        <div className="w-11/12 h-full max-w-[1800px] mt-[30px]">
          <ul className="flex text-[20px] md:text-[32px] gap-[36px] mt-[30px]">
            <li className="text-white cursor-pointer pb-[8px] whitespace-nowrap border-b-[1px] border-white">
              {language.bookclub_reading_month_heading[locale]}
            </li>
          </ul>
          <div className="w-full sm:flex-col md:flex-row flex flex-col gap-[1rem] mt-[3rem]">
            <div
              style={{
                backgroundImage:
                  "linear-gradient(rgb(0 0 0 / 50%), rgb(0 0 0 / 50%))",
              }}
              className="bg-image-gradient w-full h-[500px] max-h-[600px] rounded-[8px] relative"
            >
              <p className="z-10	text-white text-[15px] opacity-50 absolute bottom-5 p-[1rem]">
                {language.bookclub_book_name[locale]}{" "}
              </p>
              <br />
              <p className="z-10	text-white text-[15px] absolute bottom-0 p-[1rem]">
                {language.bookclub_book_author[locale]}{" "}
              </p>
              {/* <img
                src={vintage}
                className="absolute top-0 left-0 right-0 ml-auto mr-auto mt-[3rem]"
              /> */}
              {/* <img
                src={invisibleCities}
                className="absolute bottom-0 left-0 right-0 ml-auto mr-auto h-[300px]"
              /> */}
              <img
                src={bookclub_bookimage}
                className="absolute bottom-0 left-0 right-0 ml-auto mr-auto max-w-full h-[500px] max-h-[600px] object-cover"
              />
            </div>
            <div className="w-full h-full rounded-[8px] sm:mt-[1rem] md:mt-0 mt-[1rem]">
              <div
                style={{
                  backgroundImage:
                    "linear-gradient(rgb(0 0 0 / 50%), rgb(0 0 0 / 50%))",
                }}
                className="bg-image-gradient w-full rounded-[8px] p-[1rem] flex flex-col justify-around"
              >
                <p className="text-white text-[17px]">
                  {language.bookclub_book_quoteone1[locale]}{" "}
                </p>
                <br />
                <p className="text-white text-[17px]">
                  {language.bookclub_book_quoteone2[locale]}{" "}
                </p>
                <br />
                <p className="text-white text-[15px] opacity-50 text-end">
                  {language.bookclub_book_quoteone_writer[locale]}{" "}
                </p>
              </div>
              <div
                style={{
                  backgroundImage:
                    "linear-gradient(rgb(0 0 0 / 50%), rgb(0 0 0 / 50%))",
                }}
                className="hidden bg-image-gradient w-full h-[230px] mt-[2.5rem] rounded-[8px] p-[1rem] flex flex-col justify-around"
              >
                <p className="text-white text-[17px]">
                  Trees are sanctuaries. Whoever knows how to speak to them,
                  whoever knows how to listen to them, can learn the truth. They
                  do not preach learning and precepts, they preach, undeterred
                  by particulars, the ancient law of life.
                </p>
                <br />
                <p className="text-white text-[15px] opacity-50 text-end">
                  {language.bookclub_book_quoteone_writer[locale]}.
                </p>
              </div>
              {/* <div className="mt-[1rem]">
                <p className="text-white text-[17px]">
                  This month we will read ‘Invisible Cities’ by Italo Calvino
                  with selected excerpts and photographs from ‘Building Sharjah’
                  by Todd Reisz and Sultan Sooud Al Qassemi.
                </p>
                <br />
                <p className="text-white text-[17px]">
                  During our book club meeting, we will use this famous creative
                  masterpiece to deeply reflect on our own invisible and visible
                  identities and explore the invisible cities in our cities and
                  ourselves.
                </p>
                <br />
                <p className="text-white text-[17px]">
                  The discussion will be followed by a creative writing workshop
                  designed to develop your writing and critical thinking skills
                  for literature, film, and theatre.
                </p>
              </div> */}
            </div>
          </div>
          <p className="text-white text-[17px] mt-[3rem]">
            {language.bookclub_book_synopsis[locale]}
          </p>
        </div>
      </div>
      <div className="hidden w-full flex justify-center">
        <div className="w-11/12 max-w-[1800px] mt-[30px]">
          <ul className="flex text-[20px] md:text-[32px] gap-[36px] mt-[30px]">
            <li className="text-white cursor-pointer pb-[8px] whitespace-nowrap border-b-[1px] border-white">
              This Month's Box
            </li>
          </ul>
          <p className="text-white text-[17px] mb-[30px] mt-[20px]">
            Register for the reading of the month to receive your book club box
          </p>
          <div className="w-full">
            <div className="w-full h-[350px] rounded-[8px] cursor-pointer">
              <img
                src={width > 820 ? horizImage : verticalImage}
                className="w-full h-[350px]"
                style={{ objectFit: width > 820 ? "cover" : "contain" }}
              />
            </div>
            <div
              className={`flex items-center ${
                width > 820 ? "flex-row justify-around" : "flex-col"
              }`}
            >
              {monthsBox.map((box, idx) => (
                <button
                  className="text-white text-[16px] px-[20px] py-[6px] gradient-border relative mt-[1rem]"
                  onClick={() => window.open(box.link)}
                >
                  {box.title} - {box.desc}
                </button>
              ))}
            </div>
          </div>
        </div>
      </div>
      <Events
        title={language.events[locale]}
        packages={packages}
        ref={(el) => (ref.current[0] = el)}
        loading={loading}
      />
    </>
  );
};

export default BookClub;

import React, { useEffect, useState } from "react";
import Modal from "./Books/Modal";
import { fetchClient } from "../axios-config";
import PackageDetails from "./PackageDetails/PackageDetails";
import { useAuth0 } from "@auth0/auth0-react";
import { useDispatch, useSelector } from "react-redux";
import language from "../Json/locale.json";
import useStorage from "../hooks/useStorage";
import { FETCH_DATA, PROFILE_DATA } from "../redux/action";
import ReservePods from "./ReservePods/ReservePods";
import LogoutModal from "./LogoutModal";
import DeleteModal from "./DeleteModal";

export default function ComponentWrapper({ children }) {
  const { getAccessTokenSilently, isAuthenticated, isLoading } = useAuth0();
  const [bookOpen, setBookOpen] = useState(false);
  const [packageOpen, setPackageOpen] = useState(false);
  const [reservePodOpen, setReservePodOpen] = useState(false);
  const [is_Verified, setIs_Verified] = useState(false);
  const [errMessage, setErrMessage] = useState({
    title: "",
    desc: "",
  });
  const [open, setOpen] = useState(false);
  const [packageSlug, setPackageSlug] = useState("");
  const [bookId, setBookId] = useState("");
  const [spinLoading, setSpinLoading] = useState(false);
  const [profileData, setProfileData] = useState();
  const [OpenProfile, setOpenProfile] = useState(false);
  const [profileDeleted, setProfileDeleted] = useState(false);
  const [deleteProfile, setDeleteProfile] = useState(false);
  const [data, setData] = useState({
    name: "",
    last_name: "",
    mobile: "",
    email: "",
  });
  const dispatch = useDispatch();
  const locale = useStorage();
  const profileSaveData = useSelector((state) => state.profile);

  useEffect(() => {
    const getData = async () => {
      const resData = await fetchClient(
        getAccessTokenSilently,
        isAuthenticated,
        `top-hero-section?populate=home.image&populate=read.image&populate=happenings.image&populate=reserve.image&populate=dine.image&populate=about.image&populate=membership.image&populate=bookclub.image`,
        "",
        true
      );
      // window.removeEventListener("storage", getData);
      if (resData)
        dispatch({
          type: FETCH_DATA,
          payload: resData.data.attributes,
        });
    };
    getData();
    // window.addEventListener("storage", getData);
    // return () => {
    //   window.removeEventListener("storage", getData);
    // };
  }, []);

  useEffect(() => {
    const getProfileDetails = async () => {
      const profileFormData = new FormData();
      profileFormData.append("action", "profileDetails");
      const data = await fetchClient(
        getAccessTokenSilently,
        isAuthenticated,
        "",
        profileFormData,
        false
      );
      if (data) {
        const profile = data.data;
        dispatch({
          type: PROFILE_DATA,
          payload: profile,
        });
      }
    };
    if (!isLoading) if (isAuthenticated) if (!profileData) getProfileDetails();
  }, [isAuthenticated, isLoading]);

  useEffect(() => {
    console.log(profileSaveData);
    if (profileSaveData)
      if (!profileSaveData.email_verified) {
        setErrMessage(language.email_notVerified[locale]);
        setIs_Verified(true);
        setOpenProfile(false);
        setOpen(true);
      } else if (profileSaveData.is_suspend === "1") {
        setErrMessage(language.acc_suspended[locale]);
        setOpenProfile(false);
        setOpen(true);
      } else if (
        !profileSaveData.email ||
        profileSaveData.email.includes("privaterelay.appleid")
      ) {
        setErrMessage(language.email_dontExist[locale]);
        setOpenProfile(false);
        setOpen(true);
      } else if (
        !profileSaveData.name ||
        !profileSaveData.last_name ||
        !profileSaveData.mobile
      ) {
        setData(profileSaveData);
        setProfileData(profileSaveData);
        setOpenProfile(true);
        setOpen(true);
      } else {
        setOpenProfile(false);
        setOpen(false);
      }
  }, [profileSaveData]);

  useEffect(() => {
    let params = new URLSearchParams(window.location.search);
    var type = params.get("type");
    var uuid = params.get("uuid");
    if (type === "package") {
      setPackageSlug(uuid);
      setPackageOpen(true);
      return;
    }
    if (type === "library") {
      setBookId(uuid);
      setBookOpen(true);
      return;
    }
    if (type === "privatepods") {
      setReservePodOpen(true);
      return;
    }
  }, [window.location.search]);

  useEffect(() => {
    if (bookOpen || packageOpen || reservePodOpen) {
      document
        .getElementsByTagName("body")[0]
        ?.classList.add("overflow-hidden");
    } else {
      document
        .getElementsByTagName("body")[0]
        ?.classList.remove("overflow-hidden");
    }
  }, [bookOpen, packageOpen, reservePodOpen]);

  const closeBookModal = () => {
    window.history.replaceState(null, null, window.location.pathname);
    setBookId("");
    setBookOpen(false);
  };

  const resendEmail = async () => {
    setSpinLoading(true);
    const resendFormData = new FormData();
    resendFormData.append("action", "verifyEmail");
    await fetchClient(
      getAccessTokenSilently,
      isAuthenticated,
      "",
      resendFormData,
      false
    );
    setSpinLoading(false);
  };

  return (
    <div>
      {children}
      <Modal isOpen={bookOpen} closeModal={closeBookModal} bookId={bookId} />
      <PackageDetails
        setPackageSlug={setPackageSlug}
        packageSlug={packageSlug}
        isOpen={packageOpen}
        closeModal={() => {
          window.history.replaceState(null, null, window.location.pathname);
          setPackageOpen(false);
        }}
      />
      <ReservePods
        isOpen={reservePodOpen}
        closeModal={() => {
          window.history.replaceState(null, null, window.location.pathname);
          setReservePodOpen(false);
        }}
      />
      <LogoutModal
        open={open}
        resendEmail={resendEmail}
        errMessage={errMessage}
        is_Verified={is_Verified}
        OpenProfile={OpenProfile}
        data={data}
        profileData={profileData}
        setData={setData}
        setProfileData={setProfileData}
        setDeleteProfile={setDeleteProfile}
        setSpinLoading={setSpinLoading}
        spinLoading={spinLoading}
      />
      <DeleteModal
        deleteProfile={deleteProfile}
        setDeleteProfile={setDeleteProfile}
        profileDeleted={profileDeleted}
        setProfileDeleted={setProfileDeleted}
      />
    </div>
  );
}

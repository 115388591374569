import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { fetchClient } from "../axios-config";
import useFacebookPixel from "../hooks/useFacebookPixel";

function TC() {
  const { getAccessTokenSilently, isAuthenticated } = useAuth0();
  const [loading, setLoading] = useState(false);
  const [tcData, setTcData] = useState();
  const { trackPageView } = useFacebookPixel();

  useEffect(() => {
    trackPageView();
  }, [trackPageView]);

  useEffect(() => {
    const getTC = async () => {
      setLoading(true);
      const data = await fetchClient(
        getAccessTokenSilently,
        isAuthenticated,
        `pages?&locale=${
          localStorage.getItem("lang") || "en"
        }&filters[slug][$eq]=terms-conditions&populate=*`,
        "",
        true
      );
      if (data) setTcData(data.data[0].attributes);
      setLoading(false);
    };
    getTC();
  }, []);

  return (
    <div className="pt-[140px] w-full flex justify-center">
      {loading ? (
        <div className="w-10/12 md:w-8/12 relative mt-[3rem]">
          <div
            role="status"
            class="space-y-8 animate-pulse md:space-y-0 md:space-x-8 md:flex mb-[48px]"
          >
            <div class="w-full">
              <div class="h-6 bg-gray-200 rounded-full dark:bg-gray-700 w-[20rem] mb-6"></div>
              {[...Array(30)].map(() => (
                <div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-2.5"></div>
              ))}
            </div>
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      ) : (
        <div className="w-10/12 md:w-8/12 relative">
          <h4 className="text-[20px] md:text-[32px] text-white mt-[48px] mb-[20px]">
            {tcData?.Heading}
          </h4>
          <div
            className="text-white text-[16px]"
            dangerouslySetInnerHTML={{
              __html: tcData?.Content,
            }}
          ></div>
        </div>
      )}
    </div>
  );
}

export default TC;

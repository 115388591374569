import React, { Fragment, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import language from "../Json/locale.json";
import useStorage from "../hooks/useStorage";

export default function DeleteModal({
  deleteProfile,
  setDeleteProfile,
  profileDeleted,
  setProfileDeleted,
}) {
  const locale = useStorage();
  const cancelButtonRef = useRef(null);
  return (
    <Transition.Root show={deleteProfile} as={Fragment}>
      <Dialog
        as="div"
        style={{ zIndex: 9999 }}
        className="relative"
        initialFocus={cancelButtonRef}
        onClose={setDeleteProfile}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>
        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end justify-center pt-4 px-4 pb-40 text-center sm:block sm:p-0">
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative inline-block bg-modalBg rounded-[30px] text-left overflow-hidden shadow-xl transform transition-all p-8 mt-[14rem]">
                {profileDeleted ? (
                  <>
                    <h3 className="text-[20px] md:text-[32px] text-center text-white">
                      {language.deleted[locale]}
                    </h3>
                    <div className="flex w-full justify-center gap-6 mt-[60px]">
                      <button
                        type="button"
                        onClick={() => {
                          setDeleteProfile(false);
                          setTimeout(() => setProfileDeleted(false), 300);
                        }}
                        className="flex justify-center align-center text-white text-[16px] px-[20px] py-[6px] w-fit gradient-border relative "
                      >
                        {language.close[locale]}
                      </button>
                    </div>
                  </>
                ) : (
                  <>
                    <h3 className="text-[20px] md:text-[32px] text-center text-white">
                      {language.delete_acc[locale]}
                    </h3>
                    <div className="flex items-center justify-between rounded-t">
                      <div className="flex w-full justify-center gap-6 mt-[60px]">
                        <button
                          type="button"
                          onClick={() => setProfileDeleted(true)}
                          className="flex justify-center align-center text-white text-[16px] px-[20px] py-[6px] w-fit gradient-border relative "
                        >
                          {language.yes[locale]}
                        </button>
                        <button
                          type="button"
                          onClick={() => setDeleteProfile(false)}
                          className="flex justify-center align-center text-white text-[16px] px-[20px] py-[6px] w-fit gradient-border relative "
                        >
                          {language.no[locale]}
                        </button>
                      </div>
                    </div>
                  </>
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

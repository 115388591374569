import React, { useEffect } from "react";
import events from "../../assets/images/eventsignup.png";
import read from "../../assets/images/read.jpg";
import defaultImg from "../../assets/images/library-book-default.png";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { useState } from "react";
import useStorage from "../../hooks/useStorage";
import arrowprev from "../../assets/svg/arrowprev.svg";
import arrownext from "../../assets/svg/arrownext.svg";

const ImageCarousel = ({ image }) => {
  const [currentItem, setCurrentItem] = useState(0);
  const [imageLoaded, setImageLoaded] = useState(true);
  const locale = useStorage();

  useEffect(() => {
    setTimeout(() => setImageLoaded(false), 1000);
  }, [image]);

  return (
    <>
      {imageLoaded ? (
        <Carousel
          showIndicators={false}
          showStatus={false}
          showThumbs={false}
          showArrows={false}
          infiniteLoop
          selectedItem={currentItem}
          onChange={setCurrentItem}
          className={locale === "ar" && "ltr-carousel"}
        >
          {["", "", ""].map(() => (
            <div
              role="status"
              class="space-y-8 animate-pulse md:space-y-0 md:space-x-8 md:flex md:items-center w-full h-[293px] md:h-[380px]"
            >
              <div class="flex justify-center items-center w-full h-[293px] md:h-[380px] bg-gray-300 rounded dark:bg-gray-700"></div>
              <span class="sr-only">Loading...</span>
            </div>
          ))}
        </Carousel>
      ) : (
        <Carousel
          showIndicators={false}
          showStatus={false}
          showThumbs={false}
          showArrows={false}
          infiniteLoop
          selectedItem={currentItem}
          onChange={setCurrentItem}
          className={locale === "ar" && "ltr-carousel"}
        >
          {image ? (
            <img
              className="rounded-[8px] w-full h-[218px] md:h-[380px] object-cover"
              src={
                image.includes("https")
                  ? image
                  : process.env.REACT_APP_IMG_URL + encodeURI(image)
              }
            ></img>
          ) : (
            <img
              className="rounded-[8px] w-full h-[218px] md:h-[380px] object-cover"
              src={defaultImg}
            ></img>
          )}
        </Carousel>
      )}
      {/* {!image && (
        <img
          className="rounded-[8px] w-full h-[218px] md:h-[380px] object-cover"
          src={defaultImg}
        ></img>
      )} */}
      {/* <div className="absolute left-1/2 transform -translate-x-1/2 mt-[-50px] rounded-[30px] bg-[rgba(0,0,0,0.3)] p-[2px] flex gap-[8px]">
        <button
          className="w-[36px] h-[36px] bg-white flex justify-center items-center rounded-[32px]"
          onClick={() => setCurrentItem(currentItem - 1)}
        >
          <img src={arrowprev} alt="" />
        </button>
        <button
          className="w-[36px] h-[36px] bg-white flex justify-center items-center rounded-[32px]"
          onClick={() => setCurrentItem(currentItem + 1)}
        >
          <img src={arrownext} alt="" />
        </button>
      </div> */}
    </>
  );
};

export default ImageCarousel;

import React from "react";
import { Link } from "react-router-dom";
import diamond from "../../assets/svg/diamond.svg";
import language from "../../Json/locale.json";
import useStorage from "../../hooks/useStorage";

const Membership = React.forwardRef(({ showHeader }, ref) => {
  const locale = useStorage();
  return (
    <div className="mt-[84px] w-full flex justify-center">
      <div
        className={`${showHeader ? "w-11/12 max-w-[1800px]" : "w-full"}`}
        ref={ref}
      >
        {showHeader && (
          <h3 className="text-white text-[20px] md:text-[32px] mb-[36px]">
            {language.membership_title[locale]}
          </h3>
        )}
        <div className="flex justify-start md:justify-evenly gap-[12px] overflow-scroll">
          <div className="flex flex-col justify-center items-center h-[444px] w-full bg-image-gradient bg-center [--bg-url:url('./assets/images/membership1.png')] bg-cover rounded-[8px] sm:p-[48px]">
            <img src={diamond} alt="" className="h-[40px]" />
            <div className="flex flex-col justify-center items-center">
              <h3 className="text-white text-center text-[28px] md:text-[32px] mt-[24px] mb-[16px]">
                {language.membership_sub_title[locale]}
              </h3>
              <p className="text-white text-center text-[16px] mb-[30px] p-[2rem]">
                {language.membership_desc[locale]}
              </p>
              <Link
                to="/membership"
                className="flex justify-center align-center text-white text-[16px] px-[20px] py-[6px] gradient-border relative"
              >
                {language.membership_button_text[locale]}
              </Link>
            </div>
          </div>
          {/* <div className="flex flex-col justify-between items-start h-[444px] min-w-full md:min-w-0 w-full bg-image-gradient [--bg-url:url('./assets/images/membership2.png')] bg-cover rounded-[8px] p-[48px]">
            <img src={diamond} alt="" className="h-[40px]" />
            <div>
              <h3 className="text-white text-[32px]">Premium Membership</h3>
              <p className="text-white text-[16px] mb-[30px]">
                800 AED per year / 640 AED Students.
              </p>
              <button className="flex justify-center align-center text-white text-[16px] px-[20px] py-[6px] ">
                Explore the features
              </button>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
});

export default Membership;

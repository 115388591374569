import axios from "axios";
import { notifyError, notifySuccess } from "./utils/notifyToast";

export const fetchClient = (
  getAccessTokenSilently,
  isAuthenticated,
  url,
  data,
  isCMS
) => {
  const axiosInstance = axios.create({
    baseURL: `${
      isCMS ? `${process.env.REACT_APP_CMS}/api/` : process.env.REACT_APP_DOMAIN
    }${
      isCMS
        ? url +
          (localStorage.getItem("lang") === "ar"
            ? "&locale=ar"
            : "&_locale=en-US")
        : url
    }`,
    method: data ? "POST" : "GET",
  });

  axiosInstance.interceptors.request.use(async (config) => {
    var token;
    if (isAuthenticated && !isCMS) {
      try {
        token = await getAccessTokenSilently();
      } catch (e) {
        console.log(e.message);
      }
    }
    if (token) config.headers.Authorization = `Bearer ${token}`;
    if (localStorage.getItem("user_id") && !isCMS)
      config.headers.Userid = localStorage.getItem("user_id");
    config.headers["Accept-Language"] = localStorage.getItem("lang") || "en";
    if (data) config.data = data;
    return config;
  });

  axiosInstance.interceptors.response.use(
    (res) => {
      console.log(res.data);
      if (res.data.success && !isCMS) {
        if (res.data.message) {
          notifySuccess(res.data.message);
        }
        return res.data;
      } else if (res.data && isCMS) {
        return res.data;
      } else {
        notifyError(
          res.data?.message ? (
            <div dangerouslySetInnerHTML={{ __html: res.data?.message }} />
          ) : localStorage.getItem("lang") === "ar" ? (
            "حدث خطأ مّا"
          ) : (
            "Something went wrong"
          )
        );
        return "";
      }
    },
    (err) => {
      console.log(err);
      Promise.reject(err);
    }
  );

  return axiosInstance();
};
